import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { exchangeTo } from "../utils/glob-redux/store";
import { commonApiPost, getCookie, removeUserSession, setCookie } from "../utils/api";
import VisEquitySearch from "./vision/VisEquitySearch";
import { notificationTopup } from "../utils/NotificationTopup";
import { GridIconNormNS } from "../views/svgs/GridIcon";
import {
  ButtonDropdown,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import { urlsFE } from "../views/pageroutes";
import Divider from "../views/preLogin/components/Divider";

import angelOne from "./integrations/angel-one/angelonelogo.png";
import { angelOneLogout, doLogin, getUserDetails } from "./integrations/angel-one/angelOneHelper";
import { cfcdn, isLoggedin } from "../utils/configs";
import LivePrice from "../views/preLogin/components/LivePrice";
import HomeSearch from "../views/preLogin/components/HomeSearch";
import { links } from "../views/preLogin/components/Footer";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import KeyboardOptionKeyIcon from "@mui/icons-material/KeyboardOptionKey";
import LinkIcon from "@mui/icons-material/Link";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import EventIcon from "@mui/icons-material/Event";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import CableIcon from "@mui/icons-material/Cable";
import WhatshotIcon from '@mui/icons-material/Whatshot';

function Navbar() {
  const exchanges = ["INDIA", "GLOBAL", "COMBINED"];
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState({}); // Track which submenu is open

  const connectedBroker = useSelector((state) => state.connectedBroker);

  const toggleOffcanvas = () => {
    setIsOpen(!isOpen);
  };

  const n = useNavigate();

  const toggleSubmenu = (menu) => {
    setIsSubmenuOpen((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };
  // const
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const exchange = useSelector((state) => state.exchange);
  const theme = useSelector((state) => state.theme);

  const dispatch = useDispatch();
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const menuConfig = [
    {
      title: "Dashboard",
      onclick: () => {
        n(urlsFE.dashboard);
      },
      icon: <DashboardIcon />, // Updated path
    },
    {
      title: "Market Watch",
      icon: <AutoModeIcon />, // Updated path
      onclick: () => {
        n(urlsFE.marketWatch);
      },
    },
    {
      title: "Premarket",
      icon: <RocketLaunchIcon />, // Updated path
      onclick: () => {
        n(urlsFE.preMarket);
      },
    },
    {
      title: "Equity",
      icon: <ShowChartIcon />, // Updated path
      onclick: () => {
        n(urlsFE.equityOverview);
      },
    },
    {
      title: "Watchlists",
      onclick: () => {
        n(urlsFE.watchlist);
      },
      icon: <LoyaltyIcon />, // Updated path
    },
    {
      title: "IPO Details",
      icon: <TipsAndUpdatesIcon />, // Updated path
      submenus: [
        {
          title: "Ongoing",
          onclick: () => {
            n("/ipo/ongoing");
          },
        },
        {
          title: "Upcoming",
          onclick: () => {
            n("/ipo/upcoming");
          },
        },
        {
          title: "Listed",
          onclick: () => {
            n("/ipo/listed");
          },
        },
      ],
    },
    {
      title: "FII/DII",
      icon: <CompareArrowsIcon />, // Updated path
      submenus: [
        {
          title: "Day Wise",
          onclick: () => {
            n("/fii-dii/day-wise");
          },
        },
        {
          title: "CM Provisional",
          onclick: () => {
            n("/fii-dii/cm-provisional");
          },
        },
        {
          title: "CM Sebi",
          onclick: () => {
            n("/fii-dii/cm-sebi");
          },
        },
        {
          title: "FPI Sectoral",
          onclick: () => {
            n("/fii-dii/fpi-sectoral");
          },
        },
        {
          title: "Derivatives",
          onclick: () => {
            n("/fii-dii/derivatives");
          },
        },
      ],
    },
    {
      title: "Sectoral",
      icon: <WhatshotIcon />, // Updated path
      onclick: () => {
        n(urlsFE.sectors);
      }
    },
    {
      title: "Derivatives",
      icon: <KeyboardOptionKeyIcon />, // Updated path
      submenus: [
        {
          title: "Movement",
          icon: <SwapVertIcon />, // Updated path
          onclick: () => {
            n(urlsFE.derivativesOverview);
          },
        },
        {
          title: (
            <>
              Options{" "}
              <span
                className="div-pill our-border"
                style={{
                  color: "var(--bs-info)",
                }}
              >
                BETA
              </span>
            </>
          ),
          onclick: () => {
            n(urlsFE.derivatives);
          },
          icon: <LinkIcon />, // Updated path
        },
      ],
    },
    {
      title: "Others",
      icon: <SettingsEthernetIcon />, // Updated path
      submenus: [
        {
          title: "Market Holidays",
          onclick: () => {
            n(urlsFE.marketHoliday);
          },
          icon: <EventIcon />, // Updated path
        },
      ],
    },
    {
      title: "Blog",
      onclick: () => {
        window.open("https://blog.quibblefrost.in/", "_blank");
      },
      icon: <RssFeedIcon />, // Updated path
    },
    {
      title: "Connect Broker",
      icon: <CableIcon />, // Updated path
      submenus: [
        {
          title: (
            <>
              AngelOne
              <span className="text-xs ms-2">
                {connectedBroker.angelOne ? (
                  <span className="text-success">🟢</span>
                ) : (
                  <span className="text-danger">🔴</span>
                )}
              </span>
            </>
          ),
          onclick: () => {
            if (connectedBroker.angelOne) {
              angelOneLogout();
            } else {
              doLogin();
            }
          },
          icon: <img src={angelOne} width={20} />,
        },
      ],
    },
  ];

  useEffect(() => {
    getUserDetails();

    const handleClickOutside = (event) => {
      // Get the nearest parent with an ID
      const nearestParent = event.target.closest("[id]");

      // Check if the nearest parent exists and its ID is not "offcanvas_global_id"
      if (!nearestParent || (nearestParent.id !== "offcanvas_global_id" && nearestParent.id !== "hp_searchbarnav")) {
        setIsOpen(false); // Close offcanvas if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar-wrapper">
      <div className="navbar">
        <div className="sitename ms-2">
          <VisEquitySearch />
          <Link to={isLoggedin() ? urlsFE.dashboard : "/"}>
            <img src={`${cfcdn}/icons/transparent-logo.webp`} alt="Quibblefrost" height={36} />
          </Link>
          <Link to={isLoggedin() ? urlsFE.dashboard : "/"}>
            <i className="">
              {" "}
              <h1 style={{ fontWeight: 900, marginInlineStart: "-9px" }} className="h2 mt-2">
                <span className="d-none">Q</span>uibblefrost
              </h1>
            </i>
          </Link>
        </div>
        <div className="accountmenu">
          <ul>
            <li>
              <i
                onClick={() => {
                  dispatch({ type: "TOOLBOX_SEARCH" });
                  dispatch({ type: "SHOW_VISSEARCH" });
                }}
                style={{
                  cursor: "pointer",
                  transition: "2s",
                  marginTop: "10px",
                }}
                className="fas fa-magnifying-glass"
              ></i>
            </li>
            <li>
              <i
                className={theme === "light" ? "fa-solid fa-moon" : "fa-solid fa-sun"}
                onClick={() => dispatch({ type: "SWITCH_THEME" })}
                style={{
                  cursor: "pointer",
                  transition: "2s",
                  marginTop: "10px",
                }}
              ></i>
            </li>

            <li>
              <i
                onClick={toggleOffcanvas}
                style={{
                  cursor: "pointer",
                  transition: "2s",
                  marginTop: "10px",
                }}
                className="fas fa-bars"
              ></i>
            </li>

            {exchange !== "NSE" ? (
              <li>
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret size="sm" color="transparent" className="general-card-1 text-color-themed">
                    {exchange}
                  </DropdownToggle>
                  <DropdownMenu className="general-card-1 non-trans-bg">
                    {exchanges.map((exchange, index) => (
                      <DropdownItem
                        key={index}
                        className="bg-set-on-active text-color-themed"
                        onClick={() => dispatch(exchangeTo(exchange))}
                      >
                        {exchange}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>

        <Offcanvas
          scrollable
          direction="end"
          trapFocus={true}
          isOpen={isOpen}
          toggle={toggleOffcanvas}
          className=" general-card-1 non-trans-bg"
          style={{
            transition: "0.21s",
            maxWidth: "320px",
            borderLeft: "1px solid var(--border-clr)",
          }}
          id="offcanvas_global_id"
        >
          <OffcanvasHeader toggle={toggleOffcanvas} className="custom-offcanvas-header">
            Navigation
          </OffcanvasHeader>
          <Divider py={0} role="QF" />
          <OffcanvasBody
            className=" "
            style={{
              position: "relative",
            }}
          >
            <div>
              <div className="menu-general-bp">
                <div className="make-me-sticky">
                  <HomeSearch nav={true} />
                </div>
                <br />
                {menuConfig.map((menu, index) => (
                  <div key={index}>
                    {menu.submenus ? (
                      <>
                        <div onClick={() => toggleSubmenu(`menu${index + 1}`)} className="c-pointer p-2 hover-text-menu">
                          {/* <img src={menu.icon} width={30} className="me-2" /> */}

                          <span className="me-2">{menu.icon}</span>
                          {menu.title}
                        </div>
                        <Collapse isOpen={isSubmenuOpen[`menu${index + 1}`]}>
                          <div style={{ paddingLeft: "30px" }}>
                            {menu.submenus.map((submenu, subIndex) => (
                              <div
                                key={subIndex}
                                className="hover-text-submenu c-pointer"
                                onClick={() => {
                                  submenu.onclick();
                                }}
                              >
                                {/* <img
                                  src={submenu.icon || `${cfcdn}/svgs/default-left-svg.svg`}
                                  width={27}
                                  className="me-2"
                                /> */}
                                <span className="me-2">{submenu.icon || <KeyboardDoubleArrowRightIcon />}</span>
                                {submenu.title}
                              </div>
                            ))}
                          </div>
                        </Collapse>
                      </>
                    ) : (
                      <div
                        className="c-pointer p-2 hover-text-menu"
                        onClick={() => {
                          menu.onclick();
                        }}
                      >
                        {/* <img src={menu.icon} width={30} className="me-2" /> */}
                        <span className="me-2">{menu.icon}</span>
                        {menu.title}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                }}
                className="w-100"
              >
                <Divider role="QF" py={0} />
                <div className="accountmenu">
                  <ul
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {Object.keys(links).map((key, ind) => (
                      <li key={ind}>
                        <i
                          className={links[key].icon}
                          onClick={() => window.open(links[key].url, "_blank")}
                          style={{
                            cursor: "pointer",
                            transition: "2s",
                            marginTop: "10px",
                            fontSize: "18px",
                          }}
                        />
                      </li>
                    ))}
                    {location.href.includes(":3000") ? (
                      <li>
                        <i
                          className="fab fa-d-and-d"
                          onClick={() => {
                            setCookie("access_token", "a", 0.04166666667);
                          }}
                          style={{
                            cursor: "pointer",
                            transition: "2s",
                            marginTop: "10px",
                            fontSize: "18px",
                          }}
                        ></i>
                      </li>
                    ) : (
                      <></>
                    )}
                    {getCookie("access_token") !== "" ? (
                      <li>
                        <i
                          className="fas fa-power-off"
                          onClick={async () => {
                            await commonApiPost(`/user/signout`)
                              .then((res) => notificationTopup("Signout success", "green", true))
                              .catch((err) => notificationTopup(err.message));
                            caches.delete("api-cache");
                            document.cookie.split(";").forEach((cookie) => {
                              const eqPos = cookie.indexOf("=");
                              const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
                              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                            });
                            removeUserSession("", false);
                            n("/");
                          }}
                          style={{
                            cursor: "pointer",
                            transition: "2s",
                            marginTop: "10px",
                            fontSize: "18px",
                          }}
                        ></i>
                      </li>
                    ) : (
                      <></>
                    )}

                    <li>
                      <i
                        onClick={() => {
                          dispatch({ type: "TOOLBOX_TOOLBOX" });
                          dispatch({ type: "SHOW_VISSEARCH" });
                        }}
                        style={{
                          cursor: "pointer",
                          transition: "2s",
                          marginTop: "10px",
                          fontSize: "18px",
                        }}
                      >
                        <GridIconNormNS
                          style={{
                            marginTop: "10px",
                          }}
                          height={18}
                          width={18}
                        />
                      </i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <LivePrice />
    </div>
  );
}

export default Navbar;
