import React, { useEffect } from "react";
// import { HomePage } from "../views/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../static/css/index.css";
import "../static/css/bs-extended.css";
import { hawkReport, hawkReportTypeIdentifiers } from "../utils/hawkeye/hawkHelper";
import CommonCardViewer from "../components/vision/CommonCardViewer";
// import CryptoRedirector from "./CryptoRedirector";
import { pageRoutes } from "./pageroutes";
import { DefaultHelmet } from "./seo-helmet/helmets";
import Embed from "../Embed";

export default function AppRoutes() {
  const highlightedComponent = useSelector((state) => state.highlightedComponent);
  const d = useDispatch();

  useEffect(() => {
    if (highlightedComponent !== null) {
      const element = document.getElementById(highlightedComponent);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        // d({ type: "HIGHLIGHTED_COMPONENT", component: null });
      }
    }
  }, [highlightedComponent]);

  const apiCallOnLoad = async () => {
    try {
      hawkReport(new Error("HAWK REPORTING"), {}, hawkReportTypeIdentifiers.initReport);
    } catch (error) {
      console.error("QF-JOURNAL-HAWKERRORSENDINGINIT", error);
    }
  };

  const apiCallOnUnload = async () => {
    try {
      hawkReport(new Error("HAWK REPORTING"), {}, hawkReportTypeIdentifiers.sessionEndReport);
    } catch (error) {
      console.error("QF-JOURNAL-HAWKERRORSENDINGEND", error);
    }
  };

  useEffect(() => {
    // API call on window load
    apiCallOnLoad();

    // API call before window unload
    window.addEventListener("beforeunload", apiCallOnUnload);

    return () => {
      // Remove event listener on component unmount
      window.removeEventListener("beforeunload", apiCallOnUnload);
    };
  }, []);

  const theme = useSelector((state) => state.theme);
  document.documentElement.setAttribute("data-theme", theme || "light");

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path={urlsFE.crypto} element={<HomePage />} exact />
        <Route path={urlsFE.cryptoRedirector} element={<CryptoRedirector />} exact /> */}
        <Route path="/embed/:component" element={<Embed />} exact />
        {pageRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <>
                {route.emptyRoute ? (
                  route.component
                ) : (
                  <CommonCardViewer
                    isPageOrCard={route.isPage ? "page" : "card"}
                    shortComponent={route.shortComponent}
                    isProtected={route.isProtected || false}
                    pathForRef={route.path}
                    helmet={route.helmet || <DefaultHelmet />}
                    toUpdateHelmetFromCommonComponent={route.doNotChangeHelmet || false}
                  >
                    {route.component}
                  </CommonCardViewer>
                )}
              </>
            }
            exact
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}
