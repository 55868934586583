import React, { useEffect, useRef, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import ModalQF from "../vision/ModalQF";
import { chartColor, getIconForName, maxViewContent } from "../../utils/configs";
import QFLoader from "../vision/QFLoader";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import VisTable from "../vision/VisTable";

const Scanners = () => {
  const divScrollToTop = useRef(null);
  const divScrollToTopIn = useRef(null);

  const [data, setData] = useState([]);
  const [nextData, setNextData] = useState([]);
  const [dataToBeSelected, setDataToBeSelected] = useState(null); // Initialize with null
  const [dataLoadNext, setDataLoadNext] = useState([null, null]);
  const [header, setHeader] = useState("");
  const [innerDesc, setInnerDesc] = useState("");
  const [dataTable, setdataTable] = useState({ columns: [], rows: [] });
  const [isExpand, setIsExpand] = useState(false);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [loaderShowIn, setLoaderShowIn] = useState(true);
  const [errorShowIn, setErrorShowIn] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/analysis/get_scanners`, "SCANNER | MAIN")
        .then((res) => {
          let dataToLoad = res.data.list;
          setData(dataToLoad);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch(async (err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  useEffect(() => {
    if (dataLoadNext[0] === null) {
      setInnerDesc("");
    }
  }, [dataLoadNext]);

  useEffect(() => {
    if (!dataLoadNext.every((item) => item === null)) {
      (async () => {
        await commonApiGet(
          `/analysis/get_scanner_details?categoryId=${data[dataLoadNext[0]]["cat_id"]}&scanId=${
            data[dataLoadNext[0]]["scan_list"][dataLoadNext[1]]["id"]
          }`,
          "SCANNER | INNER",
          false
        )
          .then((res) => {
            let dataToLoad = res.data.data;
            setNextData(dataToLoad);
            setInnerDesc(res.data.description);
            setErrorShowIn(false);
            setLoaderShowIn(false);
          })
          .catch(async (err) => {
            notificationTopup(err.message);
            setErrorShowIn(true);
            setLoaderShowIn(false);
          });
      })();
    }
  }, [dataLoadNext]);

  useEffect(() => {
    if (nextData?.length > 0) {
      let prepareData = [];
      for (let i = 0; i < nextData.length; i++) {
        let rowData = {
          Stockname: nextData[i].stkname || nextData[i].stockname || nextData[i].stkId,
          Price: (
            <span>
              ₹{nextData[i].ltp}{" "}
              <span
                style={{
                  color: parseFloat(nextData[i].perChg) > 0 ? chartColor.green : chartColor.red,
                }}
              >
                ({nextData[i].perChg}%)
              </span>
            </span>
          ),
        };

        // Add columns dynamically
        if (isExpand) {
          nextData[i].columns.forEach((col) => {
            rowData[col.name] = col.value; // Dynamic key assignment
          });
        }
        prepareData.push(rowData);
      }

      setdataTable({
        columns: [
          { field: "Stockname", label: "Stock Name" },
          { field: "Price", label: "Price" },
          ...(isExpand
            ? nextData[0].columns.map((val) => {
                return { field: val.name, label: maxViewContent(val.name, 20) };
              })
            : []),
        ],
        rows: prepareData,
      });
    } else {
      setdataTable({ columns: [], rows: [] });
    }
  }, [nextData, isExpand]);

  return (
    <>
      <CommonCardView
        id="commonCard_fundamentalScanner"
        style={{
          ...(!isExpand
            ? {
                position: "relative",
              }
            : {}),
        }}
        header="Fundamental Scanners"
        headerInfo={{
          show: true,
          desc: "Analyze stocks based on key financial metrics like P/E ratio, earnings growth, debt-to-equity, and dividends. Identify fundamentally strong stocks for long-term investments.",
          id: "fundamental-scanners",
        }}
      >
        {errorShow ? (
          <VisOops />
        ) : loaderShow ? (
          <QFLoader />
        ) : (
          <div
            ref={divScrollToTop}
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              maxHeight: "400px",
            }}
          >
            {data.map((val, index) => {
              const isSelected = dataToBeSelected !== null && 0 === index;
              return (
                <div style={{ position: "relative" }} key={`QF_FNDMTL_SCRNS_${index}`}>
                  <div
                    className={`p-2 pt-0 ${
                      // isSelected ? "general-card-1 mt-0" : ""
                      isSelected ? " mt-0" : ""
                    }`}
                    style={{
                      maxHeight: isSelected ? "390px" : "", // Use max-height for smoother transitions
                      overflowY: "scroll", // Ensure content stays hidden when collapsed
                      transition: "max-height 0.5s ease", // Apply transition
                      padding: isSelected ? "10px" : "0px",
                    }}
                  >
                    <div
                      onClick={(e) => {
                        const divContainer = divScrollToTop.current;
                        if (divContainer) divContainer.scrollTop = 0;
                        setDataToBeSelected(isSelected ? null : index);
                        setData((prev) => {
                          return prev.sort((a, b) => (data.indexOf(a) === index ? -1 : data.indexOf(b) === index ? 1 : 0));
                        });
                      }} // Toggle open/close
                      style={{
                        cursor: "pointer",
                      }}
                      className="make-me-sticky non-trans-bg"
                    >
                      <span
                        style={{
                          textTransform: "uppercase",
                          fontWeight: 700,
                          maxHeight: "320px",
                          top: 0,
                        }}
                        // className="make-me-sticky"
                      >
                        <i className={getIconForName(val.cat_name, "scan")} /> {val.cat_name.replace("MC ", "")}
                      </span>
                      <br />
                      {val.cat_desc}
                      <hr />
                    </div>
                    {isSelected && (
                      <div className="px-2">
                        <ul
                          style={{
                            listStylePosition: "outside",
                            margin: 0,
                            paddingLeft: "10px",
                          }}
                        >
                          {val.scan_list.map((scanner, index2) => (
                            <span key={`QF_FNDMTL_SCRNS_${index}_${index2}`}>
                              <div
                                onClick={() => {
                                  setHeader(scanner.name);
                                  setErrorShowIn(false);
                                  setLoaderShowIn(true);
                                  setDataLoadNext([index, index2]);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <li
                                  style={{
                                    textTransform: "uppercase",
                                    fontWeight: 400,
                                  }}
                                >
                                  {scanner.name}
                                </li>
                              </div>
                              <hr className="m-1" />
                            </span>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <i
                    className={`fas ${isSelected ? "fa-angle-up" : "fa-angle-down"}`}
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "10px",
                      zIndex: 2,
                    }}
                  ></i>
                </div>
              );
            })}
            {dataLoadNext.every((item) => item === null) ? (
              <></>
            ) : (
              <ModalQF
                isExpandBtn={true}
                expandVal={isExpand}
                dataClearFunc={setNextData}
                dataClearTo={[]}
                expandFunc={setIsExpand}
                header={header}
                desc={innerDesc}
                closeVal={[null, null]}
                closeVar={setDataLoadNext}
                data={
                  errorShowIn ? (
                    <div
                      style={{
                        padding: "20px",
                      }}
                    >
                      <VisOops />
                    </div>
                  ) : loaderShowIn ? (
                    <div
                      style={{
                        height: "200px",
                      }}
                    >
                      <QFLoader />
                    </div>
                  ) : (
                    <div
                      style={{
                        overflowX: "hidden",
                        overflowY: "scroll",
                        ...(isExpand ? { maxHeight: "65vh" } : { maxHeight: "300px" }),
                      }}
                    >
                      <VisTable data={dataTable} recsPerPage={20} />
                    </div>
                  )
                }
              />
            )}
          </div>
        )}
      </CommonCardView>
    </>
  );
};

export default Scanners;
