import React, { useEffect, useState } from "react";
import LivePrice from "../../views/preLogin/components/LivePrice";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import { isLoggedin } from "../../utils/configs";
import Footer from "../../views/preLogin/components/Footer";
import Divider from "../../views/preLogin/components/Divider";
import { urlsFE } from "../../views/pageroutes";

const CommonCardViewer = ({
  shortComponent = true,
  isProtected = false,
  navigateIfProtected = `${urlsFE.signin}?callback=${location.pathname}`,
  pathForRef = "Not Provided",
  isPageOrCard = "card",
  children,
  helmet,
  toUpdateHelmetFromCommonComponent,
}) => {
  const [isUserLoggedin, setIsUserLoggedin] = useState(!isProtected);
  const n = useNavigate();
  useEffect(() => {
    document.getElementById("scrllToTop-btn-btmIcons")?.click();
    if (isProtected) {
      if (!isLoggedin()) {
        n(navigateIfProtected);
      } else {
        commonApiGet("/user/getuser", `COMMON_CARD_V_${location.pathname}`, false)
          .then((res) => {
            setIsUserLoggedin(true);
          })
          .catch((err) => {
            notificationTopup(err.message);
            setIsUserLoggedin(false);
            // n(navigateIfProtected);
          });
      }
    }
  }, [pathForRef]);

  return (
    <>
      {toUpdateHelmetFromCommonComponent ? <></> : helmet}
      {isUserLoggedin && (
        <div className="container-fluid">
          <Navbar />
          {/* <LivePrice /> */}
          <div className="main-content ">
            <div className="row my-0">
              <div
                className="futures-card m-0 col-12"
                style={{
                  overflowX: "hidden",
                }}
              >
                <div
                  className="row g-3 my-0"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  {isPageOrCard === "page" ? (
                    children
                  ) : shortComponent ? (
                    <div className="col-md-4 m-0">{children}</div>
                  ) : (
                    <div className="col-12 m-0">{children}</div>
                  )}
                  <div
                    className="col-12"
                    style={{
                      height: "10px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <Divider />
          </div>
          <div className="col-12">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default CommonCardViewer;
