import React, { useEffect, useRef, useState } from "react";
import VisOops from "../../vision/VisOops";
import QFLoader from "../../vision/QFLoader";
import derivativesStore, { clearOptionsLeg } from "./derivatives-store";
import OptionChainViewer from "./OptionChainViewer";
import {
  GetAngelOneLoginBtn,
} from "../../integrations/angel-one/angelOneHelper";
import VisDropDown from "../../vision/VisDropDown";

const indicesToDerive = ["NIFTY", "BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTYNXT50"];
const DerivativesOC = ({
  option,
  optionChain,
  optionExDates,
  underlying,
  strikePrices,
  underlyingValue,
  calculateNearestStrikePrice,
  apiChecker,
  optionsDate,
  setOption,
  setLoaderShow,
  setOptionChain,
  setOptionsDate,
  setApiChecker,
  setIsOneTimeAPICalled,
  loaderShow,
  errorShow,
}) => {
  const tableContainerRef = useRef(null);

  const [toScrollSetMiddleHR, setToScrollSetMiddleHR] = useState(-1);

  const [topThrees, setTopThrees] = useState({
    call: {
      byVolume: [],
      byOI: [],
    },
    put: {
      byVolume: [],
      byOI: [],
    },
  });


  const getTop3OIOTM = (optionChain, underlyingValue) => {
    if (!optionChain.data || !underlyingValue) return { topCallOI: [], topPutOI: [], topCallVol: [], topPutVol: [] };

    // Separate Call OTM and Put OTM
    const callOTM = [];
    const putOTM = [];

    Object.keys(optionChain.data).forEach((strikePrice) => {
      const price = parseFloat(strikePrice);
      const callData = optionChain.data[strikePrice]?.CE;
      const putData = optionChain.data[strikePrice]?.PE;

      if (price > underlyingValue && callData) {
        // Call is OTM when strikePrice > underlyingValue
        callOTM.push({ strikePrice: price, ...callData });
      }

      if (price < underlyingValue && putData) {
        // Put is OTM when strikePrice < underlyingValue
        putOTM.push({ strikePrice: price, ...putData });
      }
    });

    // Sort the OTM options by open interest (OI) in descending order and return the top 3
    const topCallOI = callOTM.sort((a, b) => b.openInterest - a.openInterest).slice(0, 3);

    const topPutOI = putOTM.sort((a, b) => b.openInterest - a.openInterest).slice(0, 3);

    // Sort the OTM options by total traded volume in descending order and return the top 3
    const topCallVol = callOTM.sort((a, b) => b.totalTradedVolume - a.totalTradedVolume).slice(0, 3);

    const topPutVol = putOTM.sort((a, b) => b.totalTradedVolume - a.totalTradedVolume).slice(0, 3);

    return { topCallOI, topPutOI, topCallVol, topPutVol };
  };

  useEffect(() => {
    if (optionChain.data && underlyingValue) {
      const { topCallOI, topPutOI, topCallVol, topPutVol } = getTop3OIOTM(optionChain, underlyingValue);
      setTopThrees({
        call: {
          byVolume: topCallVol.map((val) => val.strikePrice),
          byOI: topCallOI.map((val) => val.strikePrice),
        },
        put: {
          byVolume: topPutVol.map((val) => val.strikePrice),
          byOI: topPutOI.map((val) => val.strikePrice),
        },
      });
    }
  }, [optionChain, underlyingValue]);

  useEffect(() => {
    if (toScrollSetMiddleHR < 1 && calculateNearestStrikePrice > 0) {
      const tableContainer = tableContainerRef.current;
      const index = strikePrices.indexOf(calculateNearestStrikePrice);
      if (tableContainer) tableContainer.scrollTop = index * 45 + 25 - tableContainer.clientHeight / 2;
    }
  }, [toScrollSetMiddleHR, strikePrices, calculateNearestStrikePrice]);

  useEffect(() => {
    derivativesStore.dispatch(clearOptionsLeg());
  }, [option]);

  return (
    <div className="h-fill-available">
      <div
        className="tab-weight text-end mb-3"
        style={{
          zIndex: "2",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
          }}
          className="mt-1"
        >
          <GetAngelOneLoginBtn />
        </div>

        <VisDropDown
          selected={option}
          list={indicesToDerive}
          setter={(val) => {
            setOption(val);
            setLoaderShow(true);
            setOptionChain({ data: [], Exp: ["date"] });
            setOptionsDate(undefined);
            setToScrollSetMiddleHR(-1);
            setApiChecker(apiChecker + 1);
            setIsOneTimeAPICalled(false);
          }}
          isMultiple={true}
        />

        <VisDropDown
          selected={optionsDate || "date"}
          list={optionExDates}
          setter={(val) => {
            setOptionsDate(val);
            setLoaderShow(true);
            setOptionChain({});
            setToScrollSetMiddleHR(-1);
            setApiChecker(apiChecker + 1);
            setIsOneTimeAPICalled(false);
          }}
          isMultiple={true}
          className="ms-2"
        />
      </div>

      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : !optionChain || optionChain == {} || !optionChain.data ? (
        <></>
      ) : (
        <>
          <div
            className="row p-0 m-0 opt-chain section-bb"
            ref={tableContainerRef}
            style={{
              overflow: "hidden",
            }}
          >
            <OptionChainViewer
              optionChain={optionChain}
              topThrees={topThrees}
              underlying={underlying}
              underlyingValue={underlyingValue}
              strikePrices={strikePrices}
              toScrollSetMiddleHR={toScrollSetMiddleHR}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DerivativesOC;
