import { angelOneConfigs, getAngelOneWebSocketHeaders } from "./angelOneHelper";

const webSocketWorker = new Worker("/web-workers/webSocketWorker.js?");

export class AngelOneWebSocketSmartStream {
  constructor() {
    this.url = angelOneConfigs.wsBaseUrl;
    this.headers = getAngelOneWebSocketHeaders();
    this.listeners = [];
    this.isConnected = false; // Track connection state
    this.pingInterval = null; // For keeping the connection alive

    // Handle messages from the WebSocket worker
    webSocketWorker.onmessage = (e) => {
      const { action, data, error, reason } = e.data;

      switch (action) {
        case "connected":
          this.isConnected = true;
          console.log("WebSocket connected");
          this.startPing(); // Start pinging when connected
          this.onopen && this.onopen();
          break;
        case "parsedData":
          this.notifyListeners(data); // Notify listeners with parsed data from the data parser worker
          break;
        case "message":
          if (data === "pong") {
            console.log("WebSocket Connection is up..!");
          } else {
            this.notifyListeners(data);
          }
          break;
        case "error":
          console.error("Error from websocket worker:", error);
          this.onerror && this.onerror(error); // Trigger onerror callback if defined
          this.isConnected = false;
          this.stopPing(); // Stop pinging on error
          break;
        case "disconnected":
          console.log("WebSocket disconnected:", reason);
          this.isConnected = false;
          this.stopPing(); // Stop pinging when disconnected
          break;
        case "messageSent":
          console.log("messageSent action - lookout");
          break;
        default:
          console.error("Unknown action from worker:", action);
      }
    };
  }

  startPing() {
    this.stopPing(); // Ensure no duplicate intervals
    this.pingInterval = setInterval(() => {
      if (this.isConnected) {
        this.sendMessage("ping");
        console.log("Ping sent");
      }
    }, 30000); // Send ping every 30 seconds
  }

  stopPing() {
    clearInterval(this.pingInterval);
    this.pingInterval = null;
  }

  // Method to notify listeners
  notifyListeners(data) {
    this.listeners.forEach((listener) => listener(data));
  }

  // Method to add a listener
  addListener(listener) {
    this.listeners.push(listener);
  }

  removeListener(callback) {
    this.listeners = this.listeners.filter((listener) => listener !== callback);
  }

  // Method to connect to the WebSocket
  connect() {
    webSocketWorker.postMessage({
      action: "connect",
      url: this.url,
      headers: this.headers,
    });
  }

  // Method to send a message to the WebSocket
  sendMessage(message) {
    webSocketWorker.postMessage({
      action: "send",
      message,
    });
  }

  // Method to disconnect from the WebSocket
  disconnect() {
    webSocketWorker.postMessage({ action: "disconnect" });
  }
}
