import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import EodBandChanges from "./components/NSEComponents/EodBandChanges";

const Embed = () => {
  const component = useParams().component;
  useEffect(() => {
    let ems = document.getElementsByClassName("hide-in-embed");
    for (let i = 0; i < ems.length; i++) {
      ems[i].style.display = "none";
    }
  }, []);
  return (
    <div className="row w-100">
      <div className="col-12">{component === "bandChanges" && <EodBandChanges />}</div>
    </div>
  );
};

export default Embed;
