import { nseBase } from "./configs";
import { hawkReport, hawkReportTypeIdentifiers } from "./hawkeye/hawkHelper";
export const backURL = nseBase;

import { apiWorkerInstance } from "./apiWorkerHelper";

export const errorMsg = "Contact Server Admin || Something unexpected happen";

export const CACHE_PREFIX = "cache://api.qf-";

export async function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + 5.5 * 60 * 60 * 1000 + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(c_name) {
  const cookiesArray = document.cookie.split(";"); // Split cookies by ';' to create an array
  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i].trim(); // Remove leading spaces
    if (cookie.startsWith(c_name + "=")) {
      return unescape(cookie.substring(c_name.length + 1)); // Return the value of the cookie
    }
  }
  return ""; // Return an empty string if the cookie is not found
}

// const getSha = (url) => `${CACHE_PREFIX}${sha1(`${CACHE_PREFIX}${url}`)}`;
// Function to get cookies in the main thread
const getCookies = () => {
  return {
    csrftoken: getCookie("csrftoken"),
    access_token: getCookie("access_token"),
    broker_key: getCookie("angelone_access_token"),
  };
};

export const commonApiGet = async (url, invoker = "COMP-NOT-GIVEN", toCache = true, inbulitReplacer = true, retries = 2) => {
  const cookies = getCookies();
  const finalUrl = inbulitReplacer ? url.replace("%", "%25") : url;
  return apiWorkerInstance.callApi({
    type: "GET",
    url: finalUrl,
    toCache,
    cookies,
    retries
  });
};

export const commonApiPost = async (url, data) => {
  const cookies = getCookies();
  return apiWorkerInstance.callApi({ type: "POST", url, data, cookies });
};

export const commonApiPostRB = async (url, data, toCache = true) => {
  const cookies = getCookies();
  return apiWorkerInstance.callApi({
    type: "POST",
    url,
    data,
    toCache,
    cookies,
  });
};

export const commonApiPut = async (url, data) => {
  const cookies = getCookies();
  return apiWorkerInstance.callApi({ type: "PUT", url, data, cookies });
};

export const commonApiDelete = async (url, data = null) => {
  const cookies = getCookies();
  return apiWorkerInstance.callApi({ type: "DELETE", url, data, cookies });
};

// export const postApiReqHandlation = async (err, additionalData, toRemoveSession = true) => {
//   console.error("API Call Error:", err);
//   if (err.response?.status === 401 && toRemoveSession) {
//     location.replace(`/signin?callback=${location.pathname}`);
//   }
//   throw err;
// };

// export const fileUploadApi = async (url, data) => {
//   console.log(
//     `QF-JOURNAL-${new Date().getTime()}_QF-APICALL: fileUploadApi_${url}`
//   );

//   return await req
//     .post(url, data, {
//       headers: {
//         "content-type": "multipart/form-data",
//       },
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch(async (err) => {
//       await postApiReqHandlation(err);
//     });
// };

export const removeCookieUserSession = () => {
  document.cookie.split(";").forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.quibblefrost.in";
  });
};

export const removeUserSession = (to = "", navigate = true) => {
  removeCookieUserSession();
  // location.replace(backURL + `/auth/signout`);
  if (navigate) location.replace(`/${to}`);
};

export const postApiReqHandlation = async (err, additionalData, toRemoveSession, isToThrowError) => {
  console.error("QF-JOURNAL-ERROR-API-CALL", err);
  hawkReport(err, additionalData, hawkReportTypeIdentifiers.apiError);
  const response = err.response;
  if (response?.status === 401 || response?.status === 602 || response?.status === 603 || response?.status === 604) {
    if (toRemoveSession) {
      removeUserSession(`signin?callback=${location.pathname}`);
    }
    if (isToThrowError) throw err;
  } else {
    if (isToThrowError) throw err;
  }
};
