import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react"; // Import ECharts component
import derivativesStore, { addOptionLeg, clearOptionsLeg } from "../derivatives-store";
import OptionLeg from "./OptionLeg";
import { calcPayoff } from "./utils";
import { convertDaysToYears } from "./utils";
import { getDaysToExpiry } from "./utils";
import { chartColor, findIndexAll, themedTextColor } from "../../../../utils/configs";
import { useSelector } from "react-redux";
import {
  calculateMaxPain,
  calculatePOP,
  calculatePOPNew,
  calculatePOPNew2,
  calculatePOPNewwo,
  calculateProfitLoss,
  maxPrLoss,
  minMaxProfitFromPayoffs,
} from "../calculationHelpers";
import { deleteStrategy, getSavedStrategy, handleSaveStrategy, handleUpdateStrategy } from "./StrategyPaperTrade";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import captureAndShare from "../../../vision/captureAndShare";
import { angelOneApiPost } from "../../../integrations/angel-one/angelOneHelper";
import { notificationTopup } from "../../../../utils/NotificationTopup";

const PayoffChart = ({ underlying, optionChain, optionsDate, selectedIndex }) => {
  const theme = useSelector((store) => store.theme);
  const [updatedStore, setUpdatedStore] = useState(derivativesStore.getState());
  const [targetDate, setTargetDate] = useState(new Date());
  const underlyingValue = underlying.underlyingValue;
  const [lotSize, setLotSize] = useState(underlying.lotsize || 0);
  const [strategyName, setStrategyName] = useState("");
  const [strategyDesc, setStrategyDesc] = useState("");
  const [savedStrategy, setSavedStrategy] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState({});
  const [dropdown, setDropdown] = useState(false);
  const [isSavedStrategyLoaded, setIsSavedStrategyLoaded] = useState(false);
  const [suggestedStrategydropdown, setSuggestedStrategydropdown] = useState(false);
  const [suggestedStrategy, setSuggestedStrategy] = useState({});

  const ddtoggle = useCallback(() => setDropdown((prevState) => !prevState), []);

  const suggestddtoggle = useCallback(() => setSuggestedStrategydropdown((prevState) => !prevState), []);

  const connectedBroker = useSelector((state) => state.connectedBroker);

  const [requiredMargin, setRequiredMargin] = useState(0);

  const checkMargin = async () => {
    if (!connectedBroker?.angelOne || updatedStore.optionLegs.length === 0) return;
    await angelOneApiPost("/rest/secure/angelbroking/margin/v1/batch", {
      positions: updatedStore.optionLegs.map((val) => ({
        exchange: "NFO",
        qty: parseInt(val.lots) * parseInt(lotSize),
        price: parseFloat(val.tradedPrice),
        productType: "CARRYFORWARD",
        token: val.token,
        tradeType: val.action === "B" ? "BUY" : "SELL",
      })),
    })
      .then((res) => {
        setRequiredMargin(res?.data?.data?.totalMarginRequired.toFixed(2) || 0);
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkMargin();
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup function to clear the interval on unmount
    return () => clearInterval(intervalId);
  }, [updatedStore.optionLegs.length, connectedBroker?.angelOne]);

  useEffect(() => {
    if (suggestedStrategy.name === undefined) return;
    setStrategyName(suggestedStrategy.name);
    setStrategyDesc(suggestedStrategy.name);
    derivativesStore.dispatch(clearOptionsLeg());
    setSelectedStrategy({});
    setIsSavedStrategyLoaded(false);

    const addLegs = async () => {
      for (const val of suggestedStrategy.legs || []) {
        await derivativesStore.dispatch(
          addOptionLeg({
            ...optionChain.find((ocs) => ocs.optionType == val.type && ocs.strikePrice == val.strike),
            action: String(val.action).toUpperCase() === "SELL" ? "S" : "B",
          })
        );
      }
      // Now that all legs have been added, you can log the updated state
      // console.log(derivativesStore.getState().optionLegs);
    };

    addLegs();
  }, [suggestedStrategy]);

  useEffect(() => {
    const fetchSavedStrategy = async () => {
      await getSavedStrategy(setSavedStrategy);
    };
    fetchSavedStrategy();
  }, []);

  useEffect(() => {
    if (selectedStrategy.name === undefined) return;
    setStrategyName(selectedStrategy.name);
    setStrategyDesc(selectedStrategy.desc);
    setIsSavedStrategyLoaded(true);
    setSuggestedStrategy({});
    derivativesStore.dispatch(clearOptionsLeg());

    const addLegs = async () => {
      for (const val of selectedStrategy.legs || []) {
        await derivativesStore.dispatch(
          addOptionLeg({
            ...val,
            legIdInDb: val.id,
            tradedPrice: val.lastPrice,
            action: val.side,
            changeinOpenInterest: parseFloat(val.changeinOpenInterest),
            pchangeinOpenInterest: parseFloat(val.pchangeinOpenInterest),
            openInterest: parseFloat(val.openInterest),
            impliedVolatility: parseFloat(val.impliedVolatility),
            totalTradedVolume: parseFloat(val.totalTradedVolume),
            totalBuyQuantity: parseFloat(val.totalBuyQuantity),
            totalSellQuantity: parseFloat(val.totalSellQuantity),
            change: parseFloat(val.change),
            pChange: parseFloat(val.pChange),
            lastPrice: parseFloat(
              optionChain.find((ocs) => ocs.optionType == val.optionType && ocs.strikePrice == val.strikePrice)?.lastPrice
            ),
            bidprice: parseFloat(val.bidprice),
            askPrice: parseFloat(val.askPrice),
          })
        );
      }
      // Now that all legs have been added, you can log the updated state
      // console.log(derivativesStore.getState().optionLegs);
    };

    addLegs();
  }, [selectedStrategy]);

  useEffect(() => {
    const unsubscribe = derivativesStore.subscribe(() => {
      setUpdatedStore(derivativesStore.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (optionChain && underlying.underlying) {
      // setUnderlyingValue(underlying.underlyingValue);
      setLotSize(underlying.lotsize);
    }
  }, [optionChain]);

  const strikePriceRange = parseInt(underlyingValue * 0.025) || 1000;
  const strikePriceInterval = 0.05;
  const baseStrikePrice = Math.floor(underlyingValue / strikePriceInterval) * strikePriceInterval - strikePriceRange;

  const calculatePayoff = () => {
    const strikePrices = Array.from(
      { length: (strikePriceRange / strikePriceInterval) * 2 },
      (_, i) => baseStrikePrice + i * strikePriceInterval
    );

    const totalPayoffs = strikePrices.map(() => 0);
    const targetDatePayoffs = strikePrices.map(() => 0);

    updatedStore.optionLegs.forEach((leg) => {
      const { strikePrice, optionType, lastPrice, action, expiryDate, impliedVolatility, theta, lots, tradedPrice } = leg;

      const expiry = new Date(expiryDate);
      const timeToExpiry = convertDaysToYears(getDaysToExpiry(expiry, targetDate));

      const legPayoffs = strikePrices.map((strike) => {
        let intrinsicValue = 0;

        if (optionType === "CE") {
          intrinsicValue = Math.max(strike - strikePrice, 0); // Call intrinsic value
        } else if (optionType === "PE") {
          intrinsicValue = Math.max(strikePrice - strike, 0); // Put intrinsic value
        }

        return action === "B"
          ? (intrinsicValue - tradedPrice) * lotSize * lots
          : (tradedPrice - intrinsicValue) * lotSize * lots;
      });

      const legPayoffsTarget = strikePrices.map((strike) => {
        // console.log(lastPrice)
        return calcPayoff(
          optionType === "CE" ? "c" : "p", // Convert option type to 'c' or 'p'
          strike, // Use strike price as the futures price for payoff calculation
          strikePrice,
          timeToExpiry,
          impliedVolatility / 100,
          lastPrice,
          lots, // Assuming 1 lot for simplicity
          lotSize, // Assuming lot size of 1 for simplicity
          action
        );
      });

      const legTargetDatePayoffs = legPayoffsTarget.map((payoff) => {
        // Calculate time decay
        const totalTimeDecay = theta * timeToExpiry;

        // Adjust the payoff for target date
        return payoff - totalTimeDecay; // Adjust based on time decay
      });

      legPayoffs.forEach((payoff, index) => {
        totalPayoffs[index] += payoff;
      });
      legPayoffsTarget.forEach((payoff, index) => {
        targetDatePayoffs[index] += legTargetDatePayoffs[index];
      });
    });

    return {
      strikePrices,
      totalPayoffs: totalPayoffs.map((val) => val.toFixed(2)),
      targetDatePayoffs: targetDatePayoffs.map((val) => val.toFixed(2)),
      totalPNL: updatedStore.optionLegs.reduce((total, val) => {
        const marginCalc = (val.lots * lotSize * val.tradedPrice).toFixed(2);
        const pnlCalc = (val.lots * lotSize * val.lastPrice).toFixed(2) - marginCalc;
        return total + parseFloat(pnlCalc); // Convert to number to avoid string concatenation
      }, 0),
    };
  };

  const { strikePrices, totalPayoffs, targetDatePayoffs, totalPNL } = calculatePayoff();

  const breakEvens = findIndexAll(totalPayoffs, (x) => x == 0);
  const { POP } = calculatePOP(strikePrices, totalPayoffs);

  const positivePayoffs = totalPayoffs.map((payoff) => (payoff >= 0 ? payoff : null));
  const negativePayoffs = totalPayoffs.map((payoff) => (payoff < 0 ? payoff : null));

  const nearestStrikeBelow = Math.floor(underlyingValue / strikePriceInterval) * strikePriceInterval;
  const lineDecAbove =
    strikePrices.indexOf(nearestStrikeBelow) + (underlyingValue - nearestStrikeBelow) / strikePriceInterval;

  // Extract unique strike prices
  const ocSp = [...new Set(optionChain?.map((val) => val.strikePrice))];

  // Initialize objects to store aggregated open interest
  const callOIMap = {};
  const putOIMap = {};

  // const mpl = maxPrLoss(updatedStore.optionLegs, lotSize);
  const mpl = minMaxProfitFromPayoffs(totalPayoffs);

  // Iterate over the optionChain to aggregate open interest
  optionChain?.forEach((val) => {
    if (val.optionType === "CE") {
      callOIMap[val.strikePrice] = (callOIMap[val.strikePrice] || 0) + val.openInterest;
    } else if (val.optionType === "PE") {
      putOIMap[val.strikePrice] = (putOIMap[val.strikePrice] || 0) + val.openInterest;
    }
  });

  // Create arrays for call and put open interest based on unique strike prices
  const ocCOI = ocSp.map((strike) => callOIMap[strike] || 0);
  const ocPOI = ocSp.map((strike) => putOIMap[strike] || 0);

  // Calculate max pain
  const maxPain = calculateMaxPain(ocSp, ocCOI, ocPOI);
  const maxPainInd = strikePrices.indexOf(maxPain);

  const pop = calculatePOPNewwo(
    updatedStore.optionLegs.map((val) => {
      return {
        strike: val.strikePrice,
        delta: val.delta,
        action: val.action,
        type: val.optionType,
      };
    })
  );

  const chartOptions = {
    tooltip: {
      trigger: "axis",
      className: "customEchartohlcTooltip glassmorfy-it general-card-1",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
      formatter: (params) => {
        // Filter out series with undefined or null values
        const filteredParams = params.filter((val) => val.value !== undefined && val.value !== null);

        // If there are no valid params, return a message indicating no data
        if (filteredParams.length === 0) {
          return "No data available";
        }

        // Generate tooltip content
        let tooltipContent = `<strong>Strike Price: ${parseFloat(params[0].axisValue).toFixed(2)}</strong><br/>`;

        filteredParams.forEach((param) => {
          const seriesName = param.seriesName;
          const value = param.value;
          const color = param.color || "black"; // Default to black if no color is provided
          // Check if the series has a positive or negative value for proper display
          tooltipContent += `
            <span style="color:${color} !important;">●</span> ${seriesName}: 
            <strong>₹ ${value !== null && value !== undefined ? value : "N/A"}</strong><br/>
          `;
        });
        tooltipContent += `
          <span style="color:${chartColor.yellow} !important;">●</span> Max Pain: 
          <strong>${maxPain}</strong><br/>
        `;
        tooltipContent += `
        <br/>
        <strong>Probability of Profit: ${POP.toFixed(2)}%</strong>
      `;
        return tooltipContent;
      },
    },
    legend: {
      data: ["Current Payoff", "Target Date Payoff", "Max Pain"],
      top: "2%",
      left: "center",
      textStyle: {
        fontSize: 14,
        color: themedTextColor[theme],
      },
    },
    grid: {
      left: "5%",
      right: "5%",
      bottom: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: strikePrices,

      name: "",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
      },
      axisLabel: {
        formatter: (value) => parseFloat(value).toFixed(2),
        color: themedTextColor[theme],
        fontSize: 12,
      },

      splitLine: { show: false },
    },
    yAxis: {
      type: "value",
      name: "",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
      },
      axisLabel: {
        formatter: (value) => `${value} ₹`,
        color: themedTextColor[theme],
        fontSize: 12,
      },

      splitLine: { show: false },
    },
    series: [
      {
        showSymbol: false, // Removes dots from the line
        name: "Current Payoff",
        type: "line",
        data: positivePayoffs,
        itemStyle: {
          color: "green",
        },
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: "rgba(0, 255, 0, 0.2)",
        },
        smooth: true,
        markLine: {
          symbol: "none",
          data: [
            {
              name: "Underlying Price",
              xAxis: lineDecAbove,
              lineStyle: {
                type: "dashed",
                color: themedTextColor[theme],
                width: 2,
              },
              label: {
                formatter: () => `${selectedIndex}: ${underlyingValue}`,
                color: themedTextColor[theme],
              },
            },
            {
              name: "",
              yAxis: 0,
              lineStyle: {
                type: "solid",
                color: chartColor.green,
                width: 2,
              },
              label: {
                formatter: () => ``,
                show: false,
              },
            },
          ],
        },
      },
      {
        animation: false,
        name: "Max Pain", // Dummy series for the markLine
        type: "line",
        data: [], // No data, as this is just for the legend
        lineStyle: {
          type: "dashed",
          color: chartColor.yellow,
          width: 2,
        },
        markLine: {
          symbol: "none",
          data: [
            {
              xAxis: maxPainInd,
              name: "Max Pain",

              lineStyle: {
                type: "dashed",
                color: chartColor.yellow,
                width: 2,
              },
              label: {
                formatter: () => `Max Pain: ${maxPain}`,
                color: themedTextColor[theme],
                show: false,
              },
            },
          ],
        },
      },
      {
        showSymbol: false, // Removes dots from the line
        name: "Current Payoff",
        type: "line",
        data: negativePayoffs,
        itemStyle: {
          color: "red",
        },
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: "rgba(255, 0, 0, 0.2)",
        },
        smooth: true,
      },
      {
        showSymbol: false, // Removes dots from the line

        name: "Target Date Payoff",
        type: "line",
        data: targetDatePayoffs,
        itemStyle: {
          color: "#0dcaf0",
        },
        lineStyle: {
          width: 2,
          type: "solid",
        },
        smooth: true,
      },
    ],
  };

  const formatDateToLocalString = (date) => {
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const getMinDate = () => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 1); // Set min to -1 day
    return minDate.toISOString().slice(0, 16);
  };

  const getMaxDate = () => {
    if (optionsDate === undefined) {
      return getMinDate();
    }
    const maxDate = new Date(optionsDate);
    maxDate.setDate(maxDate.getDate() + 1); // Set max to +1 day
    return maxDate.toISOString().slice(0, 16);
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
  };

  return (
    <div
      className="py-3 non-trans-bg"
      style={{
        minHeight: "300px",
      }}
      id="payoffChart"
    >
      {/* <span className="pe-2">
        <i
          className="fas fa-share-nodes"
          onClick={() => {
            captureAndShare("payoffChart", "asd", "das");
          }}
        />
      </span> */}
      
      <ButtonDropdown isOpen={dropdown} toggle={ddtoggle}>
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-btn our-border text-color-themed mt-1 mx-2"
          style={{ fontSize: "12px" }}
        >
          {selectedStrategy.name || "Select OR Create New Strategy"}
        </DropdownToggle>
        <DropdownMenu
          className="general-btn our-border non-trans-bg"
          style={{ maxHeight: "220px", overflowY: "scroll", zIndex: 2 }}
        >
          {savedStrategy.map((val, key) => (
            <DropdownItem
              key={key}
              className="bg-set-on-active text-color-themed"
              onClick={() => {
                setSelectedStrategy(val);
              }}
              disabled={val.name === selectedStrategy.name}
              style={{ fontSize: "12px" }}
            >
              {val.name}
            </DropdownItem>
          ))}
          <DropdownItem
            className="bg-set-on-active text-color-themed"
            onClick={() => {
              setSelectedStrategy({});
              setSuggestedStrategy({});
              derivativesStore.dispatch(clearOptionsLeg());
              setIsSavedStrategyLoaded(false);
              setStrategyName("");
              setStrategyDesc("");
            }}
            style={{ fontSize: "12px" }}
          >
            Create New
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      <ButtonDropdown isOpen={suggestedStrategydropdown} toggle={suggestddtoggle}>
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-btn our-border text-color-themed mt-1 mx-2"
          style={{ fontSize: "12px" }}
        >
          {suggestedStrategy.name || "Load Suggested Strategy"}
        </DropdownToggle>
        <DropdownMenu
          className="general-btn our-border non-trans-bg"
          style={{ maxHeight: "220px", overflowY: "scroll", zIndex: 2 }}
        >
          {underlying.strategy.map((val, key) => (
            <DropdownItem
              key={key}
              className="bg-set-on-active text-color-themed"
              onClick={() => {
                setSuggestedStrategy(val);
              }}
              disabled={val.name === selectedStrategy.name}
              style={{ fontSize: "12px" }}
            >
              {val.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>

      <br />
      <br />

      {updatedStore.optionLegs.length > 0 ? (
        <>
          <ReactECharts option={{ ...chartOptions }} style={{ height: "320px", width: "100%" }} />
          <div className="row pt-2 px-2  zoo-80">
            <div className="col-12">
              <table className="w-100 strategy-analyser-points-table">
                <tbody>
                  <tr>
                    <td className="px-2">Target Date:</td>
                    <td>
                      <input
                        type="datetime-local"
                        className="qfInputType p-2 mt-2"
                        style={{
                          maxWidth: "250px",
                        }}
                        value={formatDateToLocalString(targetDate)}
                        // onChange={(e) => setTargetDate(new Date(e.target.value))}
                        onChange={(e) => {
                          const selectedDate = new Date(e.target.value);
                          if (!isWeekend(selectedDate)) {
                            setTargetDate(selectedDate);
                          }
                        }}
                        min={getMinDate()}
                        max={getMaxDate()}
                        onInvalid={(e) => {
                          if (isWeekend(new Date(e.target.value))) {
                            e.target.setCustomValidity("Please select a weekday.");
                          } else {
                            e.target.setCustomValidity("");
                          }
                        }}
                      />
                    </td>
                    <td className="text-end">Margin:</td>
                    <td className="ps-3">
                      {requiredMargin}
                      <i
                        className="fas fa-arrow-rotate-left ps-2"
                        onClick={() => {
                          checkMargin();
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="text-end">Max Loss:</td>
                    <td className="ps-3">{mpl[1]}</td>
                  </tr>
                  <tr>
                    <td className="px-2">Max Pain:</td>
                    <td>{maxPain}</td>
                    <td className="text-end">Max Profit:</td>
                    <td className="ps-3">{mpl[0]}</td>
                  </tr>
                  <tr>
                    <td className="px-2">Spread: </td>
                    <td>{breakEvens.map((points) => parseFloat(strikePrices[points]).toFixed(2)).join("-")}</td>
                    <td className="text-end">Total PNL:</td>
                    <td className={`ps-3 ${totalPNL < 0 ? "text-danger" : ""}`}>₹{totalPNL}</td>
                  </tr>
                  {/* <tr>
                    <td className="px-2">POP:</td>
                    <td>{pop}</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <hr />
          <div className="row px-3  zoo-80">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (isSavedStrategyLoaded && selectedStrategy.id !== undefined) {
                  handleUpdateStrategy(
                    updatedStore.optionLegs,
                    selectedIndex,
                    strategyName,
                    strategyDesc,
                    selectedStrategy.id
                  );
                } else {
                  handleSaveStrategy(updatedStore.optionLegs, selectedIndex, strategyName, strategyDesc);
                }
              }}
            >
              <div className="row">
                <div className="col-md-4 col-6 vert-center py-1">
                  <input
                    type="text"
                    className="qfInputType px-3 py-1"
                    placeholder="Name Your Strategy"
                    value={strategyName}
                    onChange={(e) => {
                      setStrategyName(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="col-md-4 col-6  vert-center py-1">
                  <input
                    type="text"
                    className="qfInputType px-3 py-1"
                    placeholder="Write something about it..?"
                    value={strategyDesc}
                    onChange={(e) => {
                      setStrategyDesc(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="col-md-4 vert-center py-1">
                  {isSavedStrategyLoaded ? (
                    <>
                      <div className="row">
                        <div className="col-6">
                          <button type="submit" className="my-3 w-100 btn btn-success btn-sm">
                            UPDATE
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            type="button"
                            className="my-3 w-100 btn btn-danger btn-sm"
                            onClick={() => {
                              deleteStrategy(selectedStrategy.id);
                            }}
                          >
                            DELETE
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <button type="submit" className="my-3 w-100 btn btn-success btn-sm">
                      SAVE
                    </button>
                  )}
                </div>
              </div>
            </form>
            {updatedStore.optionLegs.map((leg, index) => (
              <div key={index} className="col-md-6 ">
                <OptionLeg
                  {...leg}
                  deleteIndex={index}
                  lotSize={lotSize}
                  optionChain={optionChain}
                  savedStrategyConfig={{
                    isSavedStrategyLoaded,
                    strategyId: selectedStrategy.id || undefined,
                    legId: leg.legIdInDb || undefined,
                  }}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center pt-2">Add Trade to see payoff chart</div>
      )}
    </div>
  );
};

export default PayoffChart;
