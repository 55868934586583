import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../../utils/api";
import { groupINRCurrencyNSE } from "../../../utils/functions";
import { chartColor } from "../../../utils/configs";
import VisOops from "../../vision/VisOops";
import QFLoader from "../../vision/QFLoader";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { VisDropDownInlineTransparent } from "../../vision/VisDropDown";

const FiiDiiPageCMProvisional = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [maxVal, setMaxVal] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Day");
  const [selectedOptionI, setSelectedOptionI] = useState("FII");

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/analysis/fii_dii/cm?recType=${selectedOptionI.toLowerCase()}&timespan=${selectedOption[0]}`,
        "FII_DII_CM_PROVISIONAL"
      )
        .then((res) => {
          setData(res.data);
          setLoading(false);
          setError(false);
          setMaxVal(Math.max(...res.data.map((item) => Math.abs(item.NetValue))));
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          notificationTopup(err.message);
        });
    })();
  }, [selectedOption, selectedOptionI]);
  return (
    <div className="w-100 h-100">
      {loading ? (
        <QFLoader />
      ) : error ? (
        <VisOops />
      ) : (
        <table className="w-100">
          <thead className="section-bb">
            <tr className=" non-trans-bg">
              <td>
                <VisDropDownInlineTransparent
                  selected={selectedOption}
                  setter={(val) => {
                    setSelectedOption(val);
                    setLoading(true);
                  }}
                  list={["Day", "Month", "Year"]}
                />
              </td>
              <td>
                Net Value
                <div className="d-md-none d-block" style={{ fontSize: "var(--fnt-smaller)" }}>
                  Nifty Val
                </div>
              </td>
              <td className="w-50 text-center">
                <VisDropDownInlineTransparent
                  selected={selectedOptionI}
                  setter={(val) => {
                    setSelectedOptionI(val);
                    setLoading(true);
                  }}
                  list={["FII", "DII"]}
                />
              </td>
              <td className="text-end d-none d-md-block">Nifty Val</td>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.DateText}</td>
                <td
                  style={{
                    color: item.NetValue < 0 ? chartColor.red : chartColor.green,
                  }}
                >
                  {groupINRCurrencyNSE(item.NetValue, true)}Cr.
                  <div className="d-md-none d-block" style={{ fontSize: "var(--fnt-smaller)" }}>
                    <span className="text-color-themed">₹{item.NiftyC} </span>
                    <span style={{ color: item.NiftyCZG > 0 ? chartColor.green : chartColor.red }}>({item.NiftyCZG}%)</span>
                  </div>
                </td>
                <td>
                  <div className="row py-2">
                    <div className="col-6 p-0 m-0 text-end vert-center">
                      {item.NetValue < 0 ? (
                        <span
                          className=" my-1 align-self-end"
                          style={{
                            width: `${(Math.abs(item.NetValue) / maxVal) * 100}%`,
                            backgroundColor: chartColor.red,
                            height: "10px",
                          }}
                        ></span>
                      ) : null}
                    </div>
                    <div className="col-6 section-bl p-0 m-0 vert-center">
                      {item.NetValue > 0 ? (
                        <span
                          className="my-1"
                          style={{
                            width: `${(Math.abs(item.NetValue) / maxVal) * 100}%`,
                            backgroundColor: chartColor.green,
                            height: "10px",
                          }}
                        ></span>
                      ) : null}
                    </div>
                  </div>
                </td>
                <td className="text-end d-none d-md-block">
                  {item.NiftyC}{" "}
                  <span style={{ color: item.NiftyCZG > 0 ? chartColor.green : chartColor.red }}>({item.NiftyCZG}%)</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FiiDiiPageCMProvisional;
