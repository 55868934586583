import { chartColor, themedTextColor } from "../../../../utils/configs";
import { groupINRCurrencyNSE } from "../../../../utils/functions";

export const chartConfigs = ({
  theme,
  ceOpenInterest,
  peOpenInterest,
  ceChangeInOpenInterest,
  peChangeInOpenInterest,
  ceIV,
  peIV,
  combinedIV,
  pcrData,
  pcdData,
  pcr,
  pcd,
  ceBuy,
  ceSell,
  peBuy,
  peSell,
  ceVol,
  peVol,
}) => {
  return [
    {
      title: "Open Interest",
      initialserieses: [
        {
          showSymbol: false,
          name: "Call Options (CE)",
          type: "bar",
          data: ceOpenInterest,
          itemStyle: {
            color: chartColor.green,
          },
        },
        {
          showSymbol: false,
          name: "Put Options (PE)",
          type: "bar",
          data: peOpenInterest,
          itemStyle: {
            color: chartColor.red,
          },
        },
      ],
    },
    {
      title: "Change in OI",
      initialserieses: [
        {
          showSymbol: false,
          name: "Call Options (CE)",
          type: "bar",
          data: ceChangeInOpenInterest,
          itemStyle: {
            color: chartColor.green,
          },
        },
        {
          showSymbol: false,
          name: "Put Options (PE)",
          type: "bar",
          data: peChangeInOpenInterest,
          itemStyle: {
            color: chartColor.red,
          },
        },
      ],
    },
    {
      title: "Implied Volatility",
      isZoom: false,
      initialserieses: [
        {
          showSymbol: false,
          name: "Call Options (CE)",
          type: "line",
          data: ceIV,
          itemStyle: {
            color: chartColor.green,
          },
        },
        {
          showSymbol: false,
          name: "Put Options (PE)",
          type: "line",
          data: peIV,
          itemStyle: {
            color: chartColor.red,
          },
        },
        {
          showSymbol: false,
          name: "Combined IV",
          type: "line",
          data: combinedIV,
          smooth: true,
          itemStyle: { color: chartColor.blue },
          lineStyle: { type: "solid", width: 2 },
        },
      ],
      extraConfs: {
        legend: {
          data: ["Call Options (CE)", "Put Options (PE)", "Combined IV"],
          selected: {
            "Call Options (CE)": false,
            "Put Options (PE)": false,
            "Combined IV": true,
          },
          textStyle: {
            fontSize: 14,
            color: themedTextColor[theme],
          },
        },
      },
    },
    {
      title: `Put Call Ratio: ${pcr.toFixed(2)}`,
      initialserieses: [
        {
          showSymbol: false,
          name: "PCR",
          type: "bar",
          data: pcrData,
          itemStyle: {
            color: chartColor.yellow,
          },
        },
      ],
    },
    {
      title: `Put Call Diff: ${groupINRCurrencyNSE(pcd)}`,
      initialserieses: [
        {
          showSymbol: false,
          name: "PCD",
          type: "bar",
          data: pcdData,
          itemStyle: {
            color: chartColor.blue,
          },
        },
      ],
    },
    {
      title: "Buy / Sell quantity",

      initialserieses: [
        {
          showSymbol: false,
          name: "CE (Buy)",
          type: "line",
          stack: "CE",
          data: ceBuy,
          itemStyle: {
            color: chartColor.green,
          },
        },
        {
          showSymbol: false,
          name: "CE (Sell)",
          type: "line",
          stack: "CE",
          data: ceSell,
          itemStyle: {
            color: chartColor.red,
          },
        },
        {
          showSymbol: false,
          name: "PE (Buy)",
          type: "line",
          stack: "PE",
          data: peBuy,
          itemStyle: {
            color: chartColor.yellow,
          },
        },
        {
          showSymbol: false,
          name: "PE (Sell)",
          type: "line",
          stack: "PE",
          data: peSell,
          itemStyle: {
            color: chartColor.blue,
          },
        },
      ],
      extraConfs: {
        legend: {
          data: ["CE (Buy)", "CE (Sell)", "PE (Buy)", "PE (Sell)"],
          textStyle: {
            fontSize: 14,
            color: themedTextColor[theme],
          },
        },
      },
    },
    {
      title: "Total traded volume",
      initialserieses: [
        {
          showSymbol: false,
          name: "Call Options (CE)",
          type: "bar",
          data: ceVol,
          itemStyle: {
            color: chartColor.green,
          },
        },
        {
          showSymbol: false,
          name: "Put Options (PE)",
          type: "bar",
          data: peVol,
          itemStyle: {
            color: chartColor.red,
          },
        },
      ],
    },
  ];
};
