import React, { useCallback, useEffect, useRef, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import QFLoader from "../vision/QFLoader";
import VisOops from "../vision/VisOops";
import { camelCaseToSentence, chartColor, isMobile } from "../../utils/configs";
import { groupINRCurrencyNSE } from "../../utils/functions";
import { useParams } from "react-router-dom";

const IpoData = () => {
  const [ipoData, setIpoData] = useState([]);
  const options = ["ongoing", "upcoming", "listed"];
  const [selectedOption, setSelectedOption] = useState(useParams().ipoType || options[0]);

  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  const isMobileView = isMobile();

  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const colRef = useRef(null);
  const fetchData = useCallback(
    async (page) => {
      setLoaderShow(true);
      try {
        const res = await commonApiGet(`/market/ipo/${selectedOption}?page=${page}`);
        setIpoData((prevData) => [...prevData, ...res.data]);
        if (res.data.length < 10) setIsLastPage(true);
        setLoaderShow(false);
        setErrorShow(false);
      } catch (err) {
        notificationTopup(err.message);
        setErrorShow(true);
        setLoaderShow(false);
      }
    },
    [selectedOption]
  );

  useEffect(() => {
    if (isLastPage) return;
    fetchData(currentPage);
  }, [fetchData, currentPage]);

  const handleScroll = () => {
    if (colRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = colRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !loaderShow && !isLastPage && currentPage < 5) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const colElement = colRef.current;
    if (colElement) {
      colElement.addEventListener("scroll", handleScroll);
    }

    if (colRef.current && colRef.current.scrollHeight <= colRef.current.clientHeight) {
      handleScroll();
    }

    return () => {
      if (colElement) {
        colElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loaderShow, isLastPage]);

  return (
    <CommonCardView
      id="commonCard_ipoDetails"
      header="IPO Details"
      headerAlign="left"
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: "Track upcoming, ongoing, and recently listed Initial Public Offerings (IPOs). Stay informed about issue prices, subscription status, and listing dates.",
        id: "ipo-data",
      }}
      responsiveHeight={true}
    >
      <div>
        <div className="row">
          <div className="col-md-2">
            <div className={`row ${isMobileView ? "section-bb px-2" : "section-bl ms-1"}`}>
              {options.map((option, index) => (
                <div
                  className={`col-md-12 col-4 m-0 p-1 vert-center ${
                    option === selectedOption ? "tr-hover-focus-selectionDefaultOpac" : "tr-hover-focus-selection"
                  }`}
                  key={index}
                  onClick={() => {
                    if (option === selectedOption) return;
                    setCurrentPage(1);
                    setErrorShow(false);
                    setLoaderShow(true);
                    setSelectedOption(option);
                    setIpoData([]);
                    setIsLastPage(false);
                  }}
                  style={{
                    cursor: "pointer",
                    ...(isMobileView
                      ? { borderTopLeftRadius: "25px", textAlign: "center" }
                      : { borderBottomRightRadius: "25px", textAlign: "left" }),
                    borderTopRightRadius: "25px",
                  }}
                >
                  {camelCaseToSentence(option)}
                </div>
              ))}
            </div>
          </div>
          <div
            className={`col-md-10 mt-0`}
            style={{ height: isMobileView ? "auto" : "700px", overflowY: "scroll" }}
            ref={colRef}
          >
            {errorShow ? (
              <VisOops />
            ) : loaderShow && currentPage === 1 ? (
              <QFLoader />
            ) : (
              <div className={`row m-0 p-0`}>
                {ipoData.map((ipo, index) => (
                  <div className="col-xl-3 col-lg-4 m-0 p-1" key={index}>
                    <div
                      className={`general-card-1 our-border hover-effect m-0 p-2 h-100`}
                      style={{
                        // maxHeight: selectedOption === "ongoing"?"200px":"250px",
                      }}
                    >
                      <div className="row">
                        <div className="col-3">
                          {ipo.SecurityLogoUrl && <img
                            src={ipo.SecurityLogoUrl}
                            alt="logo"
                            className="img-fluid border"
                            style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                          />}
                        </div>
                        <div className="col-9 h-min-full vert-center text-end">
                          <h3 className="qf-normal-text pb-1">{ipo.SecurityName}</h3>
                        </div>
                      </div>
                      <p>
                        <table className="w-100 qf-ipo-table qf-normal-text">
                          <tbody>
                            <tr>
                              <td>Issue Start</td>
                              <td>{ipo.OpenDate.split("T")[0]}</td>
                            </tr>
                            <tr>
                              <td>Issue Close</td>
                              <td>{ipo.CloseDate.split("T")[0]}</td>
                            </tr>
                            {selectedOption === "ongoing" && (
                              <>
                                <tr>
                                  <td>Issue Size</td>
                                  <td>
                                    {groupINRCurrencyNSE(ipo.IssueSizeMin, true)}-
                                    {groupINRCurrencyNSE(ipo.IssueSizeMax, true)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Issue Price</td>
                                  <td>
                                    ₹{ipo.IssuePriceMin}-₹{ipo.IssuePriceMax}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Application Size</td>
                                  <td>{ipo.MinApplication}</td>
                                </tr>
                                {ipo.GrandTotal && ipo.GrandTotal !== null && ipo.GrandTotal !== "" && (
                                  <tr>
                                    <td>Subscibed Times</td>
                                    <td>{ipo.GrandTotal?.toFixed(2)}x</td>
                                  </tr>
                                )}
                              </>
                            )}
                            {selectedOption === "listed" && (
                              <>
                                <tr>
                                  <td>Listing Date</td>
                                  <td>{ipo.ListingDate.split("T")[0]}</td>
                                </tr>
                                <tr>
                                  <td>Issue Price</td>
                                  <td>
                                    ₹{ipo.IssuePriceMin}-₹{ipo.IssuePriceMax}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Application Size</td>
                                  <td>{ipo.MinApplication}</td>
                                </tr>
                                <tr>
                                  <td>List Price</td>
                                  <td>
                                    ₹{ipo.ListPrice}{" "}
                                    <span
                                      style={{
                                        color:
                                          (ipo.IssuePriceMax || ipo.IssuePriceMin) > ipo.ListPrice
                                            ? chartColor.red
                                            : chartColor.green,
                                      }}
                                    >
                                      ({((ipo.ListPrice * 100) / (ipo.IssuePriceMax || ipo.IssuePriceMin) - 100)?.toFixed(2)}
                                      %)
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>LTP (Gain from listing)</td>
                                  <td>
                                    ₹{ipo.LTP}{" "}
                                    <span
                                      style={{
                                        color: ipo.ListPrice > ipo.LTP ? chartColor.red : chartColor.green,
                                      }}
                                    >
                                      ({((ipo.LTP * 100) / ipo.ListPrice - 100)?.toFixed(2)}%)
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Issue gain overall</td>
                                  <td>
                                    <span
                                      style={{
                                        color:
                                          (ipo.IssuePriceMax || ipo.IssuePriceMin) > ipo.LTP
                                            ? chartColor.red
                                            : chartColor.green,
                                      }}
                                    >
                                      ({((ipo.LTP * 100) / (ipo.IssuePriceMax || ipo.IssuePriceMin) - 100)?.toFixed(2)}%)
                                    </span>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </p>
                    </div>
                  </div>
                ))}
                {loaderShow && currentPage > 1 && <div>Loading...</div>}
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonCardView>
  );
};

export default IpoData;
