import { Bounce, toast } from "react-toastify";
let options = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: localStorage.getItem("theme") || "dark",
  transition: Bounce,
};

export const notificationTopup = (message, color = "red", show = false) => {
  var finalShow = show;
  if (!show) {
    if (
      location.href.includes("localhost") ||
      location.href.includes(":3000") ||
      location.href.includes("127.0.0.1") ||
      location.href.includes("alpha.quibblefrost")
    )
      finalShow = true;
    else finalShow = false;
  }
  if (finalShow) {
    if (color === "red") {
      toast.error(message, options);
    } else if (color === "green") {
      toast.success(message, options);
    } else if (color === "info") {
      toast.info(message, options);
    } else {
      toast(message, options);
    }
  }
};
