import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import QFLoader from "../QFLoader";
import { commonApiPost } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";

const VisAddWatchlist = ({ closer, handleUpdater, invoker = "toolbar" }) => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [loaderShow, setLoaderShow] = useState(false);
  const checker = invoker !== "toolbar";

  const handleAdd = async () => {
    await commonApiPost(`/user/watchlist/add?name=${name}&desc=${desc}`)
      .then((res) => {
        handleUpdater();
        closer(false);
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };
  return (
    <div
      className={` pt-1 pb-0 w-100 m-0 px-0 mx-0 ${
        checker ? "wlPage-addToWl" : ""
      }`}
      style={{
        boxShadow: "unset",
        maxWidth: "unset",
        position: "relative",
      }}
    >
      <i
        className="fas fa-close"
        style={{
          position: "absolute",
          ...(checker
            ? {
                top: "-20px",
                right: "0px",
              }
            : {
                top: "-10px",
                right: "10px",
              }),
          cursor: "pointer",
        }}
        onClick={() => closer(false)}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoaderShow(true);
          handleAdd();
        }}
        className="vert-center"
      >
        <Row>
          <Col lg={checker ? 12 : 4}>
            <input
              className="qfInputType p-1 mb-2"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name your watchlist"
              required
            />
          </Col>
          <Col lg={checker ? 12 : 5}>
            <input
              className="qfInputType p-1 mb-2"
              type="text"
              name="desc"
              placeholder="Write something about it..?"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              required
            />
          </Col>
          <Col lg={checker ? 12 : 3} className="vert-center">
            {loaderShow ? (
              <div className="py-2">
                <QFLoader />
              </div>
            ) : (
              <button type="submit" className="general-btn w-100 mb-2">
                Add
              </button>
            )}
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default VisAddWatchlist;
