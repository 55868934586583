import { postApiReqHandlation } from "./api";

class ApiError extends Error {
  constructor({ message, status, response }) {
    super(message); // Call the parent class constructor with the message
    this.name = "ApiError"; // Set the name of the error
    this.status = status || 700; // Assign custom status
    this.response = response || {}; // Assign custom response details
    this.timestamp = new Date(); // Optional: timestamp when the error occurred
  }
}

class ApiWorkerWrapper {
  constructor(workerPath) {
    this.worker = new Worker(workerPath);
    this.requests = new Map();

    this.worker.onmessage = (event) => {
      const { id, response, error, toCache, erroring } = event.data;
      const { resolve, reject } = this.requests.get(id) || {};

      if (resolve) {
        if (error) {
          let apiError = new ApiError(erroring);
          postApiReqHandlation(apiError, { erroring }, true, false);
          reject(apiError);
        } else {
          resolve(response);
        }
        this.requests.delete(id);
      }
    };

    this.worker.onerror = (error) => {
      console.error("Worker Error:", error.message);
    };
  }

  async callApi({ type, url, data = null, config = {}, toCache = false, cookies = {}, retries }) {
    const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

    // Function to check if the cache has expired
    const isCacheExpired = (timestamp) => {
      return Date.now() - timestamp > CACHE_TTL;
    };

    // Function to get cached data, checking TTL
    const getCachedData = async (url) => {
      const cache = await caches.open("api-cache");
      const response = await cache.match(url);

      if (response) {
        const cachedData = await response.json();

        // Check if cache has expired
        if (isCacheExpired(cachedData.timestamp)) {
          return null; // Cache expired, return null
        }

        return cachedData.data; // Return cached data
      }

      return null; // No cache found
    };
    return new Promise((resolve, reject) => {
      const id = `${Date.now()}-${Math.random()}`;
      this.requests.set(id, { resolve, reject });

      if (toCache) {
        // Check cache before sending request
        getCachedData(url).then((cachedData) => {
          if (cachedData) {
            // If data is cached, resolve with the cached response
            resolve(cachedData);
            this.requests.delete(id); // Clean up request
          } else {
            // If data is not in cache, initiate the worker request
            this.worker.postMessage({
              id,
              type,
              url,
              data,
              config,
              toCache,
              cookies,
              retries,
            });
          }
        });
      } else {
        // If no cache is needed, just initiate the worker request
        this.worker.postMessage({
          id,
          type,
          url,
          data,
          config,
          toCache,
          cookies,
          retries,
        });
      }
    });
  }
}

export const apiWorkerInstance = new ApiWorkerWrapper("/web-workers/apiWorker.js?");
