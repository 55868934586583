export const ipo = {
  closedIpo: {
    allotment_date: "Allotment date",
    refund_date: "Refund date",
    dmat_credit_date: "Credit date",
    listing_date: "Listing date",
    issue_price: "Issue price",
    issue_size: "Issue size",
    lot_size: "Lot size",
    total_subs: "Subscriptions",
    qib: "QII Subs",
    nii: "NII Subs",
    retail: "Retail Subs",
    allotment_status_check_url: "Allotment URL",
  },
  listedIpo: {
    listing_date: "Listing date",
    issue_price: "Issue price",
    issue_size: "Issue size",
    total_subs: "Subscriptions",
    last_price: "Last price",
    dt_open: "First day opening price",
    dt_close: "First day closing price",
    listing_gain: "Listing gain per share",
    todays_gain: "Today's gain per share",
  },
  openIpoList: {
    open_date: "Open date",
    close_date: "Close date",
    allotment_date: "Allotment date",
    listing_date: "Listing date",
    lot_size: "Lot size",
    issue_price: "Issue price",
    issue_size: "Issue size",
    total_subs: "Subscriptions",
    nii: "NII Subs",
  },
  open_Upcoming: {
    ipo_status: "IPO Status",
    open_date: "Open date",
    close_date: "Close date",
    allotment_date: "Allotment date",
    refund_date: "Refund date",
    credit_to_demat_date: "Credit date",
    listing_date: "Listing date",
    lot_size: "Lot size",
    from_issue_price: "From issue price",
    to_issue_price: "To issue price",
    issue_size: "Issue size",
    total_subs: "Subscriptions",
    qib: "QII Subs",
    nii: "NII Subs",
    retail: "Retail Subs",
    short_desc: "Short desc",
  },
};

export const sharkholdings = {
  holdings: {
    stockName: "Wipro Ltd.",
    nseCode: "WIPRO",
    quantityHeld: "3,804,505,118",
    holdingPer: "72.74",
    changePrev: "Filing awaited for current qtr",
    holdingVal: "208,011.32",
  },
  financial: {
    stockName: "Dhampur Bio Organics Ltd.",
    marketCap: "1,074.82",
    priceBook: "1.06",
    ttPe: "31.68",
    revenue: "466",
    netProfit: "0",
    esp: "0.02",
    dividendYield: 1.54,
  },
  shareholding: {
    stockName: "K G Denim Ltd.",
    promoter: 58.74,
    promoterChange: 0,
    fii: 0,
    fiiChange: 0,
    dii: 0.01,
    diiChange: 0,
    public: 41.25,
    publicChange: 0,
  },
};

export const shortSharkholdings = {
  holdings: {
    stockName: "Wipro Ltd.",
    nseCode: "WIPRO",
    quantityHeld: "3,804,505,118",
    // holdingPer: "72.74",
  },
  financial: {
    stockName: "Dhampur Bio Organics Ltd.",
    marketCap: "1,074.82",
    // priceBook: "1.06",
  },
  shareholding: {
    stockName: "K G Denim Ltd.",
    promoter: 58.74,
    // promoterChange: 0,
  },
};

export const sharkFolioSecondPage = {
  full: {
    quarter: "June-2024",
    clientName: "Tariq Azim Premji",
    totalShareHeld: "",
    holdingPer: "",
    pledgeShares: "",
    pledgePer: "",
    lockedShares: "",
    lockedPer: "",
  },
  minified: {
    quarter: "June-2024",
    clientName: "Tariq Azim Premji",
    totalShareHeld: "",
  },
};
