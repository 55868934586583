import React, { useState } from "react";
import { commonApiPost } from "../../../utils/api";
import { Col, Row } from "reactstrap";
import { notificationTopup } from "../../../utils/NotificationTopup";
import {
  hawkReport,
  hawkReportTypeIdentifiers,
} from "../../../utils/hawkeye/hawkHelper";
import { pagesAndComponent } from "../../../utils/mappings/generalMappings";

export default function RAB({ closeFunc }) {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [selectedPage, setSelectedPage] = useState("");
  const [selectedComponent, setSelectedComponent] = useState("");

  const handleRAB = async () => {
    const pluid = await hawkReport(
      new Error("HAWK REPORTING - RAB"),
      {},
      hawkReportTypeIdentifiers.reportBug
    );
    await commonApiPost("/utils/report-a-bug", {
      email,
      name,
      mobile_number: mobileNumber,
      message,
      selected_page: selectedPage,
      selected_component: selectedComponent,
      pluid,
    })
      .then((res) => {
        closeFunc();
      })
      .catch((err) => {
        notificationTopup(err.message);
        closeFunc();
      });
  };

  return (
    <div
      className="centered-inside"
      style={{
        minHeight: "100%",
      }}
    >
      <div
        className="signup-container my-2"
        style={{
          minHeight: "100%",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleRAB();
          }}
        >
          <Row>
            <Col sm={12}>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name*"
                required
              />
            </Col>
            <Col sm={12}>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email*"
                required
              />
            </Col>
            <Col sm={12}>
              <input
                type="tel"
                name="mobileNumber"
                placeholder="Mobile Number* 9999999999"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                required
                pattern="[6-9]{1}[0-9]{9}"
              />
            </Col>
            <Col sm={12}>
              <textarea
                type="textarea"
                name="message"
                placeholder="Message goes here...*"
                onChange={(e) => setMessage(e.target.value)}
                required
              >
                {message}
              </textarea>
            </Col>
            <Col sm={12}>
              {/* Select Page Dropdown */}
              <select
                value={selectedPage}
                onChange={(e) => {
                  setSelectedPage(e.target.value);
                  setSelectedComponent(""); // Reset component when page changes
                }}
                required
              >
                <option value="">Select Page*</option>
                {Object.keys(pagesAndComponent).map((page) => (
                  <option key={page} value={page}>
                    {page}
                  </option>
                ))}
              </select>
            </Col>
            {selectedPage && (
              <Col sm={12}>
                {/* Select Component Dropdown */}
                <select
                  value={selectedComponent}
                  onChange={(e) => setSelectedComponent(e.target.value)}
                  required
                >
                  <option value="">Select Component*</option>
                  {pagesAndComponent[selectedPage].components.map(
                    (component, index) => (
                      <option key={index} value={component}>
                        {component}
                      </option>
                    )
                  )}
                </select>
              </Col>
            )}
          </Row>

          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}
