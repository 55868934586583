const commonIndices = {
  NIFTY: "99926000",
  BANKNIFTY: "99926009",
  FINNIFTY: "99926037",
  MIDCPNIFTY: "99926074",
  NIFTYNXT50: "99926013",
};

export const indexTokenMappingsDerivatives = {
  ...commonIndices,
};

export const componentsOnSite = [
  {
    name: "Recommendations",
    path: "/",
    id: "commonCard_researchAnalysis",
  },
  {
    name: "Chart Patterns",
    path: "/dashboard",
    id: "commonCard_ChartPatterns",
  },
  {
    name: "Top Stories | Blog",
    path: "/dashboard",
    id: "commonCard_TopStories",
  },
  {
    name: "NSE Corporate News",
    path: "/dashboard",
    id: "commonCard_newsCorp",
  },
  {
    name: "Fundamental Scanners",
    path: "/market-watch",
    id: "commonCard_fundamentalScanner",
  },
  {
    name: "SharkFolio",
    path: "/market-watch",
    id: "commonCard_sharkFolio",
  },
  {
    name: "Premarket Movers",
    path: "/pre-market",
    id: "commonCard_Premarket",
  },
  {
    name: "Band Changes",
    path: "/pre-market",
    id: "commonCard_bandChanges",
  },
  {
    name: "Bulk/Block/Short Deals",
    path: "/pre-market",
    id: "commonCard_deals",
  },
  {
    name: "Intraday Stocks",
    path: "/pre-market",
    id: "commonCard_intradayStocks",
  },
  {
    name: "Short Selling",
    path: "/pre-market",
    id: "commonCard_shortSelling",
  },
  {
    name: "Day End Bhavcopy",
    path: "/equity-overview",
    id: "commonCard_BhavCopy",
  },
  {
    name: "Trending Stocks",
    path: "/equity-overview",
    id: "commonCard_trending",
  },
  {
    name: "Gainer/Loser",
    path: "/equity-overview",
    id: "commonCard_advancerDecliner",
  },
  {
    name: "Band Hitters (Upper/Lower Circuit)",
    path: "/equity-overview",
    id: "commonCard_bandHitter",
  },
  {
    name: "Price Gainer Loser",
    path: "/derivatives-overview",
    id: "commonCard_PRICEChange",
  },
  {
    name: "OI Gainer Loser",
    path: "/derivatives-overview",
    id: "commonCard_OIChange",
  },
  {
    name: "OI Buildup",
    path: "/derivatives-overview",
    id: "commonCard_buildup",
  },
  {
    name: "Ongoing IPOs",
    path: "/ipo/ongoing",
    id: "commonCard_ipoDetails",
  },
  {
    name: "Upcoming IPOs",
    path: "/ipo/upcoming",
    id: "commonCard_ipoDetails",
  },
  {
    name: "Listed IPOs",
    path: "/ipo/listed",
    id: "commonCard_ipoDetails",
  },
  {
    name: "Trading Holidays",
    path: "/market-holiday",
    id: "commonCard_holidayT",
  },
  {
    name: "Clearing Holidays",
    path: "/market-holiday",
    id: "commonCard_holidayC",
  },
  {
    name: "FII/DII Day Wise",
    path: "/fii-dii/day-wise",
    id: "commonCard_fiiDiiDetails",
  },
  {
    name: "FII/DII CM Provisional",
    path: "/fii-dii/cm-provisional",
    id: "commonCard_fiiDiiDetails",
  },
  {
    name: "FII/DII CM Sebi",
    path: "/fii-dii/cm-sebi",
    id: "commonCard_fiiDiiDetails",
  },
  {
    name: "FII/DII FPI Sectoral",
    path: "/fii-dii/fpi-sectoral",
    id: "commonCard_fiiDiiDetails",
  },
  {
    name: "FII/DII Derivatives",
    path: "/fii-dii/derivatives",
    id: "commonCard_fiiDiiDetails",
  },
  //
];

export const pagesAndComponent = {
  Header: {
    components: ["Site-Logo", "Site-Name", "Search-Icon", "Navigation-Icon"],
  },

  Homepage: {
    components: [
      "Tagline",
      "Call-to-Action",
      "Background Image/Gif/Color",
      "Fundamental Scanners",
      "Band Changes",
      "Recommendations",
      "SharkFolio",
      "Our Vision",
      "Our Mission",
      "Our Values",
    ],
  },

  Navigation: {
    components: [
      "Dashboard",
      "Market Watch",
      "Premarket",
      "Equity",
      "Watchlists",
      "Derivatives",
      "Derivatives> Movement",
      "Derivatives> Options",
      "Others",
      "Others> IPO Details",
      "Others> Market Hol.",
      "Others> FII/DII",
      "Connect Broker",
      "Dark/Night Mode",
      "Floating Icon",
    ],
  },

  Services_Overview: {
    components: ["Tick-by-Tick", "Derivatives", "Watchlist", "Paper Trade", "Strategy Builder", "Premarket"],
  },

  Other: {
    components: ["Sign-in", "Sign-up"],
  },

  Footer: {
    components: ["Connect", "Contact", "T&C", "Privacy Policy"],
  },

  Dashboard_Page: {
    components: ["Chart", "Technical Analysis", "PreMarket", "Screener", "Forex", "Top Stories", "Event Calender"],
  },

  Market_Watch_Page: {
    components: ["Fundamental Scanners", "SharkFolio"],
  },

  Premarket_Page: {
    components: ["Premarket", "Band Changes", "Bulk Deals", "Intraday Stocks", "Short Selling"],
  },

  Equity_Page: {
    components: ["Bhavcopy", "Trending", "Gainer/Loser", "Band Hitters"],
  },

  Watchlists_Page: {
    components: ["MyWatchlist", "Watchlist Overview", "Diversification"],
  },

  Movement_Page: {
    components: ["Price Change", "OI Change", "OI Buildup"],
  },

  Options_Page: {
    components: ["Option Chain", "Strategy Builder", "Analysis"],
  },

  IPO_Details_Page: {
    components: ["Ongoing", "Upcoming", "Listed"],
  },

  Market_Holidays_Page: {
    components: ["CM-Trading Holidays", "CM-Clearing Holidays"],
  },

  FII_DII_Page: {
    components: ["FII/DII Activity"],
  },
  Connect_Broker: {
    components: ["AngleOne"],
  },
};
