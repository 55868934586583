import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { getRandomStockMessage } from "../../utils/configs";
import QFLoader from "../vision/QFLoader";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import LinkToComp from "../minis/LinkToComp";

const IntradayStocks = () => {
  const [intradayStocksData, setIntradayStocksData] = useState([]);
  const [search, setSearch] = useState("");
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet("/market/intradayStocks?exchange=NSE")
        .then((res) => {
          if (res.data.success || res.data.status) {
            setIntradayStocksData(res.data.data);
          }
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  return (
    <CommonCardView
      id="commonCard_intradayStocks"
      header="Intraday Stocks"
      headerInfo={{
        id: "intraday-stocks",
        show: true,
        desc: "Below Stocks are available for intraday trading.",
      }}
      toWatermark={false}
    >
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className="make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          <table className="w-100 table-tr-bb">
            <thead className="make-me-sticky non-trans-bg" style={{ top: 40 }}>
              <tr>
                <th>Symbol</th>
                <th>Multiplier</th>
              </tr>
            </thead>
            <tbody>
              {intradayStocksData
                .filter((val) => val["SymbolName"].toLowerCase().includes(search.toLowerCase()))
                .map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="py-1">
                        <LinkToComp symbol={data["SymbolName"]} />
                      </td>
                      <td className="py-1">{data["Multiplier"]}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {intradayStocksData.filter((val) => val["SymbolName"].toLowerCase().includes(search.toLowerCase())).length === 0 &&
            getRandomStockMessage()}
        </>
      )}
    </CommonCardView>
  );
};

export default IntradayStocks;
