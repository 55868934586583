// Function to generate base times for a given unit in Indian Standard Time (IST)
function generateBaseTimesIST(unit) {
  // Market hours in IST (fixed for IST directly)
  const marketStart = new Date();
  marketStart.setHours(9, 15, 0, 0); // Set market start time to 9:15 AM IST

  const marketEnd = new Date();
  marketEnd.setHours(15, 30, 0, 0); // Set market end time to 3:30 PM IST

  const intervalMinutes = getUnitMinutes(unit); // Get the interval in minutes for the given unit
  const baseTimes = []; // Array to hold the generated base times

  // Validate unit
  if (!intervalMinutes) throw new Error("Invalid unit provided"); // Throw error if the unit is invalid

  let currentTime = new Date(marketStart); // Initialize current time to market start time

  // Generate all base times in IST
  while (currentTime <= marketEnd) {
    // Loop until current time exceeds market end time
    baseTimes.push(new Date(currentTime)); // Add the current time to the base times array
    currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes); // Increment current time by the interval
  }

  return baseTimes; // Return the array of base times
}

// Function to get the number of minutes corresponding to a given unit
function getUnitMinutes(unit) {
  switch (unit) {
    case "1D":
      return (15 - 9) * 60 + 15; // Full market day in minutes (6 hours 15 minutes)
    case "1H":
      return 60; // 1 Hour
    case "30M":
      return 30; // 30 Minutes
    case "15M":
      return 15; // 15 Minutes
    case "10M":
      return 10; // 10 Minutes
    case "5M":
      return 5; // 5 Minutes
    case "3M":
      return 3; // 3 Minutes
    case "1M":
      return 1; // 1 Minute
    default:
      return null; // Return null for invalid units
  }
}

// Function to get the base time for a specific unit based on the last updated time
export function getBaseTimeForUnit(unit, lastUpdatedTime) {
  const inputTime = new Date(lastUpdatedTime); // Convert last updated time to Date object
  const inputTimeInIST = inputTime.getTime(); // Get the time in milliseconds

  if (unit === "1D") {
    // If the unit is 1D (1 Day)
    const baseTime = new Date(inputTimeInIST); // Create a new Date object from input time
    baseTime.setHours(0, 0, 0, 0); // Set the time to the start of the day (midnight)
    return Math.floor(baseTime.getTime() / 1000); // Return the base time in seconds
  }

  const baseTimes = generateBaseTimesIST(unit); // Generate base times for the specified unit

  // Loop through the base times to find the appropriate base time
  for (let i = 0; i < baseTimes.length; i++) {
    const baseTimeInIST = baseTimes[i].getTime(); // Get the time in milliseconds for the current base time
    if (inputTimeInIST <= baseTimeInIST) {
      // If input time is less than or equal to the base time
      return Math.floor(baseTimes[Math.max(0, i - 1)].getTime() / 1000); // Return the previous base time in seconds
    }
  }

  return Math.floor(baseTimes[baseTimes.length - 1].getTime() / 1000); // Return the last base time in seconds if no earlier base time is found
}

export function volumeForCurrentUnitCandle(unit, ohlcCandles, newVolumeForTheDay) {
  if (unit === "1D") return newVolumeForTheDay;

  let i = ohlcCandles.length - 2;

  console.warn(ohlcCandles[i]);
  let cumVolume = 0;
  const today = new Date();

  while (true) {
    // Break if we run out of candles
    if (i < 0) break;
    // ohlc[i][0] is in seconds epoch 1734680100 : 0th is time
    let dateForCandle = new Date(ohlcCandles[i][0] * 1000);

    // Check if the date is not today
    if (dateForCandle.getDate() !== today.getDate()) break; // Exit the loop if the date is not today

    // 1'th is volume
    cumVolume += ohlcCandles[i][1];

    i--;
  }

  console.log(
    newVolumeForTheDay,
    cumVolume,
    newVolumeForTheDay - cumVolume,
    `from-to ${ohlcCandles.length - 2} : ${i}, from ${ohlcCandles.length}`,
    ohlcCandles[i],
    ohlcCandles[ohlcCandles.length - 2]
  );
  return Math.abs(newVolumeForTheDay - cumVolume);
}
