import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { chartColor, isMobile, themedTextColor } from "../../../utils/configs";
import { useSelector } from "react-redux";
import { groupINRCurrencyNSE } from "../../../utils/functions";

const BarChartWithNegativeValues = ({ data }) => {
  const theme = useSelector((state) => state.theme);
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);
    const option = {
      title: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        className: "customEchartohlcTooltip glassmorfy-it general-card-1",
        position: function () {
          const obj = { top: 10, left: 10 };
          return obj;
        },
        borderWidth: 1,
        borderColor: "#ccc",
        padding: 10,
        textStyle: {
          color: themedTextColor[theme],
          fontSize: "10",
        },
      },
      grid: {
        left: "0%",
        right: "4%",
        bottom: "20%",
        top: "10%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        position: "top",
        splitLine: {
            show: false,
          lineStyle: {
            type: "solid",
          },
        },
        axisLabel: {
          ...(isMobile() ? { show: false } : {}),
          formatter: function (value) {
            return `${groupINRCurrencyNSE(value)}`;
          },
        },
      },
      yAxis: {
        type: "category",
        axisLine: { show: false },
        // axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        data: data.keys,
      },
      series: [
        {
          name: "Cost",
          type: "bar",
          stack: "Total",
          label: {
            show: false,
          },
          itemStyle: {
            color: function (params) {
              return params.value >= 0 ? chartColor.green : chartColor.red;
            },
          },
          barWidth: 10,
          data: data.values,
        },
      ],
    };

    myChart.setOption(option);

    // Cleanup on component unmount
    return () => {
      myChart.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: "98%", height: "200px", marginLeft: "20px" }} />;
};

export default BarChartWithNegativeValues;
