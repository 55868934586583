import React, { useEffect, useRef, useState } from "react";
import Ctas from "./Ctas";
import { isMobile } from "../../../utils/configs";
import { commonApiGet } from "../../../utils/api";
import QFLoader from "../../../components/vision/QFLoader";
import Divider from "./Divider";
import { componentsOnSite } from "../../../utils/mappings/generalMappings";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const HomeSearch = ({ nav = false }) => {
  const n = useNavigate();
  const d = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const buttonRef = useRef(null);
  const containerRef = useRef(null);
  // throw your mousemove callback up here to "add" and "remove" later
  // might be worth a useCallback based on the containerRef as well!
  function mouseMoveEvent(e) {
    const { x, y } = containerRef.current.getBoundingClientRect();
    containerRef.current.style.setProperty("--x", e.clientX - x);
    containerRef.current.style.setProperty("--y", e.clientY - y);
  }

  useEffect(() => {
    if (!nav) {
      if (buttonRef) {
        buttonRef.current.addEventListener("mousemove", mouseMoveEvent);
      }
      // don't forget to *remove* the eventListener
      // when your component unmounts!
      return () => buttonRef?.current?.removeEventListener("mousemove", mouseMoveEvent);
    }
  }, [buttonRef]);

  const [searchResults, setSearchResults] = useState([]); // Store search results
  const [isSearching, setIsSearching] = useState(false); // Flag to indicate search in progress

  const onSiteFilter = componentsOnSite.filter(
    (val) =>
      val.name.toLowerCase().includes(searchTerm.toLowerCase()) || searchTerm.toLowerCase().includes(val.name.toLowerCase())
  );

  const throttledSearch = async (searchTerm) => {
    if (searchTerm.length < 3) return;
    setTimeout(async () => {
      if (searchTerm === document.getElementById("hp_searchbar" + (nav ? "nav" : "")).value) {
        try {
          const res = await commonApiGet(
            `/stocks/scripsearch?search=${searchTerm}&type=2`,
            "HP_EQUITY_SEARCH",
            false,
            true,
            0
          );

          let data = res.data.data;
          setSearchResults(data);
        } catch (error) {
          setSearchResults([]);
        } finally {
          setIsSearching(false);
        }
      }
    }, 500);
  };

  const navigateHandle = (result) => {
    let prefix = result.instrumenttype === "AMXIDX" ? "index" : "info";
    let symbol = result.instrumenttype === "AMXIDX" ? result.symbol : result.name;
    let exchange = result.exch_seg;
    n(`/${prefix}/${exchange}/${symbol}`);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    throttledSearch(searchTerm);
    if (searchTerm.length < 3) setSearchResults([]);
    else setIsSearching(true);
  };

  return (
    <div className="shiny" ref={nav ? undefined : containerRef}>
      <div className={`vert-center ${!nav && "min-vh-80"}`} ref={nav ? undefined : buttonRef}>
        <center>
          <div className={!nav && "home-search-section"}>
            <div className="scroll-speed-5 general-card-1 our-border w-100" style={{ position: "relative" }}>
              <input
                id={"hp_searchbar" + (nav ? "nav" : "")}
                type="text"
                className={`w-100 ${nav?"py-1":"py-2"} px-3 qfrawsearch`}
                placeholder="Find (stock/index/anything)"
                style={{ paddingRight: "40px", fontSize: "18px" }} // Add padding to prevent text overlap with the icon
                value={searchTerm}
                onChange={(e) => handleSearch(e)}
              />
              <i
                className={searchTerm.length > 0 ? "fas fa-xmark" : "fas fa-magnifying-glass"}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => {
                  setSearchTerm("");
                }}
              ></i>
            </div>
            {searchTerm.length > 0 && (
              <div
                style={{
                  minHeight: "200px",
                  position: "absolute",
                  maxHeight: "400px",
                  overflowY: "scroll",
                }}
                className={`scrbar-vr-available ${!nav && "home-search-section"} general-card-1 our-border w-100 mt-2`}
              >
                {searchTerm.length < 3 ? (
                  <div
                    style={{
                      minHeight: "200px",
                    }}
                    className="vert-center"
                  >
                    Atleast 3 Characters...
                  </div>
                ) : (
                  <>
                    {!isSearching ? (
                      <div className="px-3 py-2">
                        {searchResults.length === 0 ? (
                          "No Stock/Indices Found..."
                        ) : (
                          <table className="text-start w-100">
                            <thead>
                              <th>Stock</th>
                            </thead>
                            <tbody>
                              {searchResults.map((result, index) => (
                                <tr key={index} className="tr-hover-focus-selection c-pointer">
                                  <td
                                    onClick={() => {
                                      navigateHandle(result);
                                    }}
                                  >
                                    {result.symbol} <span className="div-pill our-border">{result.exch_seg}</span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          minHeight: "100px",
                        }}
                        className="vert-center"
                      >
                        <QFLoader />
                      </div>
                    )}
                    <Divider py={0} />
                    {!isSearching && searchTerm.length >= 3 && (
                      <>
                        <div className="px-3 py-2">
                          <table className="text-start w-100">
                            <thead>
                              <th>Explore Site</th>
                            </thead>
                            <tbody>
                              {(onSiteFilter.length > 0 ? onSiteFilter : componentsOnSite).map((result, index) => (
                                <tr
                                  key={index}
                                  className="tr-hover-focus-selection c-pointer"
                                  onClick={() => {
                                    d({ type: "HIGHLIGHTED_COMPONENT", component: result.id });
                                    n(result.path);
                                    setTimeout(() => {
                                      d({ type: "HIGHLIGHTED_COMPONENT", component: null });
                                    }, 5000);
                                  }}
                                >
                                  <td>{result.name}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            {!nav && (
              <>
                <div className="scroll-speed-10 pt-5">
                  <h2
                    className="display-4 fw-bold mb-4"
                    style={{
                      fontSize: isMobile() ? "1.75rem" : "2.5rem",
                    }}
                  >
                    Rakho <i className="text-info">Market </i>ki har
                    <br /> Chal pe Nazar...
                  </h2>
                  <p className="lead w-md-60">
                    <i style={{ fontWeight: 900 }}>Unlock Smarter Investing</i> with{" "}
                    <i style={{ fontWeight: 900 }} className="text-info">
                      {" "}
                      Quibblefrost
                    </i>
                    , India’s most comprehensive platform for real-time stock market data and intelligence.
                  </p>
                </div>
                <div className="pt-5">
                  <Ctas lgSm="sm" />
                </div>
              </>
            )}
          </div>
        </center>
      </div>
    </div>
  );
};

export default HomeSearch;
