import React from "react";
import { Link } from "react-router-dom";
import "../static/css/notFound.css";
import VisPreviewChart from "./vision/charts/VisPreviewChart";
import { getRandomStockMessage, isLoggedin } from "../utils/configs";
import { urlsFE } from "../views/pageroutes";

export default function NotFound() {
  return (
    <div className="col-12">
      <div className="not-found-container w-100">
        <main className="row">
          <div className="error-section col-md-4">
            <h2 className="error-subtitle">Resource Not Found</h2>
            <h4 className="error-title h1">404</h4>
            <p className="error-message">{getRandomStockMessage()}</p>
            <Link to={isLoggedin() ? urlsFE.dashboard : "/"}>
              <button className="return-button mt-3">Dashboard</button>
            </Link>
            {/* <div className="action-buttons">
              <button className="action-button">
                <span>Sound</span>
              </button>
              <button className="action-button">
                <span>Eye</span>
              </button>
            </div> */}
          </div>

          <div className="chart-section col-md-8">
            {/* <div className="chart-placeholder"> */}
            <VisPreviewChart symbol={"NIFTY 50"} exchange={"NSE"} name={"NIFTY"} isEq={false} />
            {/* </div> */}
          </div>
        </main>
      </div>
    </div>
  );
}
