import React, { useEffect, useState } from "react";
import { notificationTopup } from "../../utils/NotificationTopup";
import { chartColor, getRandomStockMessage } from "../../utils/configs";
import QFLoader from "../vision/QFLoader";
import { commonApiGet } from "../../utils/api";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import VisDropDown from "../vision/VisDropDown";

const Trending = () => {
  const [search, setSearch] = useState("");
  const [tnds, settnds] = useState([]);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const tndsOptions = ["Bullish", "Bearish", "Turning-bullish", "Turning-bearish"];
  const [selectedtndsOption, setSelectedtndsOption] = useState(tndsOptions[0]);
  const tnds2Options = ["FNO", "LCAP", "MDCAP", "SMCAP"];
  const [selectedtnds2Option, setSelectedtnds2Option] = useState(tnds2Options[0]);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/analysis/stocksbytrend?trend=${selectedtndsOption}&collection=${selectedtnds2Option}`)
        .then((res) => {
          settnds(Array.isArray(res.data) ? res.data : []);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [selectedtndsOption, selectedtnds2Option]);
  return (
    <CommonCardView
      id="commonCard_trending"
      style={{
        position: "relative",
      }}
      header="Trending"
      headerAlign="left"
      headerInfo={{
        show: true,
        desc: "Track stocks by trends—Bullish, Bearish, Turning-Bullish, or Turning-Bearish—across categories like FNO, Large Cap, Mid Cap, and Small Cap.",
        id: "trending-eo",
      }}
    >
      <div className="absolute-multiple-tabGrp">
        <VisDropDown
          selected={selectedtndsOption}
          list={tndsOptions}
          setter={(val) => {
            setLoaderShow(true);
            settnds([]);
            setSelectedtndsOption(val);
          }}
          isMultiple={true}
        />

        <VisDropDown
          selected={selectedtnds2Option}
          list={tnds2Options}
          setter={(val) => {
            setLoaderShow(true);
            settnds([]);
            setSelectedtnds2Option(val);
          }}
          isMultiple={true}
          className="ms-2"
        />
      </div>
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          {tnds
            .filter((val) => val.data.StockName?.toLowerCase().includes(search.toLowerCase()))
            .map((val, key) => {
              return (
                <div
                  key={`selectedtndsOpt_${selectedtndsOption}_selectedtnds2Opt_${selectedtnds2Option}_${key}`}
                  className="m-0 mb-1 pb-1"
                  style={{
                    borderBottom: "1px solid var(--border-clr)",
                  }}
                >
                  <table className="table-2nd-td-r w-100 mt-2">
                    <tbody>
                      <tr>
                        <td>
                          {val.data.StockName} (₹{val.data.currPrice})
                        </td>
                        <td>{val.data.updatedTime}</td>
                      </tr>
                      <tr>
                        <td>Performance:</td>
                        <td
                          style={{
                            color: val.data.performance > 0 ? chartColor.green : chartColor.red,
                          }}
                        >
                          {val.data.performance}%
                        </td>
                      </tr>
                      <tr>
                        <td>Prev Trend:</td>
                        <td>{val.data.prevTrend}</td>
                      </tr>
                      <tr>
                        <td>Current Trend:</td>
                        <td>{val.data.currTrend}</td>
                      </tr>
                      <tr>
                        <td>Trend Change:</td>
                        <td>{val.data.trendChngDate}</td>
                      </tr>
                      <tr>
                        <td>Trend Change Price:</td>
                        <td>₹{val.data.trendChngPrice}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          {tnds.filter((val) => val.data.StockName?.toLowerCase().includes(search.toLowerCase())).length === 0 &&
            getRandomStockMessage()}
        </>
      )}
    </CommonCardView>
  );
};

export default Trending;
