import React from "react";

const VisOrderBook = ({ data = { buy: [], sell: [] } }) => {
  const maxSellQ = Math.max(...data.sell.map((val) => val.quantity));
  const maxBuyQ = Math.max(...data.buy.map((val) => val.quantity));
  const calculateWidth = (quantity, maxQuantity) => {
    let widthPercentage = (quantity / maxQuantity) * 100;
    widthPercentage = (widthPercentage * 89) / 100;
    return `${Math.min(widthPercentage, 100)}%`; // Limit to 100%
  };

  // Calculate total quantities
  const totalBuyQuantity = data.buy.reduce((total, item) => total + item.quantity, 0);
  const totalSellQuantity = data.sell.reduce((total, item) => total + item.quantity, 0);
  return (
    <div className="row qf-text-responsive p-0 m-0">
      <div className="col-6 ps-2">
        <div className="row w-100 order-book-buy section-bb">
          <div className="col-12 section-bb">
            <div className="row">
              <div className="col-4 p-0">Qty</div>
              <div className="col-4 p-0">Orders</div>
              <div className="col-4 p-0">Bid</div>
            </div>
          </div>
          <div className="col-12">
            {data.buy.map((item, index) => (
              <div
                key={index}
                style={{
                  "--width-size": calculateWidth(item.quantity, maxBuyQ),
                }}
                className="row"
              >
                <div className="col-4 p-0">{item.quantity}</div>
                <div className="col-4 p-0">{item.orders}</div>
                <div className="col-4 p-0">{item.price}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-6 ps-0 pe-2 justify-content-end d-flex">
        <div className="row w-100 order-book-sell section-bb">
          <div className="col-12 section-bb">
            <div className="row">
              <div className="col-4 p-0">Ask</div>
              <div className="col-4 p-0">Orders</div>
              <div className="col-4 p-0">Qty</div>
            </div>
          </div>
          <div className="col-12">
            {data.sell.map((item, index) => (
              <div
                key={index}
                style={{
                  "--width-size": calculateWidth(item.quantity, maxSellQ),
                }}
                className="row"
              >
                <div className="col-4 p-0">{item.price}</div>
                <div className="col-4 p-0">{item.orders}</div>
                <div className="col-4 p-0">{item.quantity}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-12 px-2">
        <div className="row ">
          <div className="col-4 p-0 text-start">{totalBuyQuantity}</div>
          <div className="col-4 p-0 text-center">Total Quantity</div>
          <div className="col-4 p-0 text-end">{totalSellQuantity}</div>
        </div>
      </div>
    </div>
  );
};

export default VisOrderBook;
