import React from "react";
import ReactECharts from "echarts-for-react";
import { chartColor } from "../../../utils/configs";
import { useSelector } from "react-redux";

const ChartPatternsLineArea = ({ data }) => {
  const theme = useSelector((state) => state.theme);

  // Transform the PathPoints data into [date, price] pairs
  const minFrom = Math.min(...data.PathPoints.map((point) => [point.Price]));
  const transformedData = data.PathPoints.map((point) => [point.Date, point.Price - minFrom]);

  // Define the chart options
  const getOption = () => ({
    tooltip: {
      show: false
    },
    xAxis: {
      type: "time", // Set x-axis type to time
      axisLine: { show: false }, // Hide axis line
      axisTick: { show: false }, // Hide ticks
      axisLabel: { show: false }, // Hide labels
    },
    yAxis: {
      type: "value",
      show: false, // Hide y-axis
    },
    grid: {
      left: "0%",
      right: "0%",
      top: "0%",
      bottom: "0%",
    },
    series: [
      {
        data: transformedData, // Use the transformed data
        type: "line",
        smooth: true,
        lineStyle: {
          width: 1,
          color:
            data.DirectionType === 1
              ? chartColor.green
              : data.DirectionType === -1
              ? chartColor.red
              : theme === "dark"
              ? chartColor.yellowDark
              : chartColor.yellow,
        },
        itemStyle: {
          color: "transparent", // No fill for points
        },
        symbol: "none", // Remove data point circles
        areaStyle: {
          color:
            data.PathPointsDrawType === 2
              ? "transparent"
              : data.DirectionType === 1
              ? chartColor.opacGreen
              : data.DirectionType === -1
              ? chartColor.opacRed
              : theme === "dark"
              ? chartColor.opacYellowDark
              : chartColor.opacYellow,
        },
      },
    ],
  });

  return (
    <div style={{ width: "100px", height: "70px", zIndex: 0, position: 'relative', padding: '10px' }}>
      <ReactECharts option={getOption()} style={{ height: "100%", width: "100%" }} />
    </div>
  );
};

export default ChartPatternsLineArea;
