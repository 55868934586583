import React, { useCallback, useEffect, useState } from "react";
import { commonApiGet } from "../../../utils/api";
import { groupINRCurrencyNSE } from "../../../utils/functions";
import { chartColor } from "../../../utils/configs";
import VisOops from "../../vision/VisOops";
import QFLoader from "../../vision/QFLoader";
import { notificationTopup } from "../../../utils/NotificationTopup";
import SmallLineChart from "../ChartComponents/SmallLineChart";
import { VisDropDownInlineTransparent } from "../../vision/VisDropDown";

const FiiDiiPageFPISectoral = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const types = ["Equity", "Debt", "DebtVRR", "Hybrid", "Total"];

  const [selectedOption, setSelectedOption] = useState(types[0]);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/analysis/fpisectoral/${selectedOption}`, "FII_DII_Derivatives")
        .then((res) => {
          setData(
            res.data
              .sort((a, b) => b.DateWiseValues[0].Value - a.DateWiseValues[0].Value)
              .map((item) => ({ ...item, DateWiseValues: item.DateWiseValues.reverse() }))
          );
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          notificationTopup(err.message);
        });
    })();
  }, [selectedOption]);

  return (
    <div className="w-100 h-100">
      {loading ? (
        <QFLoader />
      ) : error ? (
        <VisOops />
      ) : (
        <table className="w-100">
          <thead className="section-bb">
            <tr className="non-trans-bg">
              <td style={{ width: "150px" }}>Name</td>
              <td>
                <VisDropDownInlineTransparent
                  selected={selectedOption}
                  setter={(val) => {
                    setSelectedOption(val);
                    setLoading(true);
                  }}
                  list={types}
                />
              </td>
              {data[0].DateWiseValues.map((item, index) => (
                <td key={index} className={`${index === data[0].DateWiseValues.length - 1 ? "" : "d-none d-md-table-cell"}`}>
                  {item.DisplayDate}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "200px" }}>{item.FpiSectorName}</td>
                <td>
                  <SmallLineChart data={[...item.DateWiseValues.map((val) => val.Value)]} />
                </td>
                {item.DateWiseValues.map((initem, inindex) => (
                  <td
                    key={inindex}
                    className={`${inindex === item.DateWiseValues.length - 1 ? "" : "d-none d-md-table-cell"}`}
                    style={{
                      color: initem.Value > 0 ? chartColor.green : chartColor.red,
                    }}
                  >
                    {groupINRCurrencyNSE(initem.Value, true)}Cr.
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FiiDiiPageFPISectoral;
