import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { useSelector } from "react-redux";
import Divider from "../../../views/preLogin/components/Divider";
import { commonApiPost } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { chartColor, isLoggedin, themedTextColor } from "../../../utils/configs";
import VIsAdvancerDeclinerUnchanged from "../../vision/VIsAdvancerDeclinerUnchanged";
import WatchlistCopyFollow from "./WatchlistCopyFollow";
import LinkToComp from "../../minis/LinkToComp";
import VisDropDown from "../../vision/VisDropDown";

const WatchlistOverviewer = ({ watchlistToAnalyse, isSharedWL = false }) => {

  const [isSharable, setIsSharable] = useState(watchlistToAnalyse.sharable);
  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    setIsSharable(watchlistToAnalyse.sharable);
  }, [watchlistToAnalyse]);

  const options = {
    "Industry Diversification": {
      component: <div id="echarts-sector" style={{ width: "100%", height: "300px" }} />,
    },
    "Market Cap Diversification": {
      component: <div id="echarts-mcap" style={{ width: "100%", height: "300px" }} />,
    },
    Gainers: {
      component: (
        <>
          <table className="table-px w-100 mt-3 table-tr-bb">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>LTP</th>
                <th>Change</th>
              </tr>
            </thead>
            <tbody>
              {watchlistToAnalyse.items
                ?.filter((val) => val.data.percentChange > 0)
                .sort((a, b) => b.data.percentChange - a.data.percentChange)
                .map((val, index) => (
                  <tr key={index}>
                    <td>
                      <LinkToComp symbol={val.symbol} />
                    </td>
                    <td>{val.data.ltp}</td>
                    <td>
                      <span
                        style={{
                          textWrap: "nowrap",
                          color: val.data.percentChange >= 0 ? chartColor.green : chartColor.red,
                        }}
                      >
                        ₹{val.data.netChange} ({val.data.percentChange}%)
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ),
    },
    Losers: {
      component: (
        <>
          <table className="table-px w-100 mt-3 table-tr-bb">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>LTP</th>
                <th>Change</th>
              </tr>
            </thead>
            <tbody>
              {watchlistToAnalyse.items
                ?.filter((val) => val.data.percentChange < 0)
                .sort((a, b) => a.data.percentChange - b.data.percentChange)
                .map((val, index) => (
                  <tr key={index}>
                    <td>
                      <LinkToComp symbol={val.symbol} />
                    </td>
                    <td>{val.data.ltp}</td>
                    <td>
                      <span
                        style={{
                          textWrap: "nowrap",
                          color: val.data.percentChange >= 0 ? chartColor.green : chartColor.red,
                        }}
                      >
                        ₹{val.data.netChange} ({val.data.percentChange}%)
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ),
    },
    "Realised volatility Daily": {
      component: (
        <>
          <table className="table-px w-100 mt-3 table-tr-bb">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Min</th>
                <th>Max</th>
                <th>RV</th>
              </tr>
            </thead>
            <tbody>
              {watchlistToAnalyse.items
                ?.sort((a, b) => b.info.cmDailyVolatility - a.info.cmDailyVolatility)
                .map((val, index) => (
                  <tr key={index}>
                    <td>
                      <LinkToComp symbol={val.symbol} />
                    </td>
                    <td>{val.stock.intraDayHighLow.min}</td>
                    <td>{val.stock.intraDayHighLow.max}</td>
                    <td>{(val.info.cmDailyVolatility || 0).toFixed(2)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ),
    },
    "Realised volatility 52W": {
      component: (
        <>
          <table className="table-px w-100 mt-3 table-tr-bb">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Min</th>
                <th>Max</th>
                <th>RV</th>
              </tr>
            </thead>
            <tbody>
              {watchlistToAnalyse.items
                ?.sort((a, b) => b.info.cmAnnualVolatility - a.info.cmAnnualVolatility)
                .map((val, index) => (
                  <tr key={index}>
                    <td>
                      <LinkToComp symbol={val.symbol} />
                    </td>
                    <td>{val.stock.weekHighLow.min}</td>
                    <td>{val.stock.weekHighLow.max}</td>
                    <td>{(val.info.cmAnnualVolatility || 0).toFixed(2)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ),
    },
  };
  const [selectedOption, setSelectedOption] = useState("Industry Diversification");
  
  useEffect(() => {
    if (selectedOption === "Industry Diversification") {
      const chartDom = document.getElementById("echarts-sector");
      if (!chartDom) return;
      const myChart = echarts.init(chartDom);
      let sectors = watchlistToAnalyse.items?.map((val) => val.stock.industry);
      let sectorsCount = {};
      sectors.forEach((sector) => {
        if (sector in sectorsCount) {
          sectorsCount[sector]++;
        } else {
          sectorsCount[sector] = 1;
        }
      });
      const option = {
        animation: true,
        tooltip: {
          trigger: "item",
          className: "customEchartohlcTooltip glassmorfy-it general-card-1",
          position: function () {
            const obj = { top: 10, left: 10 };
            return obj;
          },
          borderWidth: 1,
          borderColor: "#ccc",
          padding: 10,
          textStyle: {
            color: "#000",
            fontSize: "10",
          },
        },
        series: [
          {
            name: "Industry",
            type: "pie",
            radius: "50%",
            data: Object.keys(sectorsCount).map((val) => {
              return {
                value: sectorsCount[val],
                name: val,
              };
            }),
            label: {
              color: themedTextColor[theme], // Color for the section names
            },
          },
        ],
      };

      myChart.setOption(option);

      return () => {
        myChart.dispose(); // Cleanup function to dispose of chart
      };
    } else if (selectedOption === "Market Cap Diversification") {
      const chartDom = document.getElementById("echarts-mcap");
      const myChart = echarts.init(chartDom);
      let marketCaps = watchlistToAnalyse.items?.map((val) => val.info.totalMarketCap);
      let marketCapsCounts = {
        Penny: marketCaps.filter((val) => val < 50).length,
        Micro: marketCaps.filter((val) => val >= 50 && val < 500).length,
        Small: marketCaps.filter((val) => val >= 500 && val < 5000).length,
        Mid: marketCaps.filter((val) => val >= 5000 && val < 20000).length,
        Large: marketCaps.filter((val) => val >= 20000).length,
      };
      const option = {
        animation: true,
        tooltip: {
          trigger: "item",
          className: "customEchartohlcTooltip glassmorfy-it general-card-1",
          position: function () {
            const obj = { top: 10, left: 10 };
            return obj;
          },
          borderWidth: 1,
          borderColor: "#ccc",
          padding: 10,
          textStyle: {
            color: "#000",
            fontSize: "10",
          },
        },
        series: [
          {
            name: "Capitalization",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "70%"],
            startAngle: 180,
            endAngle: 360,
            data: Object.keys(marketCapsCounts).map((val) => {
              return {
                value: marketCapsCounts[val],
                name: val,
              };
            }),
            label: {
              color: themedTextColor[theme], // Color for the section names
            },
          },
        ],
      };

      myChart.setOption(option);

      return () => {
        myChart.dispose(); // Cleanup function to dispose of chart
      };
    } else {
      return;
    }
  }, [watchlistToAnalyse, theme, selectedOption]);

  return watchlistToAnalyse?.id ? (
    <div>
      {/* <h5 className="text-center"></h5> */}
      <Divider role="text" py={2} text="Watchlist Overview" />
      <div className="rounded our-border py-2 px-3">
        <table className="table-tr-bb w-100">
          <tbody>
            <tr>
              <td>ID</td>
              <td>{watchlistToAnalyse.id}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{watchlistToAnalyse.name}</td>
            </tr>
            <tr>
              <td>Desc</td>
              <td>{watchlistToAnalyse.desc}</td>
            </tr>
            {isSharedWL ? (
              <>
                {isLoggedin() ? (
                  <>
                    <tr>
                      <td colSpan={2} className="text-center">
                        <WatchlistCopyFollow id={watchlistToAnalyse.id} />
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <tr>
                <td>Sharable?</td>
                <td className={`py-2 ${isSharable ? "turn-on" : ""}`}>
                  <button
                    className={`toggle-button-switch`}
                    onClick={async () => {
                      let prevStateForIsSharable = isSharable;
                      setIsSharable(!isSharable);
                      await commonApiPost(`/user/watchlist/share-toggle/${watchlistToAnalyse.id}`)
                        .then((res) => {
                          notificationTopup(
                            `Watchlist ${res.data.watchlist.sharable ? "Shared" : "is private now"}.`,
                            "green", true
                          );
                        })
                        .catch((err) => {
                          notificationTopup(`Error on ${prevStateForIsSharable ? "privating" : "sharing"} watchlist`, "red", true);
                          setIsSharable(prevStateForIsSharable);
                        });
                    }}
                  ></button>
                  {isSharable ? (
                    <i
                      className="fas fa-copy ms-2"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const linkToCopy = `${location.protocol}//${location.host}/watchlist/${watchlistToAnalyse.id}`;
                        navigator.clipboard
                          .writeText(linkToCopy)
                          .then(() => {
                            notificationTopup("Copied to clipboard", "green", true);
                          })
                          .catch((err) => {
                            notificationTopup("Error copying to clipboard", "red", true);
                          });
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            )}

            <tr>
              <td>Total stocks:</td>
              <td>{watchlistToAnalyse.items.length}</td>
            </tr>
            <tr>
              <td>1D avg change:</td>
              <td>
                {(
                  watchlistToAnalyse.items
                    .map((val) => val.data.percentChange)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / watchlistToAnalyse.items.length
                ).toFixed(2)}
                %
              </td>
            </tr>
            <tr>
              <td>Under Surveillance:</td>
              <td>{watchlistToAnalyse.items.filter((val) => val.stock.surveillance.surv).length}</td>
            </tr>
            <tr>
              <td>Intraday Stocks:</td>
              <td>{watchlistToAnalyse.items.filter((val) => val.data.tradingSymbol.endsWith("-EQ")).length}</td>
            </tr>
            <tr>
              <td>Derivative Stocks:</td>
              <td>{watchlistToAnalyse.items.filter((val) => val.stock.derivatives === "Yes").length}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <VIsAdvancerDeclinerUnchanged
          gainerLoser={{
            advance: {
              advances: watchlistToAnalyse.items.filter((val) => val.data.percentChange > 0).length,
              declines: watchlistToAnalyse.items.filter((val) => val.data.percentChange < 0).length,
              unchanged: watchlistToAnalyse.items.filter((val) => val.data.percentChange === 0).length,
            },
          }}
        />
      </div>
      <div className="pt-3">
        <Divider
          role="text"
          py={0}
          text={
            <VisDropDown
              selected={selectedOption}
              list={Object.keys(options)}
              setter={(val) => {
                setSelectedOption(val);
              }}
              isMultiple={true}
            />
          }
        />
      </div>
      <center>
        <div
          className="our-border rounded mt-3 mb-2"
          style={{
            maxHeight: "300px",
            overflowY: "scroll",
          }}
        >
          {options[selectedOption].component}
        </div>
      </center>
    </div>
  ) : (
    <></>
  );
};

export default WatchlistOverviewer;
