import React, { useEffect } from "react";
import { doLogin, getUserDetails } from "./angel-one/angelOneHelper";
import { notificationTopup } from "../../utils/NotificationTopup";

const HandleConnect = () => {
  useEffect(() => {
    (async () => {
      await getUserDetails()
        .then((res) => {
          if (res.status) {
            localStorage.setItem("angelOneProfile", JSON.stringify(res.data));
            let redirectionTo = localStorage.getItem("handle-connect-url");
            if (redirectionTo) {
              localStorage.removeItem("handle-connect-url");
              window.location.href = redirectionTo;
            } else {
              window.location.href = "/";
            }
          } else {
            doLogin();
          }
        })
        .catch((err) => notificationTopup(err.message));
    })();
  }, []);

  return <></>;
};

export default HandleConnect;
