import React from "react";

const VisOops = () => {

  return (
    <div className="qf-data-load-err-container">
      <div className="qf-data-load-err-content">
        <span className="qf-data-load-err-heading">Oops!</span>
        <p className="qf-data-load-err-message">
          Our data feed has taken an unexpected break. Prices and charts are on vacation too. We're on it! In the meantime,
          why not check out some historical data or explore other parts of the dashboard? (You know, for fun.)
        </p>
        <p className="qf-data-load-err-message">Thank you for your patience.</p>
      </div>
    </div>
  );
};

export default VisOops;
