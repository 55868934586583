import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import QFLoader from "../vision/QFLoader";
import { groupINRCurrencyNSE } from "../../utils/functions";
import { dbyTodb, getRandomStockMessage } from "../../utils/configs";
import LinkToComp from "../minis/LinkToComp";
import VisDropDown from "../vision/VisDropDown";

const Deals = () => {
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState({
    BULK_DEALS: {
      data: [],
      number: 0,
      name: "BULK DEALS",
    },
    // SHORT_DEALS: {
    //   data: [],
    //   number: 0,
    //   name: "SHORT DEALS",
    // },
    BLOCK_DEALS: {
      data: [],
      number: 0,
      name: "BLOCK DEALS",
    },
  });
  const [selectedOption, setSelectedOption] = useState("BULK_DEALS");
  useEffect(() => {
    (async () => {
      await commonApiGet("/analysis/deals")
        .then((res) => {
          setOptions({
            BULK_DEALS: {
              data: res.data.BULK_DEALS_DATA,
              number: res.data.BULK_DEALS,
              name: "BULK DEALS",
            },
            SHORT_DEALS: {
              data: res.data.SHORT_DEALS_DATA,
              number: res.data.SHORT_DEALS,
              name: "SHORT DEALS",
            },
            BLOCK_DEALS: {
              data: res.data.BLOCK_DEALS_DATA,
              number: res.data.BLOCK_DEALS,
              name: "BLOCK DEALS",
            },
          });
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  return (
    <CommonCardView
      id="commonCard_deals"
      header="Bulk deals"
      headerAlign="left"
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: "Track market activities like bulk, block, and short deals executed by institutional investors and high-net-worth individuals. Gain insights into large transactions that may influence stock prices and trends.",
        id: "deals-in-stocks",
      }}
    >
      <VisDropDown
        selected={options[selectedOption].name}
        mapping={Object.keys(options).reduce((acc, key) => {
          acc[key] = options[key].name;
          return acc;
        }, {})}
        setter={(val) => {
          setSelectedOption(val);
        }}
      />

      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <div className=" akp-zoom">
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          <div className="py-1">
            <table className="w-100 table-tr-bb">
              <thead
                className="make-me-sticky non-trans-bg"
                style={{
                  top: 40,
                }}
              >
                <tr>
                  <th className="px-1 px-md-0">Date</th>
                  <th style={{ width: "50%" }} className="px-1 px-md-0 ">
                    Client
                  </th>
                  <th className="px-1 px-md-0">Action</th>
                  <th className="px-1 px-md-0">Symbol</th>
                </tr>
              </thead>
              <tbody>
                {options[selectedOption].data
                  .filter((val) => val.symbol.toLowerCase().includes(search.toLowerCase()))
                  .map((val, key) => {
                    return (
                      <tr key={key}>
                        <td className="px-1 px-md-0">{dbyTodb(val.date)}</td>
                        <td className="px-1 px-md-0">{val.clientName}</td>
                        <td className="px-1 px-md-0">
                          {val.buySell?.toLowerCase() === "buy"
                            ? "Bought "
                            : val.buySell?.toLowerCase() === "sell"
                            ? "Sold "
                            : ""}
                          {groupINRCurrencyNSE(val.qty)}
                        </td>
                        <td className="px-1 px-md-0">
                          <LinkToComp symbol={val.symbol} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {options[selectedOption].data.filter((val) => val.symbol.toLowerCase().includes(search.toLowerCase())).length === 0
            ? getRandomStockMessage()
            : ""}
        </div>
      )}
    </CommonCardView>
  );
};

export default Deals;
