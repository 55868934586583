import React from "react";

export const tncList = (
  <>
    <h2>Terms and Conditions</h2>
    <p>
      Effective Date: 12-Oct-2024 | Dushera
      <br />
      Last Updated: 12-Oct-2024 | Dushera
    </p>
    <p className="m-0">
      Welcome to Quibblefrost (“we”, “our”, “us”). These Terms and Conditions
      (“T&Cs”) govern your use of our stock dashboard platform available at
      www.quibblefrost.in (the “Site”). By using our platform, you agree to
      comply with these terms. If you do not agree, please refrain from using
      the platform.
    </p>
    <ol>
      <li>
        <strong>Use of the Site</strong>
        <ul>
          <li>You are at least 18 years of age.</li>
          <li>You will use the site only for lawful purposes.</li>
          <li>
            You will not engage in any behavior that could harm the site, other
            users, or us, such as attempting to hack, transmit viruses, or
            perform unauthorized data extraction.
          </li>
        </ul>
        <p className="m-0">
          Quibblefrost reserves the right to terminate or restrict your access
          if you violate these terms.
        </p>
      </li>
      <li>
        <strong>Registration and Account Security</strong>
        <ul>
          <li>
            <strong>Account Creation:</strong> To access certain features of the
            Site, you may be required to register and create an account. You
            must provide accurate and complete information during registration.
          </li>
          <li>
            <strong>Account Security:</strong> You are responsible for
            maintaining the confidentiality of your account credentials and for
            all activities that occur under your account. Notify us immediately
            if you detect unauthorized access to your account.
          </li>
        </ul>
      </li>
      <li>
        <strong>Services Provided</strong>
        <p className="m-0">Quibblefrost offers the following services:</p>
        <ul>
          <li>Real-time stock market data and analytics.</li>
          <li>Portfolio management tools (optional).</li>
          <li>Market insights and investment updates.</li>
        </ul>
        <p className="m-0">
          We strive to ensure the accuracy of data but cannot guarantee its
          completeness or correctness due to reliance on third-party providers.
        </p>
      </li>
      <li>
        <strong>Financial Disclaimer</strong>
        <ul>
          <li>
            Quibblefrost does not provide financial advice. All information on
            the platform is for informational purposes only.
          </li>
          <li>
            Stock market investments carry risk, and past performance is not
            indicative of future results.
          </li>
          <li>
            You are solely responsible for your investment decisions. We
            encourage you to consult with a financial advisor before making any
            decisions.
          </li>
        </ul>
      </li>
      <li>
        <strong>Fees and Payments</strong>
        <ul>
          <li>
            Quibblefrost may offer free and paid subscription services. If
            applicable, you agree to pay all charges associated with your
            subscription, including taxes.
          </li>
          <li>
            We may update pricing from time to time. You will be notified of any
            changes, and your continued use of the service will be considered
            acceptance of the new fees.
          </li>
        </ul>
      </li>
      <li>
        <strong>User Conduct</strong>
        <p className="m-0">You agree not to:</p>
        <ul>
          <li>Use the site for any illegal activities.</li>
          <li>Post or transmit offensive or inappropriate content.</li>
          <li>Engage in spamming, phishing, or data harvesting.</li>
          <li>
            Reverse-engineer, decompile, or copy any content from the site for
            commercial purposes without our explicit permission.
          </li>
        </ul>
        <p className="m-0">
          We reserve the right to remove any content that violates these T&Cs or
          applicable laws.
        </p>
      </li>
      <li>
        <strong>Intellectual Property</strong>
        <ul>
          <li>
            <strong>Ownership:</strong> All content on the Site, including text,
            graphics, software, and logos, is the property of Quibblefrost and
            is protected by intellectual property laws.
          </li>
          <li>
            <strong>Limited License:</strong> You are granted a limited,
            non-exclusive, non-transferable license to access and use the Site
            for personal or internal business purposes only.
          </li>
        </ul>
        <p className="m-0">
          Any unauthorized use of the Site’s content is strictly prohibited and
          may result in legal action.
        </p>
      </li>
      <li>
        <strong>Third-Party Links</strong>
        <p className="m-0">
          Quibblefrost may include links to third-party websites or services. We
          do not control or endorse these sites, and we are not responsible for
          their content or practices. Use third-party services at your own risk.
        </p>
      </li>
      <li>
        <strong>Limitation of Liability</strong>
        <ul>
          <li>
            Quibblefrost is provided “as is” and “as available” without any
            warranties of any kind, either express or implied.
          </li>
          <li>
            We are not liable for any losses or damages arising out of your use
            of the platform, including but not limited to data inaccuracies,
            service disruptions, or financial losses.
          </li>
        </ul>
        <p className="m-0">
          To the maximum extent allowed by applicable law, Quibblefrost will not
          be liable for any indirect, incidental, or consequential damages
          arising from the use of our services.
        </p>
      </li>
      <li>
        <strong>Indemnification</strong>
        <p className="m-0">
          You agree to indemnify, defend, and hold harmless Quibblefrost, its
          affiliates, and its employees from any claims, damages, losses, or
          expenses arising from your violation of these T&Cs or your improper
          use of the platform.
        </p>
      </li>
      <li>
        <strong>Termination</strong>
        <p className="m-0">
          We may terminate or suspend your account or access to the Site at any
          time, without notice, if you breach these T&Cs or engage in any
          activity that is harmful to Quibblefrost or its users.
        </p>
      </li>
      <li>
        <strong>Governing Law and Jurisdiction</strong>
        <p className="m-0">
          These T&Cs are governed by and construed in accordance with the laws
          of India. Any disputes arising from or related to these terms will be
          subject to the exclusive jurisdiction of the courts of [Your City],
          India.
        </p>
      </li>
      <li>
        <strong>Changes to the Terms</strong>
        <p className="m-0">
          Quibblefrost may revise these T&Cs at any time. We will notify you of
          any changes by updating the date at the top of this page. Continued
          use of the platform after any changes means that you accept the
          revised T&Cs.
        </p>
      </li>
      <li>
        <strong>Contact Us</strong>
        <p className="m-0">
          If you have any questions or concerns regarding these T&Cs, please
          contact us at:
        </p>
        <ul>
          <li>Email: ping@quibblefrost.in</li>
        </ul>
      </li>
    </ol>
  </>
);

const Tnc = () => {
  return <div className="col-12 p-4">{tncList}</div>;
};

export default Tnc;
