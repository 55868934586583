import React, { useEffect, useState } from "react";
import CommonCardView from "./vision/CommonCardView";
import { maxViewContent, rssFeedFromBlog } from "../utils/configs";
import QFLoader from "./vision/QFLoader";
import VisOops from "./vision/VisOops";
import VisLinkPreviewImg from "./vision/VisLinkPreviewImg";
import { links } from "../views/preLogin/components/Footer";

const decodeHTML = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return doc.documentElement.textContent;
};

const BlogBridge = () => {
  const [feedData, setFeedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRSSFeed = async () => {
      try {
        const response = await fetch(rssFeedFromBlog);
        if (!response.ok) throw new Error("Failed to fetch RSS feed");

        const text = await response.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, "text/xml");

        const items = Array.from(xml.querySelectorAll("item")).map((item) => ({
          title: item.querySelector("title")?.textContent || "No Title",
          description: item.querySelector("description")?.textContent || "No Description",
          link: item.querySelector("link")?.textContent || "#",
          pubDate: item.querySelector("pubDate")?.textContent || "No Date",
          categories: Array.from(item.querySelectorAll("category")).map((category) => category.textContent),
        }));

        setFeedData(items);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchRSSFeed();
  }, []);

  return (
    <CommonCardView
      id="commonCard_TopStories"
      header="Top Stories"
      headerInfo={{
        show: true,
        desc: "Stay updated with the latest trending news and top stories from around the globe.",
        id: "top-stories",
      }}
      toWatermark={true}
      style={{
        position: "relative",
      }}
    >
      <div
        className="py-2 my-2 text-center"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        {["telegram", "whatsapp"].map((key, ind) => (
          <span
            className="general-card-1 our-border"
            style={{
              padding: "7px 10px",
              marginRight: "10px",
              fontSize: "var(--fnt-h2)",
              borderRadius: "50%",
            }}
            key={ind}
          >
            <a key={key} href={links[key].url} className="text-color-themed m-0" target="_blank" rel="noopener noreferrer">
              <i className={links[key].icon} />
            </a>
          </span>
        ))}
      </div>
      {loading ? (
        <QFLoader />
      ) : error ? (
        <VisOops />
      ) : (
        <>
          {feedData.map((item, index) => (
            <div key={index} style={{ marginBottom: "1rem" }} className="section-bb pb-3 row">
              <div className="col-12 mb-2">
                <small>
                  {new Date(item.pubDate).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </small>
              </div>
              <div className="col-md-3 col-4 vert-center">
                <VisLinkPreviewImg link={item.link} title={item.title} />
              </div>
              <div className="col-md-9 vert-center col-8">
                <a href={item.link} target="_blank" rel="noopener noreferrer" className="link text-color-themed">
                  {maxViewContent(decodeHTML(item.title), 75)}
                </a>
              </div>

              <div className="col-12 mt-2">
                {item.categories.length > 0 && (
                  <p
                    style={{
                      overflowX: "scroll",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.categories.map((category, index) => (
                      <span key={index} className="div-pill our-border me-1 rounded">
                        {category}
                      </span>
                    ))}
                  </p>
                )}
              </div>
            </div>
          ))}
          <center>
            <button
              type="button"
              className={`btn-sm px-4 general-btn m-1`}
              style={{
                fontWeight: 400,
                color: "var(--text-color)",
              }}
              onClick={() => {
                window.open("https://blog.quibblefrost.in/", "_blank");
              }}
            >
              View More
            </button>
          </center>
        </>
      )}
    </CommonCardView>
  );
};

export default BlogBridge;
