import React, { useState } from "react";
import { getRandomStockMessage } from "../../utils/configs";

const VisTable = ({ data, recsPerPage, ...props }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(recsPerPage); // Default to 10

  const [noDataMsg, setNoDataMsg] = useState(getRandomStockMessage());

  // Destructure columns and rows from data
  const { columns, rows } = data;

  // Filter rows based on search term
  const filteredRows = rows.filter((row) =>
    columns.some((column) => String(row[column.field]).toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Calculate total pages
  const totalPages = itemsPerPage === "All" ? 1 : Math.ceil(filteredRows.length / itemsPerPage);

  // Get current items
  const currentItems =
    itemsPerPage === "All" ? filteredRows : filteredRows.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Handle page change
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle items per page change
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  return (
    <div {...props}>
      <div
        className="row py-2 non-trans-bg"
        style={{
          position: "sticky",
          top: "-2px",
          zIndex: 1,
        }}
      >
        <div className="col-8">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => {
              setCurrentPage(1);
              setSearchTerm(e.target.value);
            }}
            className="qfInputType py-1 px-2"
          />
        </div>
        <div className="col-4">
          <div className="custom-select w-100">
            <select onChange={handleItemsPerPageChange} value={itemsPerPage} className="qfInputType py-1 px-2">
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value="All">All</option>
            </select>
          </div>
          {/* <select
           
          >
            
          </select> */}
        </div>
      </div>
      <div
        className="w-100"
        style={{
          overflowX: "scroll",
        }}
      >
        <table className="w-100 table-tr-bb table-px">
          <thead>
            <tr>
              {columns
                .filter((val) => val.label !== undefined)
                .map((column) => (
                  <th key={column.field}>{column.label}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns
                    .filter((val) => val.label !== undefined)
                    .map((column) => (
                      <td key={column.field}>{row[column.field] !== undefined ? row[column.field] : "-"}</td>
                    ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length}>{noDataMsg}</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              {columns
                .filter((val) => val.label !== undefined)
                .map((column) => (
                  <th key={column.field}>{column.label}</th>
                ))}
            </tr>
          </tfoot>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <i
          onClick={handlePrevPage}
          className="div-pill general-card-1 our-border fas fa-caret-left"
          disabled={currentPage === 1}
          style={{
            ...(currentPage === 1
              ? {
                  opacity: 0.5,
                }
              : {}),
          }}
        ></i>
        <i
          onClick={handleNextPage}
          className="div-pill general-card-1 our-border fas fa-caret-right"
          disabled={currentPage === totalPages}
          style={{
            ...(currentPage === totalPages
              ? {
                  opacity: 0.5,
                }
              : {}),
          }}
        ></i>
      </div>
      <div style={{ textAlign: "center", padding: "10px" }}>
        <span>
          Page {currentPage} of {totalPages} | Total Records: {filteredRows.length}
        </span>
      </div>
    </div>
  );
};

export default VisTable;
