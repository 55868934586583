import { mathSum } from "../../../utils/configs";

export function calculateMaxPain(strikePrices, callOI, putOI) {
  let maxPain = null;
  let minTotalPain = Infinity;

  // Iterate through each strike price to evaluate its pain
  strikePrices.forEach((currentStrikePrice) => {
    let totalPain = 0;

    // Calculate Call Pain
    strikePrices.forEach((strikePrice, index) => {
      if (strikePrice > currentStrikePrice) {
        totalPain += callOI[index] * (strikePrice - currentStrikePrice); // OTM Calls
      }
    });

    // Calculate Put Pain
    strikePrices.forEach((strikePrice, index) => {
      if (strikePrice < currentStrikePrice) {
        totalPain += putOI[index] * (currentStrikePrice - strikePrice); // OTM Puts
      }
    });

    // Check if this is the minimum pain
    if (totalPain < minTotalPain) {
      minTotalPain = totalPain;
      maxPain = currentStrikePrice;
    }
  });

  return maxPain;
}

// export function calculatePOP(S, K, sigma, r, T) {
//     // Calculate d1 and d2
//     const d1 = (Math.log(S / K) + (r + Math.pow(sigma, 2) / 2) * T) / (sigma * Math.sqrt(T));
//     const d2 = d1 - sigma * Math.sqrt(T);

//     // Cumulative Distribution Function (N(d2))
//     const Nd2 = jStat.normal.cdf(d2, 0, 1);

//     // POP for Call Option
//     return 1 - Nd2; // For a call option
// }

export const calculatePOP = (strikePrices, totalPayoffs) => {
  // Assuming Gaussian distribution for simplicity
  const positivePayoffs = totalPayoffs
    .map((payoff, index) => ({
      strikePrice: strikePrices[index],
      payoff,
    }))
    .filter((item) => item.payoff >= 0);

  const totalCount = totalPayoffs.length;
  const profitableCount = positivePayoffs.length;

  // POP as percentage
  const POP = (profitableCount / totalCount) * 100;
  return { positivePayoffs, POP };
};

export function calculatePOPNew(legs, spotPrice, breakevenPoints) {
  // Validate input
  if (!Array.isArray(legs) || legs.length === 0) {
    return "No option legs provided.";
  }
  if (
    typeof spotPrice !== "number" ||
    !Array.isArray(breakevenPoints) ||
    breakevenPoints.length === 0
  ) {
    return "Invalid spot price or breakeven points.";
  }

  let totalProbability = 0;

  legs.forEach((leg) => {
    const isProfitable =
      leg.action === "S"
        ? spotPrice >= breakevenPoints[0] && spotPrice <= breakevenPoints[1]
        : !(spotPrice >= breakevenPoints[0] && spotPrice <= breakevenPoints[1]);

    if (isProfitable) {
      // Selling options: Add POP based on OTM probability
      if (leg.action === "S") {
        totalProbability += 1 - Math.abs(leg.delta);
      }
      // Buying options: Add POP based on ITM probability
      else {
        totalProbability += 1 - Math.abs(leg.delta);
      }
    }
  });

  // Normalize total probability to a percentage
  const normalizedPOP =
    Math.max(0, Math.min(1, totalProbability / legs.length)) * 100;

  return `${normalizedPOP.toFixed(2)}%`;
}

export function calculatePOPNewwo(legs) {
  // Validate that the legs array is not empty
  if (legs.length === 0) {
    return "No option legs provided.";
  }

  let calculatedDelta = 0;

  legs.map((val) => {
    if (val.action === "S") {
      // if (val.type === "CE") {
      calculatedDelta += 1 - Math.abs(val.delta);
      // } else {
      //   calculatedDelta += val.delta;
      // }
    } else {
      // if (val.type === "CE") {
      calculatedDelta += Math.abs(val.delta);
      // } else {
      //   calculatedDelta += 1 - val.delta;
      // }
      // calculatedDelta += val.delta;
    }
  });

  // If no valid case is found, return unexpected configuration
  return ((calculatedDelta / legs.length) * 100).toFixed(2) + "%";
}

export function calculatePOPNew2(legs) {
  // Filter out the "Sell" legs and "Buy" legs
  const PELegs = legs.filter((leg) => leg.type === "PE");
  const CELegs = legs.filter((leg) => leg.type === "CE");

  // Validate that the legs array is not empty
  if (legs.length === 0) {
    return "No option legs provided.";
  }

  // if (legs.length === 1) {
  //   if (legs[0].action === "S") {
  //     // POP for Sell leg = 1 - |Delta|, because we want the option to expire out-of-the-money (OTM)
  //     const sellPOP = 1 - Math.abs(legs[0].delta);
  //     return (sellPOP * 100).toFixed(2) + "%"; // Return POP as percentage
  //   }

  //   // Case for Buy Leg (Long position)
  //   if (legs[0].action === "B") {
  //     // POP for Buy leg = |Delta|, because we want the option to expire in-the-money (ITM)
  //     const buyPOP = Math.abs(legs[0].delta);
  //     return (buyPOP * 100).toFixed(2) + "%"; // Return POP as percentage
  //   }
  // }

  // Identify the lower strike Put (PE) and the higher strike Call (CE) for Iron Condor
  const lowerStrikePut = PELegs.reduce(
    (min, leg) => (leg.strike < min.strike ? leg : min),
    legs[0]
  );

  const higherStrikeCall = CELegs.reduce(
    (max, leg) => (leg.strike > max.strike ? leg : max),
    legs[0]
  );

  if (lowerStrikePut && higherStrikeCall) {
    // Calculate POP as the difference in deltas (lower PE delta - higher CE delta)
    const lowerStrikePutDelta = lowerStrikePut.delta || 0;
    const higherStrikeCallDelta = higherStrikeCall.delta || 0;

    const pop = 1 + lowerStrikePutDelta - higherStrikeCallDelta;

    // Return POP in percentage
    return (pop * 100).toFixed(2) + "%";
  }

  // If no valid case is found, return unexpected configuration
  return "Unexpected leg configuration.";
}

export function maxPrLoss(optionLegs, lotSize) {
  // let maxProfit = Math.max(...totalPayoffs);
  // let maxLoss = Math.min(...totalPayoffs);
  if (optionLegs.length === 0) return [,];
  let maxPCE = [],
    maxPPE = [],
    maxLCE = [],
    maxLPE = [],
    isSellPECount = 0,
    isBuyPECount = 0,
    isSellCECount = 0,
    isBuyCECount = 0,
    isBuy = 0,
    isSell = 0;
  optionLegs
    .filter((val) => val.optionType === "PE")
    .map((val) => {
      if (val.action === "S") {
        isSell = 1;
        isSellPECount += 1;
        maxLPE.push(-Infinity);
        maxPPE.push(val.tradedPrice * val.lots * lotSize);
      } else {
        isBuy = 1;
        isBuyPECount += 1;
        maxPPE.push(Infinity);
        maxLPE.push(-val.tradedPrice * val.lots * lotSize);
      }
    });

  optionLegs
    .filter((val) => val.optionType === "CE")
    .map((val) => {
      if (val.action === "S") {
        isSell = 1;
        isSellCECount += 1;
        maxLCE.push(-Infinity);
        maxPCE.push(val.tradedPrice * val.lots * lotSize);
      } else {
        isBuy = 1;
        isBuyCECount += 1;
        maxPCE.push(Infinity);
        maxLCE.push(-val.tradedPrice * val.lots * lotSize);
      }
    });

  var calcualtedLoss, calcualtedPr;

  if (isBuy + isSell === 1) {
    if (isSell && !isBuy) {
      calcualtedLoss = "Unlimited";
      calcualtedPr = mathSum([...maxPCE, ...maxPPE]).toFixed(2);
    }
    if (!isSell && isBuy) {
      calcualtedPr = "Unlimited";
      calcualtedLoss = mathSum([...maxLCE, ...maxLPE]).toFixed(2);
    }
  } else {
    var tlpe = Math.max(...maxLPE);
    var tlce = Math.max(...maxLCE);
    var tppe = Math.min(...maxPPE);
    var tpce = Math.min(...maxPCE);
    calcualtedLoss = (tlpe + tppe + tlce + tpce).toFixed(2);
    calcualtedPr = 0;
  }
  // console.log(maxPCE, maxPPE, maxLCE, maxLPE);
  return [calcualtedPr, calcualtedLoss];
}

export function minMaxProfitFromPayoffs(payOffs) {
  let minFrompo = Math.min(...payOffs);
  let maxFrompo = Math.max(...payOffs);

  
  let len = payOffs.length;
  if (len < 10) return [,];
  // console.log(minFrompo, maxFrompo, payOffs[0],payOffs[len-1])
  if (
    (minFrompo == payOffs[0] &&
      payOffs[0] != payOffs[1] &&
      payOffs[1] != payOffs[2] &&
      payOffs[2] != payOffs[3]) ||
    (minFrompo == payOffs[len - 1] &&
      payOffs[len - 1] != payOffs[len - 2] &&
      payOffs[len - 2] != payOffs[len - 3] &&
      payOffs[len - 3] != payOffs[len - 4])
  ) {
    minFrompo = "Unlimited";
  }
  if (
    (maxFrompo == payOffs[0] &&
      payOffs[0] != payOffs[1] &&
      payOffs[1] != payOffs[2] &&
      payOffs[2] != payOffs[3]) ||
    (maxFrompo == payOffs[len - 1] &&
      payOffs[len - 1] != payOffs[len - 2] &&
      payOffs[len - 2] != payOffs[len - 3] &&
      payOffs[len - 3] != payOffs[len - 4])
  ) {
    maxFrompo = "Unlimited";
  }

  if (minFrompo !== "Unlimited") minFrompo = `₹${minFrompo}`;
  if (maxFrompo !== "Unlimited") maxFrompo = `₹${maxFrompo}`;

  return [maxFrompo, minFrompo];
}
