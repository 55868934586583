import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const EconomicCalendar = () => {
    const theme = useSelector(state => state.theme);
    useEffect(() => {
        // Load the TradingView widget script
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
            // width: "100%",
            height: "450",
            colorTheme: theme,
            isTransparent: true,
            locale: "en",
            importanceFilter: "-1,0,1",
            countryFilter: "us,hk,jp,eu,in,gb"
        });
        document.getElementsByClassName('tradingview-widget-container__widget_exonomic_event')[0].appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            const widgetContainer = document.getElementsByClassName('tradingview-widget-container__widget_exonomic_event')[0];
            if (widgetContainer) {
                widgetContainer.innerHTML = ''; // Clear the widget on unmount
            }
        };
    }, [theme]);

    return (
        <div className="tradingview-widget-container general-card-1  vert-center h-min-full">
            <div className="tradingview-widget-container__widget_exonomic_event" style={{
                // height: "-webkit-fill-available"
            }}></div>
        </div>
    );
};

export default EconomicCalendar;