import App from "./views/App";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import HawkBoundary from "./utils/hawkeye/HawkBoundary";
import { Provider } from "react-redux";
import hawkQuiver from "./utils/hawkeye/hawkQuiver";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <HelmetProvider>
    <HawkBoundary>
      <Provider store={hawkQuiver}>
        <App />
        {/* <SpeedInsights />
      <Analytics /> */}
      </Provider>
    </HawkBoundary>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
