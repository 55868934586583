import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import QFLoader from "../../vision/QFLoader";
import VisOops from "../../vision/VisOops";
import BarChartWithNegativeValues from "../ChartComponents/BarChartWithNegativeValues";
import { chartColor } from "../../../utils/configs";
import { Button, Collapse } from "reactstrap";
import { groupINRCurrencyNSE } from "../../../utils/functions";

const FiiDiiPageDayWise = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    (async () => {
      await commonApiGet("/analysis/fii_dii")
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          notificationTopup(err.message);
        });
    })();
  }, []);

  const toggleExpand = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="h-100 w-100" style={{ overflowY: "scroll" }}>
      {loading ? (
        <QFLoader />
      ) : error ? (
        <VisOops />
      ) : (
        <div className="row p-0 m-0">
          <div className="col-12">*All values in Cr</div>
          {data.map((item, index) => {
            return (
              <div key={index} className="col-md-6 section-bb section-bl py-2">
                <p className="h6 text-center">{item.Date.split("T")[0]}</p>
                <BarChartWithNegativeValues
                  data={{
                    keys: item.FIIDIIData.map((val) => val.ShortName),
                    values: item.FIIDIIData.map((val) => val.Value),
                  }}
                />
                <center>
                  <span className="general-btn px-2 py-1" onClick={() => toggleExpand(index)}>
                    {expanded[index] ? "Collapse" : "Expand"}
                  </span>
                </center>
                <Collapse isOpen={expanded[index]}>
                  <div className="row mt-2">
                    {item.FIIDIIData.filter((val) => val.ChildData?.length > 0).map((val, index) => {
                      return (
                        <div key={index} className="col-md">
                          <table className="w-100">
                            <thead>
                              <tr>
                                <td colSpan={2} className="text-center non-trans-bg">
                                  {val.ShortName}
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {val.ChildData.map((child, index) => {
                                return (
                                  <tr key={index} className={child.Value > 0 ? "text-success" : "text-danger"}>
                                    <td>{child.Name}</td>
                                    <td>{groupINRCurrencyNSE(child.Value, true)}Cr.</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                  </div>
                </Collapse>
                <center>
                  <hr className="w-50" />
                </center>
                <div className="row text-center">
                  {item.ClosePrice.map((price, index) => {
                    return (
                      <div key={index} className="col-4">
                        <span>{price.Symbol}</span>
                        <br />₹{price.C}
                        <br />
                        <span className={`text-s ${price.CZG > 0 ? "text-success" : "text-danger"}`}>
                          {price.CZ} ({price.CZG}%)
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FiiDiiPageDayWise;
