import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import VisOops from "../../components/vision/VisOops";
import QFLoader from "../../components/vision/QFLoader";
import { camelCaseToSentence } from "../../utils/configs";
import { groupINRCurrencyNSE } from "../../utils/functions";
import VisDropDown from "../../components/vision/VisDropDown";

const nonCustomTable = (config, data) => {
  let replaceConfig = {
    broadcastdate: "Date",
  };
  return (
    <table className="w-100 table-tr-bb">
      <thead className=" make-me-sticky non-trans-bg">
        <tr>
          {config.keys.map((val, key) => (
            <td key={key}>{camelCaseToSentence(replaceConfig[val] || val)}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {data[config.inside].map((val, key) => (
          <tr key={key}>
            {config.keys.map((valIn, keyIn) => (
              <td key={keyIn}>{camelCaseToSentence(val[valIn])}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const EquityNews = ({ symbol }) => {
  const [newsData, setNewsData] = useState({
    latest_announcements: { data: [] },
    corporate_actions: { data: [] },
    shareholdings_patterns: { data: {} },
    financial_results: { data: [] },
    borad_meeting: { data: [] },
  });

  const config = {
    latest_announcements: {
      inside: "data",
      keys: ["broadcastdate", "subject"],
    },
    corporate_actions: { inside: "data", keys: ["exdate", "purpose"] },
    financial_results: {
      isCustom: true,
    },
    borad_meeting: { inside: "data", keys: ["purpose", "meetingdate"] },
    shareholdings_patterns: { isCustom: true },
  };

  const [newsOption, setNewsOption] = useState("corporate_actions");
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/stocks/getFundamentals?symbol=${symbol}`)
        .then((res) => {
          setLoaderShow(false);
          setErrorShow(false);
          setNewsData(res.data);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [symbol]);

  return (
    <div
      className="px-3 akp-zoom vert-center"
      style={{
        minHeight: "300px",
      }}
    >
      <VisDropDown
        selected={newsOption}
        formattor={(val) => camelCaseToSentence(val.replaceAll("_", " "))}
        list={Object.keys(newsData)}
        setter={(val) => {
          setNewsOption(val);
        }}
      />
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <div className="mt-5">
          {Object.keys(config).map((val, key) => {
            if (val === newsOption) {
              return (
                <div
                  key={`QF_EQ_NEWS_SECTION_${key}`}
                  style={{
                    height: "300px",
                    overflowY: "scroll",
                  }}
                >
                  {!config[val].isCustom ? (
                    nonCustomTable(config[val], newsData[val])
                  ) : val === "financial_results" ? (
                    newsData["financial_results"].data.map((valIn, keyIn) => {
                      return (
                        <table key={keyIn} className="w-100 section-bb mb-3">
                          <tbody>
                            <tr
                              className="section-bb"
                              style={{
                                backgroundColor: "var(--text-color)",
                                color: "var(--body-bg)",
                              }}
                            >
                              <td>
                                {valIn.to_date}
                                <br />
                                {valIn.cumulative}
                              </td>
                              <td>
                                {valIn.audited}
                                <br />
                                {valIn.consolidated}
                              </td>
                            </tr>
                            <tr>
                              <td>Income: </td>
                              <td>{groupINRCurrencyNSE(parseFloat(valIn.income) * 100000, true)}</td>
                            </tr>
                            <tr>
                              <td>Expense: </td>
                              <td>{groupINRCurrencyNSE(parseFloat(valIn.expenditure) * 100000, true)}</td>
                            </tr>
                            <tr>
                              <td>Pr. Before Tax: </td>
                              <td>{groupINRCurrencyNSE(parseFloat(valIn.reProLossBefTax) * 100000, true)}</td>
                            </tr>
                            <tr>
                              <td>Pr. After Tax: </td>
                              <td>{groupINRCurrencyNSE(parseFloat(valIn.proLossAftTax) * 100000, true)}</td>
                            </tr>
                            <tr>
                              <td>Earning Per Share: </td>
                              <td>{valIn.reDilEPS}</td>
                            </tr>
                            {valIn.na_attachment ? (
                              <tr>
                                <td>Attachment: </td>
                                <td>
                                  <a
                                    href={valIn.na_attachment}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "var(--text-color)",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Download Now: <i className="fas fa-file"></i>
                                  </a>
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      );
                    })
                  ) : val === "shareholdings_patterns" ? (
                    <table className="w-100 table-tr-bb">
                      <thead>
                        <tr>
                          <td>Date</td>
                          <td>Promoter (%)</td>
                          <td>Public (%)</td>
                          <td>Employee (%)</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(newsData["shareholdings_patterns"].data).map(([date, entries]) => {
                          let temp = {
                            ...entries[0],
                            ...entries[1],
                            ...entries[2],
                            ...entries[3],
                          };
                          return (
                            <tr key={date}>
                              <td>{date}</td>
                              <td>{entries[0]["Promoter & Promoter Group"]}</td>
                              <td>{entries[1]["Public"]}</td>
                              <td>{entries[2]["Shares held by Employee Trusts"]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <></>
                  )}
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default EquityNews;
