import React from "react";
import { Helmet } from "react-helmet-async";

export const PreMarketHelmet = () => {
  return (
    <Helmet data-rh="true">
      <title>{`Premarket Info - Quibblefrost`}</title>
      <meta
        name="description"
        content={`Get real-time insights on NSE stocks with our comprehensive coverage of premarket trading, delivery activities, and band changes. Explore previous day's bulk and block deals, discover intraday stock options, and access live short selling data to enhance your trading strategy.`}
      />
      <meta
        name="keywords"
        content={`NSE stocks, premarket trading, delivery activities, band changes, bulk deals, block deals, intraday stock options, live short selling data, stock market trends, investment opportunities, stock price updates, trading strategies, financial news, market analysis, equity trading, stock performance`}
      />
      <meta
        property="og:title"
        content={`Premarket Insights - Real-Time Updates | Band Changes | Bulk/Block Deals | Intraday Stocks | Live Short Selling Data - Quibblefrost`}
      />
      <meta
        property="og:description"
        content={`Stay ahead in the stock market with real-time updates on band changes, and the latest news from the NSE exchange on Quibblefrost. Discover market trends and investment opportunities today!`}
      />
      <meta
        name="twitter:title"
        content={`Premarket Insights - Real-Time Updates | Band Changes | Bulk/Block Deals | Intraday Stocks | Live Short Selling Data - Quibblefrost`}
      />
      <meta
        name="twitter:description"
        content={`Stay ahead in the stock market with real-time updates on band changes, and the latest news from the NSE exchange on Quibblefrost. Discover market trends and investment opportunities today!`}
      />
    </Helmet>
  );
};

export const DefaultHelmet = () => {
  return (
    <Helmet data-rh="true">
      <title>Quibblefrost | Market Ki Har Chal Pe Nazar</title>
      <meta
        name="description"
        content="Stay updated with the latest market trends, stock analysis, and investment insights on Quibblefrost."
      />
      <meta name="keywords" content="stock market, investment, trading, finance, NSE, IPO, watchlist, derivatives" />
      <meta property="og:title" content="Quibblefrost | Market Ki Har Chal Pe Nazar" />
      <meta
        property="og:description"
        content="Explore comprehensive market data and insights to make informed investment decisions."
      />
      <meta name="twitter:title" content="Quibblefrost | Market Ki Har Chal Pe Nazar" />
      <meta
        name="twitter:description"
        content="Join Quibblefrost for the latest updates and analysis in the stock market."
      />
    </Helmet>
  );
};

export const MarketWatchHelmet = () => {
  return (
    <Helmet data-rh="true">
      <title>{`Market Watch - Quibblefrost`}</title>
      <meta
        name="description"
        content={`Stay updated with real-time insights on market trends, stock performance, and trading strategies. Explore our comprehensive coverage of scanners, SharkFolio, and FiiDii monthly reports to enhance your investment decisions.`}
      />
      <meta
        name="keywords"
        content={`market watch, stock market, trading strategies, scanners, SharkFolio, FiiDii monthly, investment insights, financial news, stock performance, market trends`}
      />
      <meta
        property="og:title"
        content={`Market Watch - Real-Time Insights | Scanners | SharkFolio | FiiDii Monthly - Quibblefrost`}
      />
      <meta
        property="og:description"
        content={`Get the latest updates on market trends and stock performance with Quibblefrost's Market Watch. Discover valuable insights to enhance your trading strategies!`}
      />
      <meta
        name="twitter:title"
        content={`Market Watch - Real-Time Insights | Scanners | SharkFolio | FiiDii Monthly - Quibblefrost`}
      />
      <meta
        name="twitter:description"
        content={`Get the latest updates on market trends and stock performance with Quibblefrost's Market Watch. Discover valuable insights to enhance your trading strategies!`}
      />
    </Helmet>
  );
};

export const EquityOverviewHelmet = () => {
  return (
    <Helmet data-rh="true">
      <title>{`Equity Overview - Quibblefrost`}</title>
      <meta
        name="description"
        content={`Explore the latest trends in equity markets with our comprehensive overview. Get insights on trending stocks, advancers and decliners, and price band hitters to make informed investment decisions.`}
      />
      <meta
        name="keywords"
        content={`equity overview, trending stocks, advancers, decliners, price band hitters, stock market trends, investment insights, financial news, equity trading`}
      />
      <meta
        property="og:title"
        content={`Equity Overview - Real-Time Insights | Trending Stocks | Advancers & Decliners | Price Band Hitters - Quibblefrost`}
      />
      <meta
        property="og:description"
        content={`Stay updated with the latest equity market trends. Discover trending stocks, advancers and decliners, and price band hitters to enhance your trading strategies on Quibblefrost.`}
      />
      <meta
        name="twitter:title"
        content={`Equity Overview - Real-Time Insights | Trending Stocks | Advancers & Decliners | Price Band Hitters - Quibblefrost`}
      />
      <meta
        name="twitter:description"
        content={`Stay updated with the latest equity market trends. Discover trending stocks, advancers and decliners, and price band hitters to enhance your trading strategies on Quibblefrost.`}
      />
    </Helmet>
  );
};

export const IpoDataHelmet = () => {
  return (
    <Helmet data-rh="true">
      <title>{`IPO Data - Quibblefrost`}</title>
      <meta
        name="description"
        content={`Stay informed with the latest IPO data. Explore ongoing, upcoming, and listed IPOs to make informed investment decisions on Quibblefrost.`}
      />
      <meta
        name="keywords"
        content={`IPO data, IPO subscription, ongoing IPOs, upcoming IPOs, listed IPOs, investment insights, stock market, financial news`}
      />
      <meta property="og:title" content={`IPO Data - Real-Time Insights | Ongoing, Upcoming & Listed IPOs - Quibblefrost`} />
      <meta
        property="og:description"
        content={`Discover the latest IPO data including ongoing, upcoming, and listed IPOs to enhance your investment strategies on Quibblefrost.`}
      />
      <meta
        name="twitter:title"
        content={`IPO Data - Real-Time Insights | Ongoing, Upcoming & Listed IPOs - Quibblefrost`}
      />
      <meta
        name="twitter:description"
        content={`Discover the latest IPO data including ongoing, upcoming, and listed IPOs to enhance your investment strategies on Quibblefrost.`}
      />
    </Helmet>
  );
};
