import React, { useState } from "react";
import { commonApiPost } from "../../../utils/api";
import { Col, Row } from "reactstrap";
import { notificationTopup } from "../../../utils/NotificationTopup";

export default function Contact({ closeFunc }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const handleContact = async () => {
    await commonApiPost("/utils/contact-us", {
      email,
      name,
      mobile_number: mobileNumber,
      message,
    })
      .then((res) => {
        closeFunc(true);
      })
      .catch((err) => {
        notificationTopup(err.message);
        closeFunc(false);
      });
  };

  return (
    <div
      className="centered-inside"
      style={{
        minHeight: "100%",
      }}
    >
      <div
        className="signup-container my-2"
        style={{
          minHeight: "100%",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleContact();
          }}
        >
          <Row>
            <Col sm={12}>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name*"
                required
              />
            </Col>
            <Col sm={12}>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email*"
                required
              />
            </Col>
            <Col sm={12}>
              <input
                type="tel"
                name="mobileNumber"
                placeholder="Mobile Number* 9999999999"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                required
                pattern="[6-9]{1}[0-9]{9}"
              />
            </Col>
            <Col sm={12}>
              <textarea
                type="textarea"
                name="message"
                placeholder="Message goes here...*"
                onChange={(e) => setMessage(e.target.value)}
                required
              >
                {message}
              </textarea>
            </Col>
          </Row>

          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}
