import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { commonApiPost } from "../../utils/api";
import { Col, Row } from "reactstrap";
import { notificationTopup } from "../../utils/NotificationTopup";
import { GoogBtn } from "../preLogin/components/Ctas";
import Divider from "../preLogin/components/Divider";
import QFLoader from "../../components/vision/QFLoader";
import { urlsFE } from "../pageroutes";

export default function Signup() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const usernametoconfirm = queryParams.get("usernametoconfirm");

  const [isCooldown, setIsCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(60); // 60 seconds

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [username, setUsername] = useState(usernametoconfirm || "");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [birthday, setBirthday] = useState(""); // New state for birthday
  const [gender, setGender] = useState(""); // New state for gender
  const [verificationToken, setVerificationToken] = useState(usernametoconfirm ? "ok" : "error");
  const [verificationProcess, setVerificationProcess] = useState(usernametoconfirm ? true : false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const [viewPass, setViewPass] = useState(false);

  const [loaderShow, setLoaderShow] = useState(false);

  const n = useNavigate();
  const handleSignUp = async () => {
    if (password === passwordConfirm) {
      setLoaderShow(true);
      await commonApiPost("/user/signup", {
        username,
        email,
        password: password,
        birthday,
        gender,
        name: firstName,
        phone_number: mobileNumber,
      })
        .then((res) => {
          setVerificationToken("ok");
        })
        .catch((err) => {
          setVerificationToken("error");
          if(err.response.status >= 400 && err.response.status < 500 && err.response.data.error){
            notificationTopup(err.response.data.error, 'red', true)
          }
          else{
            notificationTopup(err.message);
          }
        });
      setLoaderShow(false);
    } else {
      notificationTopup("Passwords do not match", 'red', true);
    }
  };

  useEffect(() => {
    if (verificationToken !== "error") {
      setVerificationProcess(true);
    }
  }, [verificationToken]);

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("Text");
    const pastedOtp = pastedData.match(/\d/g);
    if (pastedOtp && pastedOtp.length === 6) {
      setOtp(pastedOtp);
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index >= 0 && otp[index] === "") {
      event.preventDefault();
      if (index == 0) index = 1;
      refs[index - 1].current.focus();
    } else {
      const newOtp = [...otp];
      const inputValue = event.key;
      // Accept only numerical values
      const numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
      newOtp[index] = numericValue;
      setOtp(newOtp);
      if (index < 5 && numericValue !== "") {
        refs[index + 1].current.focus();
      }
    }
  };

  useEffect(() => {
    if (otp.join("").length === 6) {
      handleAuthVerification();
    }
  }, [otp]);

  const handleAuthVerification = async () => {
    await commonApiPost(`/user/confirm-email?code=${otp.join("")}&username=${username}`)
      .then((res) => {
        notificationTopup(res.data.message, "green");
        n(urlsFE.signin);
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };

  useEffect(() => {
    let timer;
    if (isCooldown) {
      timer = setInterval(() => {
        setCooldownTime((prev) => {
          if (prev <= 1) {
            setIsCooldown(false);
            clearInterval(timer);
            return 60; // reset to 60 seconds
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isCooldown]);

  return (
    <div
      className="centered-inside col-12"
      style={{
        minHeight: "80%",
      }}
    >
      <div className="signup-container">
        <h3>{!verificationProcess ? "Join Today" : "Verify Email"}</h3>
        <Divider role="common_card_view" py={1} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSignUp();
          }}
          className="pt-3"
        >
          {verificationProcess == false ? (
            <Row>
              <Col sm={12}>
                <input
                  type="text"
                  name="fname"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Full Name"
                  pattern="^[A-Za-z ]+$"
                  title="Full name must contain only letters and spaces."
                  required
                />
              </Col>
              <Col sm={12}>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </Col>
              <Col sm={5}>
                <input
                  type="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  required
                />
              </Col>
              <Col sm={7}>
                <input
                  type="tel"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  required
                  pattern="[6-9]{1}[0-9]{9}"
                />
              </Col>
              <Col sm={6}>
                <input
                  type="text"
                  name="birthday"
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                  placeholder="DOB (DD-MM-YYYY)"
                  required
                  pattern="\d{2}-\d{2}-\d{4}" // Regex pattern for validation
                />
              </Col>
              <Col sm={6}>
                <select name="gender" value={gender} onChange={(e) => setGender(e.target.value)} required>
                  <option value="" disabled>
                    Select Gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </Col>
              <Col
                sm={6}
                style={{
                  position: "relative",
                }}
              >
                <input
                  type={viewPass ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$"
                  title="Password must contain at least one letter, one number, and one special character."
                  required
                />
                <span className="password-toggle-icon">
                  <i onClick={() => setViewPass(!viewPass)} className={viewPass ? "fas fa-lock-open" : "fas fa-lock"}></i>
                </span>
              </Col>
              <Col sm={6}>
                <input
                  type={viewPass ? "text" : "password"}
                  name="passwordConfirm"
                  placeholder="Confirm Password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$"
                  title="Password must contain at least one letter, one number, and one special character."
                  required
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="w-100 p-0 pb-2">
                <Col
                  sm={12}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <i className="fas fa-at me-1" />
                  {username}
                </Col>
              </Row>
              <Row className="otp-verification-text-center">
                {otp.map((value, index) => (
                  <Col key={index}>
                    <input
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => e.preventDefault()}
                      onPaste={handlePaste}
                      ref={refs[index]}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}

          {!verificationProcess ? (
            loaderShow ? (
              <div className="py-2">
                <QFLoader />
              </div>
            ) : (
              <button type="submit">Sign up</button>
            )
          ) : (
            <button
              type="button"
              onClick={() =>
                (async () => {
                  if (isCooldown) return;
                  setIsCooldown(true);
                  await commonApiPost(`/user/resend-email?username=${username}`)
                    .then((res) => {
                      // console.log(res)
                      notificationTopup(res.data.message, "green");
                      setCooldownTime(60); // reset cooldown time
                    })
                    .catch((err) => {
                      notificationTopup(err.response.data.error);
                      // console.log(err)
                    });
                })()
              }
            >
              {isCooldown ? `Resend OTP (${cooldownTime}s)` : "Resend OTP"}
            </button>
          )}
        </form>
        <Divider role="text" py="3" />
        {!verificationProcess ? (
          <>
            <GoogBtn text="Signup" />
            <p>
              Already have account? <Link to={urlsFE.signin}>Login</Link> <br />
              Jump to{" "}
              <strong
                onClick={() => {
                  if (username.length === 0) {
                    notificationTopup("Please fill username");
                  } else {
                    setVerificationProcess(true);
                  }
                }}
              >
                OTP Verification
              </strong>
            </p>
          </>
        ) : (
          <>
            Click to{" "}
            <strong
              onClick={() => {
                setVerificationProcess(false);
              }}
            >
              edit information
            </strong>
          </>
        )}
      </div>
    </div>
  );
}
