import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { themedTextColor } from "../../../utils/configs";

const ForexMovement = () => {
  const theme = useSelector((state) => state.theme);
  useEffect(() => {
    // Load the TradingView widget script
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "450",
      currencies: [
        "INR", // Indian Rupee on top
        "USD", // United States Dollar
        "EUR", // Euro
        "GBP", // British Pound
        "JPY", // Japanese Yen
        "AUD", // Australian Dollar
        "SGD", // Singapore Dollar
        "HKD", // Hong Kong Dollar
      ],
      isTransparent: true,
      colorTheme: theme,
      locale: "en",
      backgroundColor: "transparent",
    });
    document.getElementsByClassName("tradingview-widget-container__widget_fx_move")[0].appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const widgetContainer = document.getElementsByClassName("tradingview-widget-container__widget_fx_move")[0];
      if (widgetContainer) {
        widgetContainer.innerHTML = ""; // Clear the widget on unmount
      }
    };
  }, [theme]);

  return (
    <div className="tradingview-widget-container general-card-1  vert-center h-min-full">
        <div
          className="tradingview-widget-container__widget_fx_move"
          style={{
            // height: "-webkit-fill-available",
            // overflow: "hidden",
            padding: '8px'
          }}
        ></div>
    </div>
  );
};

export default ForexMovement;
