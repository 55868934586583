import React, { useEffect, useRef, useState } from "react";
import { chartColor, getDateRangeAO, isMobile, themedTextColor } from "../../../utils/configs";
import { useSelector } from "react-redux";
import { createChart } from "lightweight-charts";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { getCandles } from "../../../utils/functions";
import { AngelOneWebSocketSmartStream } from "../../integrations/angel-one/angelOneWebsocket";
import { getTokenForSymbolA1 } from "../../integrations/angel-one/angelOneHelper";
import { getBaseTimeForUnit, volumeForCurrentUnitCandle } from "./helpers/utils";
import indexSymbolName from "../../../utils/mappings/indices-symbolName.json";

const VisPreviewChart = ({
  symbol,
  exchange,
  name,
  isEq = true,
  settingLiveOrderBook = () => {},
  livePriceReflector = (updatedData) => {},
}) => {
  const theme = useSelector((state) => state.theme);
  const upColor = chartColor.green;
  const downColor = chartColor.red;

  const ohlcLiveCandleWithTimeHolderRef = useRef({});

  const handleOhlcLive = (time, ltp, volume) => {
    let holder = ohlcLiveCandleWithTimeHolderRef.current[time];
    if (holder) {
      holder.close = ltp;
      ohlcData.current.values[ohlcData.current.values.length - 1].close = ltp;
      if (holder.low > ltp) {
        holder.low = ltp;
        ohlcData.current.values[ohlcData.current.values.length - 1].low = ltp;
      }
      if (holder.high < ltp) {
        holder.high = ltp;
        ohlcData.current.values[ohlcData.current.values.length - 1].close = ltp;
      }
    } else {
      holder = { time, open: ltp, high: ltp, low: ltp, close: ltp };
      ohlcData.current.values.push({ time, open: ltp, high: ltp, low: ltp, close: ltp });
    }
    ohlcLiveCandleWithTimeHolderRef.current[time] = holder;
    ohlcLiveCandleWithTimeHolderRef.current[time].volume = volume;
  };

  const [options, setOptions] = useState({
    // ...(!isMobile() ? { ONE_MINUTE: { label: "1M", days: 30 }, THREE_MINUTE: { label: "3M", days: 60 } } : {}),
    FIVE_MINUTE: { label: "5M", days: 100 },
    TEN_MINUTE: { label: "10M", days: 100 },
    FIFTEEN_MINUTE: { label: "15M", days: 200 },
    THIRTY_MINUTE: { label: "30M", days: 200 },
    ONE_HOUR: { label: "1H", days: 400 },
    ONE_DAY: { label: "1D", days: 2000 },
  });

  const [selectedOption, setSelectedOption] = useState("ONE_DAY");
  const dtpvalue = useRef([]);
  const chartContainerRef = useRef();
  const chartRef = useRef(); // Create a ref for the chart instance
  const legendRef = useRef(); // Reference for the legend
  const ohlcData = useRef({ values: [], volumes: [] });

  const isPreviousHistory = useRef(true);
  const isApiInFlight = useRef(false);
  const [initialCalled, setInitialCalled] = useState(false);

  const tokenRef = useRef(null);

  const connectedBroker = useSelector((state) => state.connectedBroker);
  const connectedBrokerRef = useRef({});
  useEffect(() => {
    connectedBrokerRef.current = connectedBroker;
  }, [connectedBroker]);
  const webSocketWorkerRef = useRef(null);

  const subscribeMessage = () => {
    if (connectedBroker.angelOne && tokenRef.current !== null) {
      const message = {
        action: 1,
        params: {
          mode: 3,
          tokenList: [{ exchangeType: exchange==='NSE'?1:3, tokens: [tokenRef.current] }],
        },
      };
      webSocketWorkerRef.current.sendMessage(message);
    }
  };

  const unsubscribeMessage = () => {
    // const
    if (connectedBroker.angelOne) {
      const message = {
        action: 0,
        params: {
          mode: 3,
          tokenList: [{ exchangeType: exchange==='NSE'?1:3, tokens: [tokenRef.current] }],
        },
      };

      // console.log(message);
      webSocketWorkerRef.current.sendMessage(message);
    }
  };

  const detectIntersections = (intma1, intma2) => {
    const intersections = [];
    for (let i = 1; i < intma1.length; i++) {
      const prevMA50 = intma1[i - 1].value;
      const currMA50 = intma1[i].value;
      const prevMA200 = intma2[i - 1].value;
      const currMA200 = intma2[i].value;

      // Check for intersection
      if (prevMA50 < prevMA200 && currMA50 >= currMA200) {
        intersections.push({ time: intma1[i].time, indication: 1 });
      }
      if (prevMA50 > prevMA200 && currMA50 <= currMA200) {
        intersections.push({ time: intma1[i].time, indication: -1 });
      }
    }
    return intersections;
  };

  function calculateMovingAverageSeriesData(candleData, maLength) {
    const maData = [];
    for (let i = 0; i < candleData.length; i++) {
      if (i < maLength) {
        maData.push({ time: candleData[i].time });
      } else {
        let sum = 0;
        for (let j = 0; j < maLength; j++) {
          sum += candleData[i - j].close;
        }
        const maValue = sum / maLength;
        maData.push({ time: candleData[i].time, value: maValue });
      }
    }
    return maData;
  }

  const [enabledSeries, setEnabledSeries] = useState({
    MA50: false,
    MA200: false,
    // MA300: false
  }); // State to track enabled series
  const enabledSeriesRefs = useRef({
    MA50: null,
    MA200: null,
    // MA300: null,
  });
  const multiSeries = {
    GOLD_CROSS: {
      showFunc: () => {
        setEnabledSeries((prev) => {
          const isEnabled = prev.MA50 || prev.MA200; // Check if any of the MAs are enabled
          const newState = { ...prev, MA50: !isEnabled, MA200: !isEnabled }; // Toggle both MA50 and MA200 together
          if (newState.MA50 && newState.MA200) {
            // Enable both MAs
            const ma50Data = calculateMovingAverageSeriesData(ohlcData.current.values, 50);
            const ma200Data = calculateMovingAverageSeriesData(ohlcData.current.values, 200);
            enabledSeriesRefs.current.MA50 = chartRef.current.addLineSeries({
              color: "green",
              lineWidth: 1,
            });
            enabledSeriesRefs.current.MA200 = chartRef.current.addLineSeries({
              color: "orange",
              lineWidth: 1,
            });
            enabledSeriesRefs.current.MA50.setData(ma50Data);
            enabledSeriesRefs.current.MA200.setData(ma200Data);
            let intersections = detectIntersections(ma50Data, ma200Data);
            const markers = intersections.map((val) => ({
              time: val.time,
              position: val.indication === 1 ? "belowBar" : "aboveBar",
              color: val.indication === 1 ? upColor : downColor,
              shape: val.indication === 1 ? "arrowUp" : "arrowDown",
              text: "",
            }));
            enabledSeriesRefs.current.MA200.setMarkers(markers);
          } else {
            // Remove both MAs
            if (enabledSeriesRefs.current.MA50) {
              chartRef.current.removeSeries(enabledSeriesRefs.current.MA50);
              enabledSeriesRefs.current.MA50 = null;
            }
            if (enabledSeriesRefs.current.MA200) {
              chartRef.current.removeSeries(enabledSeriesRefs.current.MA200);
              enabledSeriesRefs.current.MA200 = null;
            }
          }
          return newState;
        });
      },
      color: "goldenrod", // Button color theme set to golden
    },
    // MA300: {
    //   showFunc: () => {
    //     setEnabledSeries((prev) => {
    //       const newState = { ...prev, MA300: !prev.MA300 }; // Toggle both MA50 and MA200 together
    //       if (newState.MA300) {
    //         // Enable both MAs
    //         const ma300Data = calculateMovingAverageSeriesData(ohlcData.values, 300);
    //         enabledSeriesRefs.current.MA300 = chartRef.current.addLineSeries({
    //           color: "red",
    //           lineWidth: 1,
    //         });
    //         enabledSeriesRefs.current.MA300.setData(ma300Data);
    //       } else {
    //         // Remove both MAs
    //           chartRef.current.removeSeries(enabledSeriesRefs.current.MA300);
    //           enabledSeriesRefs.current.MA300 = null;
    //       }
    //       return newState;
    //     });
    //   },
    //   color: "blue", // Button color theme set to golden
    // },
  };

  const splitData = (rawData) => {
    let values = [];
    let volumes = [];
    for (let i = 0; i < rawData.length; i++) {
      values.push({
        time: new Date(rawData[i][0]) / 1000, // Convert to seconds
        open: rawData[i][1],
        high: rawData[i][2],
        low: rawData[i][3],
        close: rawData[i][4],
      });
      volumes.push([new Date(rawData[i][0]) / 1000, rawData[i][5], rawData[i][1] < rawData[i][4] ? 0 : 1]);
    }
    return { values, volumes };
  };

  const formatPrice = (price) => (Math.round(price * 100) / 100).toFixed(2);
  const setTooltipHtml = (name, date, price) => {
    if (legendRef.current) {
      legendRef.current.innerHTML = `<div style="font-size: ${isMobile() ? "16px" : "24px"}; margin: 4px 0px;">${
        isMobile() ? symbol : name
      }</div><div style="font-size: ${isMobile() ? "15px" : "22px"}; margin: 4px 0px;">₹${price}</div><div>${date}</div>`;
    }
  };

  useEffect(() => {
    async function initiate() {
      ohlcData.current = { values: [], volumes: [] };
      dtpvalue.current = [];
      await prependData(true);
      setInitialCalled(true);
    }
    if (!initialCalled) initiate();
  }, [symbol, selectedOption, initialCalled]);

  useEffect(() => {
    setInitialCalled(false);
  }, [symbol]);

  const prependData = async (isInitialCall) => {
    if (isApiInFlight.current) return;
    if (isPreviousHistory.current === false) return;
    isApiInFlight.current = true;
    var dates;
    if (dtpvalue.current.length === 0) dates = getDateRangeAO(options[selectedOption].days);
    else dates = getDateRangeAO(options[selectedOption].days, new Date(dtpvalue.current[0]));
    dtpvalue.current = [dates.from, dates.today];

    return await getCandles(symbol, selectedOption, exchange, dtpvalue.current[0], dtpvalue.current[1])
      .then((response) => {
        const rawData = response.data;
        if (rawData.data.length === 0) {
          isPreviousHistory.current = false;
          isApiInFlight.current = false;
        } else {
          const data = splitData(rawData.data);
          // console.log(data.volumes, ohlcData.current.volumes);
          let toBeNewData = {
            values: [...data.values, ...ohlcData.current.values],
            volumes: [...data.volumes, ...ohlcData.current.volumes],
          };
          ohlcData.current = toBeNewData;
          isApiInFlight.current = false;
          if (!isInitialCall) {
            return toBeNewData;
          }
        }
      })
      .catch((err) => {
        notificationTopup(err.message);
        isApiInFlight.current = false;
      });
  };

  useEffect(() => {
    if (ohlcData.current.values.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { color: "transparent" },
        textColor: themedTextColor[theme],
      },
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: "right",
        vertAlign: "top",
        color: `${themedTextColor[theme]}30`,
        text: "QUIBBLEFROST",
      },
      crossHair: {
        mode: 1,
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      autoSize: true,
    });
    chartRef.current = chart; // Store the chart instance in the ref

    const candlestickSeries = chart.addCandlestickSeries({
      upColor: upColor,
      downColor: downColor,
      borderVisible: false,
      wickUpColor: upColor,
      wickDownColor: downColor,
    });

    const volumeSeries = chart.addHistogramSeries({
      priceFormat: { type: "volume" },
      priceScaleId: "", // overlay on the same chart
    });

    volumeSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7, // Position the volume at the bottom 30% of the chart
        bottom: 0,
      },
    });

    const updateLegend = (param) => {
      const validCrosshairPoint = !(
        param === undefined ||
        param.time === undefined ||
        param.point.x < 0 ||
        param.point.y < 0
      );
      const bar = validCrosshairPoint ? param.seriesData.get(candlestickSeries) : null;
      const time = bar ? bar.time : ohlcData.current.values[ohlcData.current.values.length - 1].time;
      const price = bar
        ? bar.value !== undefined
          ? bar.value
          : bar.close
        : ohlcData.current.values[ohlcData.current.values.length - 1].close;
      const formattedPrice = formatPrice(price);
      const newDatetime = new Date(time * 1000);
      setTooltipHtml(
        name,
        `${newDatetime.getDate()}/${newDatetime.getMonth() + 1}/${newDatetime.getFullYear()}`,
        formattedPrice
      );
    };

    // Create the legend element
    const legend = document.createElement("div");
    legend.style = `position: absolute; left: 12px; top: 4px; z-index: 1; font-size: 14px; line-height: 18px; font-weight: 700;`;
    legend.style.color = themedTextColor[theme];
    chartContainerRef.current.appendChild(legend);
    legendRef.current = legend; // Assign the legend to the ref

    candlestickSeries.setData(ohlcData.current.values);
    let lastCandle = ohlcData.current.values[ohlcData.current.values.length - 1];
    ohlcLiveCandleWithTimeHolderRef.current[lastCandle.time] = {
      time: lastCandle.time,
      open: lastCandle.open,
      high: lastCandle.high,
      low: lastCandle.low,
      close: lastCandle.close,
    };

    // Map the volumes for the histogram
    if (isEq) {
      // console.log(ohlcData.current);
      const volumeData = ohlcData.current.values.map((candle, index) => ({
        time: candle.time,
        value: ohlcData.current.volumes[index][1],
        color: ohlcData.current.volumes[index][2] === 0 ? upColor : downColor,
      }));
      volumeSeries.setData(volumeData);
      // console.log(volumeData);
    }

    chart.subscribeCrosshairMove(updateLegend);
    updateLegend(undefined);

    multiSeries.GOLD_CROSS.showFunc();

    chart.timeScale().subscribeVisibleLogicalRangeChange(async (logicalRange) => {
      if (logicalRange.from < 1) {
        let newData = await prependData(false);
        if (newData) {
          candlestickSeries.setData(newData.values);
          if (isEq) {
            // console.log(ohlcData.current);
            const volumeData = ohlcData.current.values.map((candle, index) => ({
              time: candle.time,
              value: ohlcData.current.volumes[index][1],
              color: ohlcData.current.volumes[index][2] === 0 ? upColor : downColor,
            }));
            // console.log(volumeData);
            volumeSeries.setData(volumeData);
          }

          multiSeries.GOLD_CROSS.showFunc();

          multiSeries.GOLD_CROSS.showFunc();
        }
      }
    });

    if (connectedBroker.angelOne) {
      webSocketWorkerRef.current = new AngelOneWebSocketSmartStream();

      const waitForWebSocketConnection = () =>
        new Promise((resolve, reject) => {
          webSocketWorkerRef.current.onopen = () => resolve(); // Resolve when the connection opens
          webSocketWorkerRef.current.onerror = (err) => reject(err); // Reject on error
        });

      webSocketWorkerRef.current.connect();

      const handleMessage = (data) => {
        const liveData = {
          ltp: data.last_traded_price,
          volume: data.volume_traded_for_the_day,
          open: data.open_price_of_the_day,
          high: data.high_price_of_the_day,
          low: data.low_price_of_the_day,
          orderBook: data.best_five_data,
          ltq: data.last_traded_quantity,
          avgtp: data.average_traded_price,
          ts: data.exchange_timestamp, // in ms 1000
          prevClose: data.close_price,
        };
        let timeForUpdate = getBaseTimeForUnit(options[selectedOption].label, liveData.ts);

        let newVol = volumeForCurrentUnitCandle(options[selectedOption].label, ohlcData.current.volumes, liveData.volume);
        let lengthOfvolumnes = ohlcData.current.volumes.length;
        // console.log(ohlcData.current.volumes[lengthOfvolumnes - 1], lengthOfvolumnes, newVol, timeForUpdate);
        if (ohlcData.current.volumes[lengthOfvolumnes - 1][0] === timeForUpdate) {
          ohlcData.current.volumes[lengthOfvolumnes - 1] = [timeForUpdate, newVol, -1];
        } else {
          ohlcData.current.volumes.push([timeForUpdate, newVol, -1]);
        }
        handleOhlcLive(timeForUpdate, liveData.ltp / 100, newVol);

        if (ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate]) {
          let newVals = {
            time: ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].time,
            open: ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].open,
            close: ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].close,
            high: ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].high,
            low: ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].low,
          };
          candlestickSeries.update(newVals);
          if (isEq) {
            volumeSeries.update({
              time: ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].time,
              value: ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].volume,
              color:
                ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].open <
                ohlcLiveCandleWithTimeHolderRef.current[timeForUpdate].close
                  ? upColor
                  : downColor,
            });
          }
          multiSeries.GOLD_CROSS.showFunc();
          multiSeries.GOLD_CROSS.showFunc();
        }
        settingLiveOrderBook(liveData.orderBook);
        livePriceReflector({ ltp: liveData.ltp / 100, close: liveData.prevClose / 100 });
      };

      webSocketWorkerRef.current.addListener(handleMessage);

      // Wait for the WebSocket connection before proceeding
      waitForWebSocketConnection()
        .then(() => {
          if (!isEq) {
            tokenRef.current = indexSymbolName[symbol.toLowerCase()]?.token;
            subscribeMessage();
          } else {
            getTokenForSymbolA1(symbol, exchange)
              .then((res) => {
                if (res.data.data.length > 0) {
                  tokenRef.current = res.data.data[0].symboltoken;
                  subscribeMessage(); // Safely call after WebSocket is ready
                }
              })
              .catch((err) => {
                console.error("Error fetching token:", err);
              });
          }
        })
        .catch((err) => {
          console.error("WebSocket connection error:", err);
        });
    }

    return () => {
      setEnabledSeries((prev) => {
        return Object.keys(prev).reduce((acc, key) => {
          acc[key] = false; // Set each series to false
          return acc;
        }, {});
      });
      legend.remove();
      chart.remove();
      if (connectedBroker.angelOne) {
        unsubscribeMessage();
        webSocketWorkerRef.current.disconnect();
      }
    };
  }, [theme, initialCalled]);

  return (
    <div className="pt-1">
      <div className="ps-2">
        {Object.keys(options).map((val, key) => (
          <span
            onClick={() => {
              setSelectedOption(val);
              setInitialCalled(false);
              isPreviousHistory.current = true;
            }}
            className={"div-pill rounded px-2 " + (selectedOption === val ? "our-border" : "")}
            key={`QF_EQ_CHARTPREVIEW_${key}`}
            style={{
              fontSize: "var(--fnt-h6)",
              cursor: "pointer", // Change cursor to pointer for better UX
            }}
          >
            {options[val].label}
          </span>
        ))}
      </div>
      <div className="ps-2 mt-1">
        {Object.keys(multiSeries).map((val, key) => (
          <span
            onClick={() => {
              multiSeries[val].showFunc();
            }}
            className="div-pill rounded px-2 me-1"
            key={`QF_EQ_CHARTPREVIEW_${key}`}
            style={{
              fontSize: "var(--fnt-h6)",
              cursor: "pointer",
              ...((val === "GOLD_CROSS" && enabledSeries.MA50 && enabledSeries.MA200) || enabledSeries[val] // Check if both MAs are active
                ? {
                    backgroundColor: multiSeries[val].color, // Golden background when active
                    color: themedTextColor.light,
                  }
                : {
                    color: multiSeries[val].color, // Golden text when inactive
                  }),
            }}
          >
            {val.replaceAll("_", " ")}
          </span>
        ))}
      </div>
      <div
        ref={chartContainerRef}
        style={{
          width: "100%",
          height: isMobile() ? "300px" : "500px",
          position: "relative",
        }}
      />
    </div>
  );
};

export default VisPreviewChart;
