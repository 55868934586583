import { commonApiGet } from "./api";
import { formatDateAO } from "./configs";

export const prepareCyptoData = (newData, currData, type = "ticker") => {
  if (type === "ticker") {
    return {
      newData,
      id: "Price" + (newData.symbol || currData.symbol || null),
      symbol: newData.symbol || currData.symbol || null,
      markPrice: formatCurrency(parseFloat(newData.mark_price || currData.markPrice || null), "USD", false),
      inr: {
        spotPrice: formatCurrency(parseFloat(newData.spot_price || currData.usd?.spotPrice || null) * 85, "INR", false),
        volume: formatCurrency(parseFloat(newData.turnover_usd || currData.usd?.volume || null) * 85, "INR"),
        oi: formatCurrency(parseFloat(newData.oi_value_usd || currData.usd?.oi || null) * 85, "INR"),
        oiChange: formatCurrency(parseFloat(newData.oi_change_usd_6h || currData.usd?.oiChange || null) * 85, "INR"),
      },
      usd: {
        spotPrice: formatCurrency(parseFloat(newData.spot_price || currData.usd?.spotPrice || null), "USD", false),
        volume: formatCurrency(parseFloat(newData.turnover_usd || currData.usd?.volume || null), "USD"),
        oi: formatCurrency(parseFloat(newData.oi_value_usd || currData.usd?.oi || null), "USD"),
        oiChange: formatCurrency(parseFloat(newData.oi_change_usd_6h || currData.usd?.oiChange || null), "USD"),
      },
      quotes: {
        ask_size: newData.quotes?.ask_size || currData.quotes?.ask_size || null,
        best_ask: newData.quotes?.best_ask || currData.quotes?.best_ask || null,
        best_bid: newData.quotes?.best_bid || currData.quotes?.best_bid || null,
        bid_size: newData.quotes?.bid_size || currData.quotes?.bid_size || null,
        mark_iv: newData.quotes?.mark_iv || currData.quotes?.mark_iv || null,
      },
      funding_rate: newData.funding_rate || currData.funding_rate || null,
      mark_change_24h: newData.mark_change_24h || currData.mark_change_24h || null,
      open: newData.open || currData.open || null,
      high: newData.high || currData.high || null,
      low: newData.low || currData.low || null,
      close: newData.close || currData.close || null,
      price_band: {
        lower_limit: newData.price_band?.lower_limit || currData.price_band?.lower_limit || null,
        upper_limit: newData.price_band?.upper_limit || currData.price_band?.upper_limit || null,
      },
      incDec: {
        newSpot: newData.spot_price || 0,
        oldSpot: parseFloat((currData.usd?.spotPrice || "").replace("$", "").replace(",", "")) || 0,
      },
    };
  }
  if (type === "spot") {
    return {
      ...currData,
      inr: {
        ...currData.inr,
        spotPrice: formatCurrency(parseFloat(newData.p || currData.usd?.spotPrice || null) * 85, "INR", false),
      },
      usd: {
        ...currData.usd,
        spotPrice: formatCurrency(parseFloat(newData.p || currData.usd?.spotPrice || null), "USD", false),
      },
      incDec: {
        newSpot: newData.p || 0,
        oldSpot: parseFloat((currData.usd?.spotPrice || "").replace("$", "").replace(",", "")) || 0,
      },
    };
  }
};

export function formatCurrency(amount, currency, grouped = true) {
  if (isNaN(amount)) {
    return null;
  }
  let formattedAmount = "";

  formattedAmount = parseFloat(amount).toLocaleString(currency === "USD" ? "en-US" : "en-IN", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  let output = grouped
    ? currency === "USD"
      ? groupUSDCurrency(formattedAmount)
      : groupINRCurrency(formattedAmount)
    : formattedAmount;
  return output;
}

function groupUSDCurrency(formattedAmount) {
  if (formattedAmount === undefined) return "N/A";
  let isMinus = formattedAmount[0] == "-" ? "-" : "";
  // Extract numeric part from formatted amount
  const numericPart = parseFloat(formattedAmount.replace(/[^\d.]/g, ""));

  // Determine the suffix based on the numeric part
  let suffix = "";
  let value = numericPart;

  if (numericPart >= 1e9) {
    value = (numericPart / 1e9).toFixed(2);
    suffix = "B";
  } else if (numericPart >= 1e6) {
    value = (numericPart / 1e6).toFixed(2);
    suffix = "M";
  } else if (numericPart >= 1e3) {
    value = (numericPart / 1e3).toFixed(2);
    suffix = "K";
  }

  // Combine with currency symbol
  return `${isMinus}$${value}${suffix}`;
}

function groupINRCurrency(formattedAmount) {
  if (formattedAmount === undefined) return "N/A";
  let isMinus = formattedAmount[0] == "-" ? "-" : "";
  const numericPart = parseFloat(formattedAmount.replace(/[^\d.]/g, ""));

  // Determine the suffix and format based on the numeric part
  let suffix = "";
  let value = numericPart;

  if (numericPart >= 1e7) {
    // Crore
    value = (numericPart / 1e7).toFixed(2);
    suffix = "Cr";
  } else if (numericPart >= 1e5) {
    // Lakh
    value = (numericPart / 1e5).toFixed(2);
    suffix = "L";
  } else if (numericPart >= 1e3) {
    // Thousand
    value = (numericPart / 1e3).toFixed(2);
    suffix = "K";
  }

  // Combine with currency symbol
  return `${isMinus}₹${value}${suffix}`;
}

export function groupINRCurrencyNSE(amount, isINR = false) {
  if (isNaN(amount)) {
    return 0;
  }
  let formattedAmount = "";

  formattedAmount = parseFloat(amount).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (formattedAmount === undefined) return "N/A";
  let isMinus = formattedAmount[0] == "-" ? "-" : "";
  const numericPart = parseFloat(formattedAmount.replace(/[^\d.]/g, ""));

  // Determine the suffix and format based on the numeric part
  let suffix = "";
  let value = numericPart;

  if (numericPart >= 1e7) {
    // Crore
    value = (numericPart / 1e7).toFixed(2);
    suffix = "Cr";
  } else if (numericPart >= 1e5) {
    // Lakh
    value = (numericPart / 1e5).toFixed(2);
    suffix = "L";
  } else if (numericPart >= 1e3) {
    // Thousand
    value = (numericPart / 1e3).toFixed(2);
    suffix = "K";
  }

  // Combine with currency symbol
  let op = `${isMinus}${isINR ? "₹" : ""}${value}${suffix}`;
  return op === "NaN" ? 0 : op;
}

export const searchTerm = async (symbol) => {
  if (symbol === undefined) return;
  return await commonApiGet(`/analysis/search_symbol?search=${symbol}`).then((res) => {
    if (res.data.error !== undefined) {
      throw error(res.data.error);
    } else {
      return res.data;
    }
  });
};

export const getCandles = async (symbol, interval, exchange, from, to) => {
  if (symbol === undefined) return;
  return await commonApiGet(
    `/stocks/getCandles?symbol=${encodeURIComponent(
      symbol
    )}&interval=${interval}&exchange=${exchange}&fromdate=${formatDateAO(from)}&todate=${formatDateAO(to)}`,
    "EQUITY_PAGE_CHART_PREV_VIS",
    false,
    false
  );
};
