import React, { useEffect, useState, useRef, useCallback } from "react";
import CommonCardView from "../vision/CommonCardView";
import QFLoader from "../vision/QFLoader";
import VisOops from "../vision/VisOops";
import { commonApiGet } from "../../utils/api";
import VisTable from "../vision/VisTable";
import ChartPatternsLineArea from "./ChartComponents/ChartPatternsLineArea";

const ChartPatterns = () => {
  const [chartPatternData, setChartPatternData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const containerRef = useRef(null);

  // Fetch data for the current page
  const fetchChartPatterns = useCallback(async (page) => {
    setIsLoading(true);
    try {
      const res = await commonApiGet(`/analysis/getpatterns?page=${page}`);
      const newData = res.data;

      setChartPatternData((prevData) => [...prevData, ...newData]);

      // Check if the data returned is less than the page limit (e.g., 10 rows per page)
      if (newData.length < 10 || page === 5) {
        setIsLastPage(true);
      }

      setIsError(false);
    } catch (err) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch initial data
  useEffect(() => {
    fetchChartPatterns(currentPage);
  }, [fetchChartPatterns, currentPage]);

  // Prepare data for the table
  useEffect(() => {
    if (chartPatternData.length > 0) {
      setDataTable({
        columns: [
          { field: "name", label: "Name" },
          { field: "chartPatternTypeName", label: "Pattern" },
          { field: "chart", label: "Pattern" },
          { field: "PricePoint", label: "Formed @" },
          { field: "CandlesCount", label: "Candles" },
          { field: "DaysFromNow", label: "Formed" },
          { field: "TimePeriod", label: "Period" },
        ],
        rows: chartPatternData.map((val) => ({
          name: val.Name,
          chartPatternTypeName: val.ChartPatternTypeName,
          chart: <ChartPatternsLineArea data={val.ChartData} />,
          PricePoint: `₹${val.PricePoint}`,
          CandlesCount: val.CandlesCount,
          DaysFromNow: `${val.DaysFromNow} days ago`,
          TimePeriod: val.TimePeriod,
        })),
      });
    }
  }, [chartPatternData]);

  // Handle scroll event to load more data
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading && !isLastPage) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  // Add scroll event listener
  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    if (containerRef.current && containerRef.current.scrollHeight <= containerRef.current.clientHeight) {
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading, isLastPage]);

  return (
    <CommonCardView
      id="commonCard_ChartPatterns"
      header="Chart Patterns"
      headerInfo={{
        show: true,
        desc: "Chart patterns are graphical representations of price movements in financial markets, used to identify trends, predict future price movements, and inform investment decisions.",
        id: "chart-patterns",
      }}
      toWatermark={true}
      style={{
        position: "relative",
      }}
    >
      {isError ? (
        <VisOops />
      ) : (
        <div
          ref={containerRef}
          style={{
            maxHeight: "400px",
            overflowY: "scroll",
            overflowX: 'hidden',
            position: "relative",
          }}
        >
          {dataTable.rows.length > 0 && <VisTable data={dataTable} recsPerPage={"All"} />}
          {isLoading && <QFLoader />}
        </div>
      )}
    </CommonCardView>
  );
};

export default ChartPatterns;
