import React from "react";
import ReactECharts from "echarts-for-react";
import { chartColor, mathSum } from "../../../utils/configs";

const SmallLineChart = ({ data }) => {
  let summation = mathSum(data);
  // Define the chart options
  const getOption = () => ({
    tooltip: {
      show: false, // Disable tooltip
    },
    xAxis: {
      type: "category",
      show: false, // Hide x-axis
    },
    yAxis: {
      type: "value",
      show: false, // Hide y-axis
    },
    grid: {
      left: "0%",
      right: "0%",
    },
    series: [
      {
        data: data,
        type: "line",
        smooth: true,
        lineStyle: {
          width: 1, // Set line width
          color: data[data.length - 1] > 0 ? chartColor.green : chartColor.red, // Default line color
        },
        itemStyle: {
          color: "transparent", // No fill for points
        },
        symbol: "none", // Remove data point circles
        areaStyle: {
          color: data[data.length - 1] > 0 ? chartColor.opacGreenMore : chartColor.opacRedMore, // Set area fill color (light gray)
        },
      },
    ],
  });

  return (
    <div style={{ width: "100px", height: "70px" }}>
      <ReactECharts option={getOption()} style={{ height: "100%", width: "100%" }} />
    </div>
  );
};

export default SmallLineChart;
