import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

const VisDropDown = ({
  selected,
  mapping = undefined,
  setter,
  list = undefined,
  isMultiple = false,
  className = "",
  formattor = (val) => val,
}) => {
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const ddootoggle = () => setDropdownOptionOpen((prevState) => !prevState);
  return (
    <ButtonDropdown
      isOpen={dropdownOptionOpen}
      toggle={ddootoggle}
      className={`tab-weight ${!isMultiple && "absolute-tabGrp"} ${className}`}
    >
      <DropdownToggle
        caret
        size="sm"
        color="transparent"
        className="general-btn our-border text-color-themed mt-1"
        style={{
          fontSize: "12px",
        }}
      >
        {formattor(selected)}
      </DropdownToggle>
      <DropdownMenu
        className="general-btn our-border non-trans-bg"
        style={{
          maxHeight: "220px",
          overflowY: "scroll",
        }}
      >
        {(list || Object.keys(mapping)).map((val, key) => (
          <DropdownItem
            className="bg-set-on-active text-color-themed"
            key={key}
            onClick={() => {
              setter(val);
            }}
            style={{
              fontSize: "12px",
            }}
            disabled={(mapping ? mapping[val] : val) === selected ? true : false}
          >
            {formattor(mapping ? mapping[val] : val)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export const VisDropDownInlineTransparent = ({
  selected,
  mapping = undefined,
  setter,
  list = undefined,
  className = "",
}) => {
  const theme = useSelector((state) => state.theme);
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const ddootoggle = () => setDropdownOptionOpen((prevState) => !prevState);
  return (
    <ButtonDropdown isOpen={dropdownOptionOpen} toggle={ddootoggle} className={`${className}`}>
      <DropdownToggle
        caret
        size="sm"
        color="transparent"
        className=" text-color-themed py-0 px-1"
        style={{ fontSize: "var(--fnt-comm)" }}
      >
        {selected}
      </DropdownToggle>
      <DropdownMenu
        style={{
          maxHeight: "220px",
          overflowY: "scroll",
          zIndex: 2,
          backgroundColor: theme === "dark" ? "rgba(0,0,0,0.8)" : "rgba(255,255,255,0.8)",
          fontWeight: "700",
          maxWidth: "100px",
          minWidth: "unset !important",
          // backdropFilter: "blur(20px) !important",
          // willChange: 'backdropFilter'
        }}
      >
        {(list || Object.keys(mapping)).map((val, key) => (
          <DropdownItem
            className="bg-set-on-active text-color-themed"
            key={key}
            onClick={() => {
              setter(val);
            }}
            style={{ fontSize: "var(--fnt-small)" }}
            disabled={(mapping ? mapping[val] : val) === selected ? true : false}
          >
            {mapping ? mapping[val] : val}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default VisDropDown;
