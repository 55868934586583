import React, { useCallback, useEffect, useState } from "react";
import { commonApiGet } from "../../../utils/api";
import { groupINRCurrencyNSE } from "../../../utils/functions";
import { chartColor } from "../../../utils/configs";
import VisOops from "../../vision/VisOops";
import QFLoader from "../../vision/QFLoader";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { VisDropDownInlineTransparent } from "../../vision/VisDropDown";

const FiiDiiPageCMSebi = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [maxVal, setMaxVal] = useState(0);

  const [selectedOption, setSelectedOption] = useState("Day");

  const assetTypes = {
    Equity: 1,
    Debt: 2,
    "Equity + Debt": 3,
  };
  const marketType = {
    "Primary Market": 1,
    "Secondary Market": 2,
    "Both Market": 3,
  };

  const [selectedOptionA, setSelectedOptionA] = useState("Equity");
  const [selectedOptionM, setSelectedOptionM] = useState("Primary Market");

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/analysis/fii/sebi?timespan=${selectedOption[0]}&asset=${assetTypes[selectedOptionA]}&market=${marketType[selectedOptionM]}`,
        "FII_DII_CM_Sebi"
      )
        .then((res) => {
          setData(res.data);
          setLoading(false);
          setError(false);
          setMaxVal(Math.max(...res.data.map((item) => Math.abs(item.NetValue))));
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          notificationTopup(err.message);
        });
    })();
  }, [selectedOption, selectedOptionA, selectedOptionM]);
  return (
    <div className="w-100 h-100">
      {loading ? (
        <QFLoader />
      ) : error ? (
        <VisOops />
      ) : (
        <table className="w-100">
          <thead className="section-bb">
            <tr className="non-trans-bg">
              <td>
                <VisDropDownInlineTransparent
                  selected={selectedOption}
                  setter={(val) => {
                    setSelectedOption(val);
                    setLoading(true);
                  }}
                  list={["Day", "Month", "Year"]}
                />
              </td>
              <td>Net Value</td>
              <td className="w-50 text-center">
                <VisDropDownInlineTransparent
                  selected={selectedOptionA}
                  setter={(val) => {
                    setSelectedOptionA(val);
                    setLoading(true);
                  }}
                  list={Object.keys(assetTypes)}
                />
                <VisDropDownInlineTransparent
                  selected={selectedOptionM}
                  setter={(val) => {
                    setSelectedOptionM(val);
                    setLoading(true);
                  }}
                  list={Object.keys(marketType)}
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.DateText}</td>
                <td
                  style={{
                    color: item.NetValue < 0 ? chartColor.red : chartColor.green,
                  }}
                >
                  {groupINRCurrencyNSE(item.NetValue, true)}Cr.
                </td>
                <td>
                  <div className="row py-2">
                    <div className="col-6 p-0 m-0 text-end vert-center">
                      {item.NetValue < 0 ? (
                        <span
                          className=" my-1 align-self-end"
                          style={{
                            width: `${(Math.abs(item.NetValue) / maxVal) * 100}%`,
                            backgroundColor: chartColor.red,
                            height: "10px",
                          }}
                        ></span>
                      ) : null}
                    </div>
                    <div className="col-6 section-bl p-0 m-0 vert-center">
                      {item.NetValue > 0 ? (
                        <span
                          className="my-1"
                          style={{
                            width: `${(Math.abs(item.NetValue) / maxVal) * 100}%`,
                            backgroundColor: chartColor.green,
                            height: "10px",
                          }}
                        ></span>
                      ) : null}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FiiDiiPageCMSebi;
