import React from "react";
import Navbar from "../../../components/Navbar";
import Divider from "./Divider";
import Footer from "./Footer";

export const privacyPolicy = (
  <>
    <h2>Privacy Policy</h2>
    <p>
      Effective Date: 12-Oct-2024 | Dushera
      <br />
      Last Updated: 12-Oct-2024 | Dushera
    </p>
    <ol>
      <li>
        <strong>Introduction</strong>
        <p className="m-0">
          Welcome to Quibblefrost (“Company”, “we”, “our”, “us”). We operate the
          Quibblefrost platform, which provides users with real-time stock data,
          market insights, and analytics tools.
          <br />
          This Privacy Policy explains how we collect, use, share, and protect
          information about our users (“you”, “your”) and your rights concerning
          your data in accordance with the applicable laws, including the
          Information Technology Act, 2000 and the rules made thereunder.
        </p>
      </li>
      <li>
        <strong>Information We Collect</strong>
        <p className="m-0">
          We may collect the following types of information when you use our
          platform:
        </p>
        <ul>
          <li>
            Personal Information
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>User preferences</li>
              <li>Account login credentials</li>
            </ul>
          </li>
          <li>
            Financial Data
            <ul>
              <li>Investment preferences</li>
              <li>Portfolio data (optional)</li>
              <li>Transaction history (optional)</li>
            </ul>
          </li>
          <li>
            Usage Data
            <ul>
              <li>Device information (IP address, device ID, browser type)</li>
              <li>Usage data (log-in times, frequency of use)</li>
              <li>
                Cookies: We use cookies to enhance your experience and analyze
                how you use our platform.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <strong>How We Use Your Information</strong>
        <ul>
          <li>
            To provide services: Real-time stock updates, market insights, and
            analytics.
          </li>
          <li>
            To improve the platform: Analyze usage patterns to improve
            functionality.
          </li>
          <li>
            To send updates: Notify you of changes, updates, or promotional
            offers related to the platform.
          </li>
          <li>
            To comply with legal obligations: Follow applicable laws and
            regulations.
          </li>
        </ul>
      </li>
      <li>
        <strong>Data Sharing and Disclosure</strong>
        <p className="m-0">We may share your personal information with:</p>
        <ul>
          <li>
            Service Providers: Third-party service providers who assist in
            delivering our services (e.g., hosting providers, analytics
            services).
          </li>
          <li>
            Legal Compliance: If required by law or governmental authority.
          </li>
          <li>
            Business Transfers: In case of mergers, acquisitions, or asset
            sales, your information may be transferred.
          </li>
        </ul>
        <p className="m-0">
          We do not sell or rent your personal information to third parties.
        </p>
      </li>
      <li>
        <strong>Data Security</strong>
        <p className="m-0">
          We use reasonable administrative, technical, and physical measures to
          protect your personal information. However, no method of transmission
          over the Internet is 100% secure. We cannot guarantee absolute
          security but strive to protect your data to the best of our ability.
        </p>
      </li>
      <li>
        <strong>Your Rights</strong>
        <p className="m-0">
          Under applicable law, you have the following rights regarding your
          personal information:
        </p>
        <ul>
          <li>
            Access and Correction: You can access and correct your personal data
            by logging into your account.
          </li>
          <li>
            Data Deletion: You may request the deletion of your personal data,
            subject to our legal obligations.
          </li>
          <li>
            Opt-Out: You may opt out of receiving marketing emails or
            notifications from us at any time.
          </li>
        </ul>
      </li>
      <li>
        <strong>Children’s Privacy</strong>
        <p className="m-0">
          Our services are not intended for use by individuals under the age of
          18. We do not knowingly collect personal data from minors.
        </p>
      </li>
      <li>
        <strong>Changes to this Privacy Policy</strong>
        <p className="m-0">
          We may update this policy from time to time. Any changes will be
          posted on this page with an updated effective date.
        </p>
      </li>
      <li>
        <strong>Contact Us</strong>
        <p className="m-0">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </p>
        <ul>
          <li>Email: ping@quibblefrost.in</li>
        </ul>
      </li>
    </ol>
  </>
);

const PrivacyPolicy = () => {
  return <div className="col-12 p-4">{privacyPolicy}</div>;
};

export default PrivacyPolicy;
