import React, { useEffect, useRef, useState } from "react";
import CommonCardView from "../vision/CommonCardView";
import { commonApiGet } from "../../utils/api";
import VisOops from "../vision/VisOops";
import VisTable from "../vision/VisTable";
import QFLoader from "../vision/QFLoader";
import { formatCurrency } from "../../utils/functions";
import PieChart from "./ChartComponents/PieChart";
import { Link, useNavigate, useParams } from "react-router-dom";
import { urlsFE } from "../../views/pageroutes";
import Divider from "../../views/preLogin/components/Divider";

const SectoralPage = () => {
  const selectedSector = useParams().sectorName;
  const n = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [dataTableMain, setDataTableMain] = useState({ columns: [], rows: [] });
  const [mainData, setMainData] = useState([]);
  const [sectorsMapping, setSectorsMapping] = useState({});

  const [loaderForSector, setLoaderForSector] = useState(true);
  const [errorForSector, setErrorForSector] = useState(false);
  const [sectorData, setSectorData] = useState([]);
  const [sectorDataForTable, setSectorDataForTable] = useState({ columns: [], rows: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/market/allsectors`)
        .then((res) => {
          setMainData(res.data);
        })
        .catch((err) => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  }, []);

  useEffect(() => {
    if (mainData.length > 0) {
      setSectorsMapping(
        mainData.reduce((acc, val) => {
          acc[val.Slug] = {
            Name: val.Name,
            ID: val.ID,
            IndustriesForSector: val.IndustriesForSector,
          };
          return acc;
        }, {})
      );

      setDataTableMain({
        columns: [
          { field: "name", label: "Name" },
          { field: "IndustriesCount", label: "# Industries" },
          { field: "StocksCount", label: "# Stocks" },
          { field: "MarketCap", label: "Market Cap" },
          { field: "PerChange", label: "Change" },
          { field: "Industries", label: "Industries" },

          { field: "hidden1", label: undefined },
          { field: "hidden2", label: undefined },
        ],
        rows: mainData.map((val) => ({
          name: (
            <Link
              className="link-to-comp" // Add the class name here
              to={`/sectors/${val.Slug}`}
            >
              {val.Name}
            </Link>
          ),
          IndustriesCount: val.IndustriesCount,
          StocksCount: val.StocksCount,
          MarketCap: `${formatCurrency(val.Mcap, "INR")}Cr.`,
          PerChange: `${val.McapZG.toFixed(2)}%`,
          Industries: (
            <div
              style={{
                maxWidth: "200px",
              }}
            >
              <PieChart
                data={val.IndustriesForSector.map((item) => ({
                  value: item.StocksCount,
                  name: item.Name,
                  mcap: item.Mcap,
                }))}
              />
            </div>
          ),

          hidden1: val.IndustriesForSector.map((item) => item.Name).join(" | "),
          hidden2: val.Name,
        })),
      });
    }
  }, [mainData]);

  useEffect(() => {
    if (Object.keys(sectorsMapping).length > 0 && selectedSector && !isLastPage) {
      (async () => {
        await commonApiGet(`/market/sectorDetails/${sectorsMapping[selectedSector].ID}?page=${currentPage}`)
          .then((res) => {
            setSectorData((prevData) => [...prevData, ...res.data]);
            if (res.data.length < 10) {
              setIsLastPage(true);
            }
          })
          .catch((err) => {
            setErrorForSector(true);
          })
          .finally(() => {
            setLoaderForSector(false);
          });
      })();
    }
  }, [sectorsMapping, selectedSector, currentPage]);

  useEffect(() => {
    setSectorDataForTable({
      columns: [
        { field: "name", label: "Name" },
        { field: "MCAP", label: "Market Cap" },
        { field: "LTP", label: "LTP" },
      ],
      rows: sectorData.map((val) => ({
        name: val.Name,
        MCAP: `${formatCurrency(val.MCAP, "INR")}Cr.`,
        LTP: `₹${val.C} (${val.CZG}%)`,
      })),
    });
  }, [sectorData]);

  // Handle scroll event to load more data
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !isLastPage) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  // Add scroll event listener
  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    if (containerRef.current && containerRef.current.scrollHeight <= containerRef.current.clientHeight) {
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLastPage, containerRef.current]);

  return (
    <CommonCardView
      id="commonCard_allSectors"
      header={sectorsMapping[selectedSector]?.Name || "All Sectors"}
      headerAlign={!selectedSector ? "left" : "center"}
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: "A stock market sector is a category of companies grouped by similar business activities or industries, like technology or healthcare.",
        id: "all-sectors",
      }}
      responsiveHeight={true}
    >
      <div
        style={{
          minHeight: "300px",
        }}
        className="vert-center"
      >
        {isLoading ? (
          <QFLoader />
        ) : isError ? (
          <VisOops />
        ) : selectedSector ? (
          loaderForSector ? (
            <QFLoader />
          ) : errorForSector ? (
            <VisOops />
          ) : (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <Divider role="text" py={0} text="Industries" />
                  <div className="row">
                    <div className="col-sm-6 text-center">
                      <PieChart
                        data={sectorsMapping[selectedSector].IndustriesForSector.map((item) => ({
                          value: item.StocksCount,
                          name: item.Name,
                          mcap: item.Mcap,
                        }))}
                      />{" "}
                      <br /> Based Stock Count
                    </div>
                    <div className="col-sm-6 text-center">
                      <PieChart
                        data={sectorsMapping[selectedSector].IndustriesForSector.map((item) => ({
                          value: item.Mcap,
                          name: item.Name,
                          stocks: item.StocksCount,
                        }))}
                      />
                      <br /> Based Market CAP
                    </div>
                  </div>

                  <VisTable
                    data={{
                      columns: [
                        { field: "name", label: "Name" },
                        { field: "MCAP", label: "MCAP" },
                        { field: "StocksCount", label: "# Stocks" },
                        { field: "Change", label: "Change" },
                      ],
                      rows: sectorsMapping[selectedSector].IndustriesForSector.map((val) => ({
                        name: val.Name,
                        MCAP: `${formatCurrency(val.Mcap, "INR")}Cr.`,
                        StocksCount: val.StocksCount,
                        Change: `${val.McapZG.toFixed(2)}%`,
                      })),
                    }}
                    recsPerPage={"All"}
                  />
                </div>
                <div
                  className="col-md-6 scrbar-vr-available"
                  ref={containerRef}
                  style={{
                    maxHeight: "600px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    position: "relative",
                  }}
                >
                  <Divider role="text" py={0} text="All Stocks" />
                  <VisTable data={sectorDataForTable} recsPerPage={"All"} />
                </div>
              </div>

              <i
                className="div-pill general-card-1 our-border fas fa-caret-left px-3 py-1"
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSectorData([]);
                  setCurrentPage(1);
                  setIsLastPage(false);
                  setSectorDataForTable([]);
                  n(urlsFE.sectors);
                }}
              />
            </div>
          )
        ) : (
          <VisTable data={dataTableMain} recsPerPage={"All"} />
        )}
      </div>
    </CommonCardView>
  );
};

export default SectoralPage;
