import React, { useEffect, useState } from "react";
import derivativesStore, { setSBOptionLegs } from "../derivatives-store";

const GetOpenPositionFor = ({ strike, type, exp, lotsize }) => {
  const [updatedStore, setUpdatedStore] = useState(derivativesStore.getState());
  useEffect(() => {
    const unsubscribe = derivativesStore.subscribe(() => {
      setUpdatedStore(derivativesStore.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <div className="row">
      {updatedStore.optionLegs.map((opl, index) => {
        if (
          opl.strikePrice == strike &&
          opl.optionType == type &&
          opl.expiryDate == exp
        ) {
          return (
            <React.Fragment key={index}>
              <div className="col-8 text-start vert-center">
                {`Available: ${opl.action == "B" ? "+" : "-"}${
                  opl.lots
                } x${lotsize}`}
              </div>
              <div className="col-4 text-end">
                <i
                  title="Square-Off Position"
                  type="button"
                  className="fas fa-trash text-danger p-2"
                  onClick={() => {
                    derivativesStore.dispatch(
                      setSBOptionLegs({
                        type: "delete",
                        optionLegIndex: index,
                      })
                    );
                  }}
                />
              </div>
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

export default GetOpenPositionFor;
