import React, { Component } from "react";

import store from "../utils/glob-redux/store";
import AppRoutes from "./AppRoutes";
import { Provider } from "react-redux";
import { AddtowlHelper } from "../components/vision/toolbox/VisAddToWatchlist";
import BottomIcons from "../components/vision/BottomIcons";
import { ToastContainer } from "react-toastify";

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider store={store}>
        <ToastContainer />
        <AppRoutes />
        <AddtowlHelper />
        <BottomIcons />
      </Provider>
    );
  }
}

// const root = createRoot(document.getElementById("app"));
