import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../vision/QFLoader";
import VisOops from "../vision/VisOops";
import CommonCardView from "../vision/CommonCardView";
import VisTable from "../vision/VisTable";
import { chartColor } from "../../utils/configs";
import { groupINRCurrencyNSE } from "../../utils/functions";
import VisOrderBook from "../vision/charts/VisOrderBook";
import LinkToComp from "../minis/LinkToComp";

const PreMarketData = ({ selectedIndex }) => {
  const [preMarketData, setPreMarketData] = useState({ data: [] });
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(true);
  const [dataTable, setdataTable] = useState({ columns: [], rows: [] });
  const [showOrderBookState, setShowOrderBookState] = useState(false);
  const [orderBook, setOrderBook] = useState({});

  const showOrderBook = (orderBook) => {
    const buySell = {
      buy: orderBook
        .filter((val) => val.sellQty === 0 && val.buyQty !== 0)
        .map((val) => ({
          price: val.price,
          quantity: val.buyQty,
          orders: "-",
        })),
      sell: orderBook
        .filter((val) => val.buyQty === 0 && val.sellQty !== 0)
        .map((val) => ({
          price: val.price,
          quantity: val.sellQty,
          orders: "-",
        })),
    };
    setShowOrderBookState(true);
    setOrderBook(buySell);
  };

  useEffect(() => {
    setLoaderShow(true);
    setErrorShow(false);
    commonApiGet(
      `/market/premarket?key=${
        selectedIndex === "NIFTY 50" ? "NIFTY" : selectedIndex === "NIFTY BANK" ? "BANKNIFTY" : "ALL"
      }`
    )
      .then((res) => {
        setPreMarketData(res.data);
        setLoaderShow(false);
      })
      .catch((err) => {
        notificationTopup(err.message);
        setLoaderShow(false);
        setErrorShow(true);
      });
  }, [selectedIndex]);

  useEffect(() => {
    if (preMarketData.data.length > 0) {
      setdataTable({
        columns: [
          { field: "symbol", label: "Symbol" },
          { field: "hiddenSymbol", label: undefined },
          { field: "lastPrice", label: "LTP" },
          { field: "buySell", label: "Buy/Sell" },
          { field: "totalTradedVolume", label: "Traded Vol" },
          { field: "totalTurnover", label: "Turnover" },
          { field: "yearlyMovement", label: "52W" },
          // { field: "purpose", label: "Purpose" },
          // { field: "dayGraph", label: "" },
        ],
        rows: preMarketData.data.map((val) => ({
          symbol: <LinkToComp symbol={val.metadata.symbol} />,
          hiddenSymbol: val.metadata.symbol,
          lastPrice: (
            <span>
              ₹{val.metadata.lastPrice}
              <br />
              <span style={{ fontSize: "var(--fnt-small-cp)", color: val.metadata.change > 0 ? chartColor.green : chartColor.red }}>
                {val.metadata.change.toFixed(2)} ({val.metadata.pChange.toFixed(2)}%)
              </span>
            </span>
          ),
          buySell: (
            <div
              className="c-pointer hover-effect-des"
              onClick={() => {
                showOrderBook(val.detail.preOpenMarket.preopen);
              }}
            >
              <font color={chartColor.green}>+{groupINRCurrencyNSE(val.detail.preOpenMarket.totalBuyQuantity, false)}</font>
              <br />
              <font color={chartColor.red}>-{groupINRCurrencyNSE(val.detail.preOpenMarket.totalSellQuantity, false)}</font>
            </div>
          ),
          totalTradedVolume: groupINRCurrencyNSE(val.detail.preOpenMarket.totalTradedVolume, false),
          totalTurnover: groupINRCurrencyNSE(val.metadata.totalTurnover),
          yearlyMovement: `₹${val.metadata.yearLow}-₹${val.metadata.yearHigh}`,
          // purpose: val.metadata.purpose,
          // dayGraph: (
          //   <img src={val.metadata.chartTodayPath} title={`Premarket movement ${val.metadata.symbol}`} height={30} />
          // ),
        })),
      });
    }
  }, [preMarketData]);
  return (
    <CommonCardView
      id="commonCard_Premarket"
      header="Premarket"
      headerInfo={{
        show: true,
        desc: "Track stock movements and trends in the 15-minute premarket session of the Indian stock market. Use this data to gauge early market sentiment and prepare for the trading day.",
        id: "pre-market",
      }}
      toWatermark={true}
      style={{
        position: "relative",
      }}
    >
      {errorShow ? <VisOops /> : loaderShow ? <QFLoader /> : <VisTable data={dataTable} recsPerPage={20} />}

      {showOrderBookState ? (
        <>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              //   backgroundColor: "var(--text-color-opac)",
              backdropFilter: "blur(1px)",
              top: "0",
              right: "0",
            }}
            onClick={() => {
              setShowOrderBookState(false);
              setOrderBook({});
            }}
          ></div>

          <div
            className="glassmorfy-it"
            style={{
              position: "absolute",
              bottom: "0px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "300px",
              maxWidth: "90%",
              minHeight: "100px",
              //   backgroundColor: "var(--body-bg)",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              padding: "10px",
            }}
          >
            <>
              <div className="col-12 non-trans-bg zoo-70 py-1 px-3 rounded our-border mx-0">
                <VisOrderBook data={orderBook} />
              </div>
            </>
          </div>
        </>
      ) : (
        <></>
      )}
    </CommonCardView>
  );
};

export default PreMarketData;
