import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const GlobalIndicesNews = () => {
  const theme = useSelector((state) => state.theme);
  useEffect(() => {
    // Load the TradingView widget script
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      feedMode: "market",
      isTransparent: true,
      displayMode: "adaptive",
      // width: "400",
      height: "450",
      colorTheme: theme,
      locale: "en",
      market: "index",
    });
    document.getElementsByClassName("tradingview-widget-container__widget_globNews")[0].appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const widgetContainer = document.getElementsByClassName("tradingview-widget-container__widget_globNews")[0];
      if (widgetContainer) {
        widgetContainer.innerHTML = ""; // Clear the widget on unmount
      }
    };
  }, [theme]);

  return (
    <div className="tradingview-widget-container general-card-1  vert-center h-min-full">
      <div
        className="tradingview-widget-container__widget_globNews"
        style={{
          // height: "-webkit-fill-available",
        }}
      ></div>
    </div>
  );
};

export default GlobalIndicesNews;
