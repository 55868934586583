// TVStockPageRedirector.js

import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const TVStockPageRedirector = () => {
  const [sp] = useSearchParams();
  const tvwidgetsymbol = sp.get("tvwidgetsymbol");
  const n = useNavigate();

  useEffect(() => {
    if (tvwidgetsymbol.split(":")[1]) {
      n("/info/BSE/" + tvwidgetsymbol.split(":")[1]);
    } else {
      n("/404");
    }
  }, []);
  return <div></div>;
};

export default TVStockPageRedirector;
