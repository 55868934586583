import React, { useState } from "react";
import { commonApiPost } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";

const WatchlistCopyFollow = ({ id }) => {
  const [loadingForCopy, setLoadingForCopy] = useState(false);
  const [loadingForBookmark, setLoadingForBookmark] = useState(false);
  return (
    <div>
      <button
        type="button"
        className={`btn-xs rounded-pill px-2 general-card-1 our-border btn-hover-effect m-1`}
        style={{
          fontWeight: 400,
          color: "var(--text-color)",
          position: "relative",
        }}
        onClick={async () => {
          setLoadingForCopy(true);
          await commonApiPost(`/user/watchlist/copy/${id}`)
            .then((res) => {
              notificationTopup(res.data.info, "green");
            })
            .catch((err) => {
              notificationTopup(err?.response?.data?.error || err.message);
            });
          setLoadingForCopy(false);
        }}
        disabled={loadingForCopy}
      >
        {loadingForCopy ? "Loading..." : "Copy"}
      </button>
      <button
        type="button"
        className={`btn-xs rounded-pill px-2 general-card-1 our-border btn-hover-effect m-1`}
        style={{
          fontWeight: 400,
          color: "var(--text-color)",
          position: "relative",
        }}
        onClick={async () => {
          setLoadingForBookmark(true);
          await commonApiPost(`/user/watchlist/bookmark/${id}`)
            .then((res) => {
              notificationTopup(res.data.message, "green");
            })
            .catch((err) => {
              notificationTopup(err?.response?.data?.error || err.message);
            });
          setLoadingForBookmark(false);
        }}
        disabled={loadingForBookmark}
      >
        {loadingForBookmark ? "Loading..." : "Bookmark"}
      </button>
    </div>
  );
};

export default WatchlistCopyFollow;
