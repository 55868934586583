import React, { useEffect, useRef, useState } from "react";
import CommonCardView from "../vision/CommonCardView";
import { camelCaseToSentence, isMobile } from "../../utils/configs";
import FiiDiiPageDayWise from "./FiiDiiComponents/FiiDiiPageDayWise";
import FiiDiiPageCMProvisional from "./FiiDiiComponents/FiiDiiPageCMProvisional";
import FiiDiiPageCMSebi from "./FiiDiiComponents/FiiDiiPageCMSebi";
import FiiDiiPageDerivatives from "./FiiDiiComponents/FiiDiiPageDerivatives";
import FiiDiiPageFPISectoral from "./FiiDiiComponents/FiiDiiPageFPISectoral";
import { useParams } from "react-router-dom";

const FiiDiiPage = () => {
  const urlSelectedOption = useParams().fiiDiiType;
  const options = ["Day Wise", "CM Provisional", "CM Sebi", "FPI Sectoral", "Derivtives"];
  const urlOptions = {
    "day-wise": "Day Wise",
    "cm-provisional": "CM Provisional",
    "cm-sebi": "CM Sebi",
    "fpi-sectoral": "FPI Sectoral",
    derivatives: "Derivtives",
  };
  const [selectedOption, setSelectedOption] = useState(urlOptions[urlSelectedOption] || options[0]);

  useEffect(() => {
    setSelectedOption(urlOptions[urlSelectedOption] || options[0]);
  }, [urlSelectedOption]);

  const isMobileView = isMobile();

  const colRef = useRef(null);

  return (
    <CommonCardView
      id="commonCard_fiiDiiDetails"
      header="FII/DII Activity"
      headerAlign="left"
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: "Track FII and DII trades with options like Day Wise, CM Provisional, CM Sebi, FPI Sectoral, and Derivatives.",
        id: "fii-dii-market-activity",
      }}
      responsiveHeight={true}
    >
      <div>
        <div className="row">
          <div className="col-md-2">
            <div
              className={`row ${isMobileView ? "section-bb px-2" : "section-bl ms-1"}`}
              style={{
                ...(isMobileView
                  ? {
                      flexWrap: "nowrap",
                      overflowX: "scroll",
                    }
                  : {}),
              }}
            >
              {options.map((option, index) => (
                <div
                  className={`col-md-12 col-5 m-0 p-1 vert-center ${
                    option === selectedOption ? "tr-hover-focus-selectionDefaultOpac" : "tr-hover-focus-selection"
                  }`}
                  key={index}
                  onClick={() => {
                    if (option === selectedOption) return;
                    setSelectedOption(option);
                  }}
                  style={{
                    cursor: "pointer",
                    ...(isMobileView
                      ? { borderTopLeftRadius: "25px", textAlign: "center" }
                      : { borderBottomRightRadius: "25px", textAlign: "left" }),
                    borderTopRightRadius: "25px",
                  }}
                >
                  {camelCaseToSentence(option)}
                </div>
              ))}
            </div>
          </div>
          <div
            className={`col-md-10 fiidiitables mt-0 ${isMobileView ? "my-2" : "vert-center"}`}
            style={{ height: isMobileView ? "600px" : "700px", overflowY: "scroll" }}
            ref={colRef}
          >
            {selectedOption === "Day Wise" ? (
              <FiiDiiPageDayWise />
            ) : selectedOption === "CM Provisional" ? (
              <FiiDiiPageCMProvisional />
            ) : selectedOption === "CM Sebi" ? (
              <FiiDiiPageCMSebi />
            ) : selectedOption === "FPI Sectoral" ? (
              <FiiDiiPageFPISectoral />
            ) : selectedOption === "Derivtives" ? (
              <FiiDiiPageDerivatives />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </CommonCardView>
  );
};

export default FiiDiiPage;
