import { commonApiGet, getCookie, postApiReqHandlation } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import React, { useEffect, useState } from "react";
import angelonelogo from "./angelonelogo.png";
import axios from "axios";
import hawkQuiver, { appendJourney } from "../../../utils/hawkeye/hawkQuiver";
import store from "../../../utils/glob-redux/store";
import { Tooltip } from "reactstrap";
import { useSelector } from "react-redux";

export const angelOneConfigs = {
  apiBaseUrl: "https://apiconnect.angelone.in",
  wsBaseUrl: "wss://smartapisocket.angelone.in/smart-stream",
  api_key: localStorage.getItem("angelOneApi") || "pVySGpkC",
};

const req = axios.create({
  baseURL: angelOneConfigs.apiBaseUrl, // Replace with your actual base URL
});

req.interceptors.request.use(async (config) => {
  const authToken = getCookie("angelone_access_token"); // Replace with your method to retrieve the token dynamically if needed
  const localIP = "127.0.0.1"; // Replace with your logic to get the local IP
  const publicIP = "127.0.0.1"; // Replace with your logic to get the public IP
  const macAddress = "00:00:00:00:00:00"; // Replace with the MAC address dynamically
  const privateKey = angelOneConfigs.api_key; // Replace with your private key

  config.headers.Authorization = `Bearer ${authToken}`;
  config.headers["Content-Type"] = "application/json";
  config.headers.Accept = "application/json";
  config.headers["X-UserType"] = "USER";
  config.headers["X-SourceID"] = "WEB";
  config.headers["X-ClientLocalIP"] = localIP;
  config.headers["X-ClientPublicIP"] = publicIP;
  config.headers["X-MACAddress"] = macAddress;
  config.headers["X-PrivateKey"] = privateKey;

  return config;
});

export default req;
export async function angelOneApiGet(url, invoker = "COMP-NOT-GIVEN") {
  hawkQuiver.dispatch(appendJourney(`${new Date().getTime()}_AO-APICALL_${invoker}_angelOneApiGet: ${url}`));

  return await req
    .get(url)
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(
        err,
        {
          url,
          invoker,
        },
        false,
        true
      );
    });
}

// {
//   "success": false,
//   "message": "Invalid Token",
//   "errorCode": "AG8001",
//   "data": ""
// }

export const angelOneApiPost = async (url, data) => {
  return await req
    .post(url, data, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(
        err,
        {
          url,
        },
        false,
        true
      );
    });
};

export function getAngelOneWebSocketHeaders() {
  const angelonep = JSON.parse(localStorage.getItem("angelOneProfile"));
  return {
    jwttoken: getCookie("angelone_access_token"),
    apikey: angelOneConfigs.api_key,
    clientcode: angelonep.clientcode,
    feedtoken: getCookie("angelone_feed_token"),
  };
}

export async function getExpiryToken(index, exp) {
  return await commonApiGet(`/websock/angelone/optchain/tokens?symbol=${index}&expiry=${exp}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      notificationTopup(err.message);
      return [];
    });
}

export async function doLogin() {
  await commonApiGet("/user/broker/angelone/login", "angelOneGetLogin", false)
    .then((res) => {
      if (res?.data?.auth_url) {
        location.href = res?.data?.auth_url;
      } else {
        notificationTopup("Failed to get login URL | Try after some time.");
      }
    })
    .catch((err) => {
      notificationTopup("Unauthorized");
    });
}

export const handleAngelOne = () => {
  const accessToken = getCookie("angelone_access_token");
  const feedToken = getCookie("angelone_feed_token");
  if ((accessToken, feedToken)) return { status: true, accessToken, feedToken };
  return { status: false };
};

// https://apiconnect.angelone.in/rest/secure/angelbroking/user/v1/getProfile
export const getUserDetails = async () => {
  return await angelOneApiGet("/rest/secure/angelbroking/user/v1/getProfile")
    .then((res) => {
      store.dispatch({
        type: "EDIT_CONNECTED_BROKER",
        broker: {
          angelOne: res.data.success || res.data.status || false,
        },
      });
      return res.data;
    })
    .catch((err) => notificationTopup(err.message));
};

export const GetAngelOneLoginBtn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const connectedBroker = useSelector((state) => state.connectedBroker);

  useEffect(() => {
    (async () => {
      await getUserDetails()
        .then((res) => {
          setIsLoggedIn(res.success || res.status);
        })
        .catch((err) => {
          setIsLoggedIn(false);
        });
    })();
  }, []);

  useEffect(() => {
    setIsLoggedIn(connectedBroker.angelOne);
  }, [connectedBroker.angelOne]);

  return (
    <>
      <button
        type="button"
        id="brokerConnect_AO_button"
        className={`btn-sm general-btn ${
          isLoggedIn ? "our-border-success" : "our-border-danger"
        } btn-hover-effect non-trans-bg`}
        style={{
          fontWeight: 400,
          color: "var(--text-color)",
          maxWidth: "200px",
        }}
        onClick={() => {
          if (!isLoggedIn) {
            localStorage.setItem("handle-connect-url", location.href);
            doLogin();
          }
        }}
      >
        <img src={angelonelogo} title="Angel One" alt="" className="mx-1" />
      </button>
      {isLoggedIn ? (
        <Tooltip
          popperClassName="trans-bg"
          isOpen={tooltipOpen}
          placement="right"
          target={`brokerConnect_AO_button`}
          toggle={() => {
            setTooltipOpen(!tooltipOpen);
          }}
          innerClassName="text-color-themed glassmorfy-it"
          autohide={false}
          fade={true} // Use fade instead of transition
          style={{
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)", // Soft shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.3)", // Light border to enhance glass feel
          }}
        >
          <div className="align-left">
            <div className=" c-pointer">
              <div
                onClick={async () => {
                  angelOneLogout();
                  setIsLoggedIn(false);
                }}
              >
                <span style={{ fontSize: "var(--fnt-small)" }}>Logout</span>{" "}
                <i
                  className={`fas fa-power-off bg-danger p-1 rounded`}
                  style={{
                    color: "#fff",
                  }}
                ></i>
              </div>
            </div>
          </div>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export const getAngleOneOptionGreeks = async (symbol, expiry) => {
  return await angelOneApiPost("/rest/secure/angelbroking/marketData/v1/optionGreek", {
    name: symbol,
    expirydate: expiry,
  });
};

export const angelOneLogout = async () => {
  // https://apiconnect.angelone.in/rest/secure/angelbroking/user/v1/logout
  document.cookie = "angelone_access_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
  document.cookie = "angelone_feed_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
  const angelonep = JSON.parse(localStorage.getItem("angelOneProfile"));
  let clientcode = angelonep?.clientcode || null;
  if (clientcode !== null) {
    await angelOneApiPost("/rest/secure/angelbroking/user/v1/logout", {
      clientcode,
    });
    store.dispatch({
      type: "EDIT_CONNECTED_BROKER",
      broker: {
        angelOne: false,
      },
    });
    localStorage.removeItem("angelOneProfile");
  }
};

export const getTokenForSymbolA1 = async (symbol, exchange) => {
  return await angelOneApiPost("/rest/secure/angelbroking/order/v1/searchScrip", {
    exchange,
    searchscrip: symbol,
  });
};
