import React, { useEffect, useState } from "react";
import derivativesStore, { setSBOptionLegs } from "../derivatives-store";
import { chartColor, isMobile } from "../../../../utils/configs";
import { Tooltip } from "reactstrap";
import { deleteStrategyLeg } from "./StrategyPaperTrade";

const OptionLeg = ({
  strikePrice,
  lastPrice,
  optionType,
  action,
  timeAdded,
  deleteIndex,
  expiryDate,
  lots,
  pChange,
  change,
  lotSize,
  optionChain,
  tradedPrice,
  savedStrategyConfig,
}) => {
  // const [updatedStore, setUpdatedStore] = useState(derivativesStore.getState());
  // const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const pnl = (lastPrice * 100) / tradedPrice - 100;
  const marginCalc = (
    (action == "B" ? -1 : 1) *
    (lots * lotSize * tradedPrice)
  ).toFixed(2);
  const pnlCalc =
    (lots * lotSize * lastPrice).toFixed(2) - Math.abs(marginCalc);
  // const borderStyle = "1px solid var(--text-color) !important";
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  // useEffect(() => {
  //   const unsubscribe = derivativesStore.subscribe(() => {
  //     setUpdatedStore(derivativesStore.getState());
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  // const handleOptionLegUpdate = (sp, ot, ac, l) => {
  //   const latestData = optionChain.find(
  //     (ocs) => ocs.optionType == ot && ocs.strikePrice == sp
  //   );
  //   const newLeg = {
  //     ...updatedStore.optionLegs[deleteIndex],
  //     ...latestData,
  //     action: ac,
  //     lots: l,
  //     tradedPrice: latestData.lastPrice,
  //   };

  //   derivativesStore.dispatch(
  //     setSBOptionLegs({
  //       type: "replace",
  //       optionLegIndex: deleteIndex,
  //       optionLeg: newLeg,
  //     })
  //   );
  // };

  // const DeleteBTN = (
  //   <div className="col-2 text-center px-1 mt-2">
  //     <button
  //       type="button"
  //       className="btn btn-sm btn-danger ms-1"
  //       onClick={() => {
  //         derivativesStore.dispatch(
  //           setSBOptionLegs({
  //             type: "delete",
  //             optionLegIndex: deleteIndex,
  //           })
  //         );
  //       }}
  //     >
  //       <i className="fas fa-trash-can" />
  //     </button>
  //   </div>
  // );

  return (
    <form className="mt-2 border rounded ">
      <div
        className="row text-xs rounded p-0 m-0 py-2"
        style={{
          backgroundColor:
            action == "S" ? chartColor.opacRedMore : chartColor.opacGreenMore,
          fontWeight: "bolder",
        }}
      >
        <div className="col-2 col-md-1 my-auto">
          <span
            className={`div-pill bg-${
              action == "S" ? "danger" : "success"
            } px-2 py-1 rounded`}
            style={{
              fontWeight: 400,
              color: "#fff",
              fontSize: "var(--fnt-h3)",
            }}
          >
            {action}
          </span>
        </div>
        <div className="col-10 col-md-11 vert-center">
          <div className="row">
            <div
              className={`col-12 ${isMobile() ? "ps-0" : ""}`}
              style={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span>
                {expiryDate} {strikePrice} {optionType}
              </span>
              <span>
                <i
                  id={`drtv_oc_${strikePrice}_${optionType}_${action}_${expiryDate}_leg`}
                  className="fas fa-bars-staggered"
                ></i>
                <Tooltip
                  popperClassName="trans-bg"
                  isOpen={tooltipOpen}
                  placement="right"
                  target={`drtv_oc_${strikePrice}_${optionType}_${action}_${expiryDate}_leg`}
                  toggle={() => {
                    setTooltipOpen(!tooltipOpen);
                  }}
                  innerClassName="text-color-themed glassmorfy-it"
                  autohide={false}
                  fade={true} // Use fade instead of transition
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)", // Soft shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.3)", // Light border to enhance glass feel
                  }}
                >
                  {/* <div>{headerInfo.desc}</div>

                  <hr className="m-1" /> */}
                  <div className="align-left">
                    <div className=" c-pointer">
                      {isDeleteConfirmation ? (
                        <>
                          <i
                            className={`fas fa-thumbs-up mx-1 text-warning p-1 rounded`}
                            style={{
                              color: "#fff",
                            }}
                            onClick={() => {
                              if (
                                savedStrategyConfig.isSavedStrategyLoaded &&
                                savedStrategyConfig.strategyId !== undefined &&
                                savedStrategyConfig.legId !== undefined
                              ) {
                                deleteStrategyLeg(
                                  savedStrategyConfig.strategyId,
                                  savedStrategyConfig.legId
                                );
                              }
                              derivativesStore.dispatch(
                                setSBOptionLegs({
                                  type: "delete",
                                  optionLegIndex: deleteIndex,
                                })
                              );
                            }}
                          ></i>
                          <i
                            className={`fas fa-thumbs-down mx-1 text-warning p-1 rounded`}
                            style={{
                              color: "#fff",
                            }}
                            onClick={() => {
                              setIsDeleteConfirmation(false);
                            }}
                          ></i>
                        </>
                      ) : (
                        <div
                          onClick={() => {
                            setIsDeleteConfirmation(true);
                          }}
                        >
                          <span style={{ fontSize: "var(--fnt-small)" }}>Square off</span>{" "}
                          <i
                            className={`fas fa-ban bg-${
                              pnl > 0 ? "success" : "danger"
                            } p-1 rounded`}
                            style={{
                              color: "#fff",
                            }}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                </Tooltip>
              </span>
            </div>
            <div
              className={`col-12 ${isMobile() ? "ps-0" : ""}`}
              style={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span>
                {lots}x{lotSize} @ {tradedPrice}
              </span>
              <span className={`text-${pnl < 0 ? "danger" : "success"}`}>
                PNL: {pnlCalc} ({pnl.toFixed(2)}%)
              </span>
            </div>
            <div
              className={`col-12 ${isMobile() ? "ps-0" : ""}`}
              style={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span>Premium: ₹{marginCalc}</span>
              <span className={`text-${pChange < 0 ? "danger" : "success"}`}>
                LTP: {lastPrice} ({pChange.toFixed(2)}%)
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* {isExpanded ? (
        <div className="row mx-2 mt-2 pb-2">
          <div className="col-md-3 col-6 vert-center px-1 mt-2">
            <div className="input-group">
              <input
                type="number"
                className="qfInputType pl-3 form-control"
                style={{
                  borderLeft: borderStyle,
                  borderTop: borderStyle,
                  borderBottom: borderStyle,
                  maxHeight: "30px",
                }}
                placeholder="Lots"
                aria-label="Lots"
                aria-describedby={`lots_${deleteIndex}`}
                value={lots}
                min={1}
                onChange={(e) => {
                  handleOptionLegUpdate(
                    strikePrice,
                    optionType,
                    action,
                    e.target.value
                  );
                }}
              />
              <div className="input-group-append ">
                <span
                  className="input-group-text qfInputType-addon-input-label p-2"
                  id={`lots_${deleteIndex}`}
                  style={{
                    maxHeight: "30px",
                    borderTopLeftRadius: "unset !important",
                    borderBottomLeftRadius: "unset !important",
                  }}
                >
                  x{lotSize}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 vert-center px-1 mt-2">
            <select
              onChange={(e) => {
                handleOptionLegUpdate(e.target.value, optionType, action, lots);
              }}
              value={strikePrice}
              className="qfInputType px-2 "
              style={{
                height: "30px",
                border: borderStyle,
              }}
            >
              {(optionChain || [{ strikePrice }]).map((ocs, index) => {
                return (
                  <option value={ocs.strikePrice} key={index}>
                    {ocs.strikePrice}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-2 col-5 vert-center px-1 mt-2">
            <select
              onChange={(e) => {
                handleOptionLegUpdate(
                  strikePrice,
                  e.target.value,
                  action,
                  lots
                );
              }}
              value={optionType}
              className="qfInputType px-2"
              style={{
                height: "30px",
                border: borderStyle,
              }}
            >
              <option value="PE">PE</option>
              <option value="CE">CE</option>
            </select>
          </div>
          {isMobile() ? DeleteBTN : <></>}
          <div className="col-md-2 col-5 vert-center px-1 mt-2">
            <select
              onChange={(e) => {
                handleOptionLegUpdate(
                  strikePrice,
                  optionType,
                  e.target.value,
                  lots
                );
              }}
              value={action}
              className="qfInputType px-2"
              style={{
                height: "30px",
                border: borderStyle,
              }}
            >
              <option value="B">B</option>
              <option value="S">S</option>
            </select>
          </div>
          {isMobile() ? <></> : DeleteBTN}
        </div>
      ) : (
        <></>
      )} */}
    </form>
  );
};

export default OptionLeg;
