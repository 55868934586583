import html2canvas from "html2canvas";
import { notificationTopup } from "../../utils/NotificationTopup";

const downloadFallback = (blob) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "screenshot.png";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Your function for capturing and sharing the canvas
function captureAndShare(
  id,
  header,
  headerInfo = {
    desc: "Description",
  },
  isLink = false
) {
  html2canvas(document.getElementById(id), {
    useCORS: true, // Enable CORS
    crossOrigin: "anonymous", // Set crossOrigin attribute
  })
    .then((canvas) => {
      // Fallback to using toDataURL() if toBlob() causes security issues

      const dataURL = canvas.toDataURL("image/jpeg"); // Base64 encoded image
      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

      // Convert base64 string to a Blob
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: mimeString });

      if (blob) {
        const file = new File([blob], "screenshot.jpeg", {
          type: "image/jpeg",
        });

        // Check if the system supports file sharing
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          navigator
            .share({
              ...(!isLink
                ? {
                    files: [file],
                  }
                : {}),
              title: `Check out ${header} on Quibblefrost`,
              url: location.href,
              text: `Check out ${header} on Quibblefrost | ${
                headerInfo.desc === "Description" ? header : headerInfo.desc
              } on Quibblefrost! Visit now `,
            })
            .catch((error) => {
              console.error("Error sharing:", error);
            });
        } else {
          console.log("Your system doesn't support sharing files.");
          notificationTopup("Your system doesn't support file sharing.");
          downloadFallback(blob); // Trigger download as fallback
        }
      } else {
        console.error("Failed to convert canvas to Blob.");
      }
    })
    .catch((err) => {
      notificationTopup(err.message);
    });
}

export default captureAndShare;
