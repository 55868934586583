import React, { useEffect, useMemo, useRef, useState } from "react";
import DerivativesPages from "./DerivativesPages";
import DerivativesOC from "./DerivativesOC";
import { commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { isMarketOpen } from "../../../utils/configs";
import { AngelOneWebSocketSmartStream } from "../../integrations/angel-one/angelOneWebsocket";
import { useSelector } from "react-redux";
import { getAngleOneOptionGreeks } from "../../integrations/angel-one/angelOneHelper";
import derivativesStore, { setSBOptionLegs } from "./derivatives-store";
import { indexTokenMappingsDerivatives } from "../../../utils/mappings/generalMappings";
import VisBetaTag from "../../vision/VisBetaTag";

const Derivatives = () => {
  const [underlying, setUnderlying] = useState({});
  const [fulldata, setFullData] = useState({ data: [] });

  const [optionChain, setOptionChain] = useState({ data: [], Exp: ["date"] });
  const [optionExDates, setOptionExDates] = useState([]);
  const [option, setOption] = useState("NIFTY");
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  const [isOneTimeAPICalled, setIsOneTimeAPICalled] = useState(false);
  const [apiChecker, setApiChecker] = useState(0);
  const [optionsDate, setOptionsDate] = useState(undefined);

  const isOneTimeAPICalledRef = useRef(isOneTimeAPICalled); // Create a ref

  const [tokens, setTokens] = useState([]);
  const tokensRef = useRef([]); // Ref to store the latest tokens

  const tokenHelper = useRef({});
  const connectedBroker = useSelector((state) => state.connectedBroker);

  const connectedBrokerRef = useRef({});

  const optionChainRef = useRef(optionChain);

  useEffect(() => {
    optionChainRef.current = optionChain;
  }, [optionChain]);

  useEffect(() => {
    const interval = setInterval(() => {
      setOptionChain(optionChainRef.current);
    }, 200); // Refresh every 1 second

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, []);

  useEffect(() => {
    connectedBrokerRef.current = connectedBroker;
  }, [connectedBroker]);

  const webSocketWorkerRef = useRef(null);

  const updatedStore = useRef(derivativesStore.getState());

  useEffect(() => {
    const unsubscribe = derivativesStore.subscribe(() => {
      updatedStore.current = derivativesStore.getState();
    });
    return () => {
      unsubscribe();
    };
  }, []);
  const handleUpdateForOptionChainFromA1 = (strk, type, data, isOptGreeks = false) => {
    // console.log("Updating option chain for:", { strk, type, data }); // Debug log
    if (optionChainRef.current.data) {
      optionChainRef.current = {
        ...optionChainRef.current,
        data: {
          ...optionChainRef.current.data,
          [strk]: {
            ...(optionChainRef.current.data[strk] || {}),
            [type]: {
              ...(optionChainRef.current.data[strk]?.[type] || {}),
              ...(isOptGreeks
                ? {
                    gamma: data.gamma,
                    vega: data.vega,
                    theta: data.theta,
                    delta: data.delta,
                    impliedVolatility: data.impliedVolatility,
                  }
                : {
                    totalTradedVolume: data.volume_traded_for_the_day,
                    openInterest: data.open_interest,
                    changeinOpenInterest: data.open_interest_change_percent,
                    lastPrice: data.last_traded_price / 100,
                    pChange: (data.last_traded_price * 100) / data.close_price - 100,

                    otherAngelOneData: {
                      best_five_data: data.best_five_data,
                      total_buy_quantity: data.total_buy_quantity,
                      total_sell_quantity: data.total_sell_quantity,
                      open_price_of_the_day: data.open_price_of_the_day / 100,
                      high_price_of_the_day: data.high_price_of_the_day / 100,
                      low_price_of_the_day: data.low_price_of_the_day / 100,
                      upper_circuit_limit: data.upper_circuit_limit / 100,
                      lower_circuit_limit: data.lower_circuit_limit / 100,
                      fifty_two_week_high: data.fifty_two_week_high / 100,
                      fifty_two_week_low: data.fifty_two_week_low / 100,
                      close_price: data.close_price / 100,
                      average_traded_price: data.average_traded_price / 100,
                    },
                  }),
            },
          },
        },
      };
    }
    // setOptionChain((prevOptionChain) => {
    //   if (!prevOptionChain.data) {
    //     return prevOptionChain;
    //   }
    //   const updatedData = {
    //     ...prevOptionChain.data,
    //     [strk]: {
    //       ...(prevOptionChain.data[strk] || {}),
    //       [type]: {
    //         ...(prevOptionChain.data[strk]?.[type] || {}),
    //         ...(isOptGreeks
    //           ? {
    //               gamma: data.gamma,
    //               vega: data.vega,
    //               theta: data.theta,
    //               delta: data.delta,
    //               impliedVolatility: data.impliedVolatility,
    //             }
    //           : {
    //               totalTradedVolume: data.volume_traded_for_the_day,
    //               openInterest: data.open_interest,
    //               changeinOpenInterest: data.open_interest_change_percent,
    //               lastPrice: data.last_traded_price / 100,
    //               pChange:
    //                 (data.last_traded_price * 100) / data.close_price - 100,

    //               otherAngelOneData: {
    //                 best_five_data: data.best_five_data,
    //                 total_buy_quantity: data.total_buy_quantity,
    //                 total_sell_quantity: data.total_sell_quantity,
    //                 open_price_of_the_day: data.open_price_of_the_day / 100,
    //                 high_price_of_the_day: data.high_price_of_the_day / 100,
    //                 low_price_of_the_day: data.low_price_of_the_day / 100,
    //                 upper_circuit_limit: data.upper_circuit_limit / 100,
    //                 lower_circuit_limit: data.lower_circuit_limit / 100,
    //                 fifty_two_week_high: data.fifty_two_week_high / 100,
    //                 fifty_two_week_low: data.fifty_two_week_low / 100,
    //                 close_price: data.close_price / 100,
    //                 average_traded_price: data.average_traded_price / 100,
    //               },
    //             }),
    //       },
    //     },
    //   };

    //   return {
    //     ...prevOptionChain,
    //     data: updatedData,
    //   };
    // });
  };

  useEffect(() => {
    if (optionChain.data) {
      updatedStore.current.optionLegs.forEach((val, ind) => {
        const updatedOptionLeg = {
          ...val,
          lastPrice: optionChain?.data[val.strikePrice][val.optionType].lastPrice,
          pChange: optionChain?.data[val.strikePrice][val.optionType].pChange,
          theta: optionChain?.data[val.strikePrice][val.optionType].theta,
          impliedVolatility: optionChain?.data[val.strikePrice][val.optionType].impliedVolatility,
        };

        derivativesStore.dispatch(
          setSBOptionLegs({
            type: "replace",
            optionLegIndex: ind,
            optionLeg: updatedOptionLeg,
          })
        );
      });
    }
  }, [optionChain]);

  const handleUnderlyingFromA1 = (data) => {
    setUnderlying((prevUnderlying) => {
      const updatedData = {
        underlyingValue: data.last_traded_price / 100,
        underlyingPChange: (data.last_traded_price * 100) / data.close_price - 100,
        underlyingChange: (data.last_traded_price - data.close_price) / 100,
      };

      return {
        ...prevUnderlying,
        ...updatedData,
      };
    });
  };

  const handleUnderlyingFromMomentum = (data) => {
    setUnderlying((prevUnderlying) => {
      const updatedData = {
        trend: data.trend,
        momentum: data.momentum,
        pcr: data.pcr,
        pcrVol: data.pcrVol,
      };

      return {
        ...prevUnderlying,
        ...updatedData,
      };
    });
  };

  async function getAOGreeks() {
    const marketOpen = await isMarketOpen();

    if (!marketOpen) return;

    await getAngleOneOptionGreeks(option, optionsDate)
      .then((res) => {
        res.data?.data?.map((val) => {
          handleUpdateForOptionChainFromA1(
            parseInt(val.strikePrice),
            val.optionType,
            {
              gamma: parseFloat(val.gamma),
              vega: parseFloat(val.vega),
              theta: parseFloat(val.theta),
              delta: parseFloat(val.delta),
              impliedVolatility: parseFloat(val.impliedVolatility),
            },
            true
          );
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getMomentum() {
    const marketOpen = await isMarketOpen();

    if (!marketOpen) return;

    await commonApiGet(`/indices/momentum?symbol=${option}&expiry=${optionsDate}`, "DERIVETIVESPAGE", false)
      .then((res) => {
        handleUnderlyingFromMomentum(res.data);
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  }

  useEffect(() => {
    if (connectedBroker.angelOne) {
      webSocketWorkerRef.current = new AngelOneWebSocketSmartStream();
      webSocketWorkerRef.current.connect();

      const handleMessage = (data) => {
        // console.log("Data from WebSocket:", data);
        if (data.exchange_type === 1) {
          handleUnderlyingFromA1(data);
        } else if (data.exchange_type === 2) {
          let gotDataForToken = data.token;
          let strk = tokenHelper.current[gotDataForToken][0];
          let type = tokenHelper.current[gotDataForToken][1];
          handleUpdateForOptionChainFromA1(strk, type, data, false);
        }
      };

      webSocketWorkerRef.current.addListener(handleMessage);

      const greeksInterval = setInterval(getAOGreeks, 2000); // Refresh every 15 seconds
      const momentumInterval = setInterval(getMomentum, 30000); // Refresh every 15 seconds

      return () => {
        clearInterval(greeksInterval); // Clean up the interval on unmount
        clearInterval(momentumInterval);
        unsubscribeMessage(tokensRef.current);
        // webSocketWorkerRef.current.removeListener(handleMessage);
        webSocketWorkerRef.current.disconnect();
      };
    }
  }, [connectedBroker, option, optionsDate]); // Removed optionChain from dependency array

  const subscribeMessage = (newTokens) => {
    if (connectedBroker.angelOne && tokens.length > 0) {
      const message = {
        action: 1,
        params: {
          mode: 3,
          tokenList: [
            { exchangeType: 1, tokens: [indexTokenMappingsDerivatives[option]] },
            { exchangeType: 2, tokens: newTokens },
          ],
        },
      };
      webSocketWorkerRef.current.sendMessage(message);
    }
  };

  const unsubscribeMessage = (oldTokens) => {
    // const
    if (connectedBroker.angelOne) {
      const message = {
        action: 0,
        params: {
          mode: 3,
          tokenList: [
            { exchangeType: 1, tokens: [indexTokenMappingsDerivatives[option]] },
            { exchangeType: 2, tokens: oldTokens },
          ],
        },
      };

      // console.log(message);
      webSocketWorkerRef.current.sendMessage(message);
    }
  };

  useEffect(() => {
    tokensRef.current = tokens;
    subscribeMessage(tokens);
  }, [tokens]);

  // Update the ref whenever isOneTimeAPICalled changes
  useEffect(() => {
    isOneTimeAPICalledRef.current = isOneTimeAPICalled;
  }, [isOneTimeAPICalled]);

  const fetchDates = async () => {
    await commonApiGet(`/websock/expiry/${option}`)
      .then((res) => {
        setOptionExDates(res.data);
        setOptionsDate(res.data[0] || "date");
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };

  const fetchOptionChain = async () => {
    if (optionExDates.length === 0 || !optionsDate || optionsDate === "date") return;

    const marketOpen = await isMarketOpen();

    if (!marketOpen && isOneTimeAPICalledRef.current) return;

    if (isOneTimeAPICalledRef.current && connectedBrokerRef.current.angelOne) return;

    const expiryParam = optionsDate ? `&expiry=${optionsDate}` : "";
    // /indices/openinterest?symbol=NIFTY&expiry=05DEC2024
    const url = `/indices/openinterest?symbol=${option}${expiryParam}`;

    await commonApiGet(url, "OPTION_CHAIN_API", false)
      .then((res) => {
        if (res.data.error) {
          setLoaderShow(false);
          notificationTopup(res.data.error);
        } else {
          let data = res.data;
          setFullData(data);

          if (data.message !== undefined) {
            setErrorShow(true);
            setLoaderShow(false);
            return;
          }
          const currOcFilter = data.data.filter(
            (val) => val.expiryDate.toString().replaceAll("-", "").toLowerCase() === optionsDate.toLowerCase()
          );
          const preData = currOcFilter.reduce((acc, curr) => {
            const { strikePrice, optionType } = curr;
            if (!acc[strikePrice]) acc[strikePrice] = {};
            acc[strikePrice][optionType] = curr;
            return acc;
          }, {});

          setOptionChain({
            data: preData,
            Exp: optionsDate,
          });

          setLoaderShow(false);
          setErrorShow(false);
          setUnderlying(data.underlying);

          const newTokens = currOcFilter.map((val) => val.token).filter((val) => val !== undefined);

          let prepareTokensHelper = {};
          currOcFilter.map((val) => {
            const { strikePrice, optionType } = val;
            const token = val.token;

            prepareTokensHelper[token] = [strikePrice, optionType];
          });

          tokenHelper.current = prepareTokensHelper;

          setTokens(newTokens);
          setIsOneTimeAPICalled(true);
        }
      })
      .catch((error) => {
        if (!isOneTimeAPICalledRef.current) {
          notificationTopup(error.message);
          setErrorShow(true);
          setLoaderShow(false);
        }
      });
  };

  useEffect(() => {
    (async () => {
      await fetchDates();
    })();
  }, [option]);

  useEffect(() => {
    unsubscribeMessage(tokensRef.current);
    setLoaderShow(true);
    fetchOptionChain(); // Initial fetch
    const interval = setInterval(fetchOptionChain, 3 * 60 * 1000); // Refresh every 15 seconds

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, [apiChecker, optionsDate]); // Add dependencies as needed

  const strikePrices = useMemo(() => {
    return optionChain.data ? Object.keys(optionChain.data).map(parseFloat) : [];
  }, [optionChain]);

  const underlyingValue = useMemo(() => {
    return underlying.underlyingValue ? underlying.underlyingValue : null;
  }, [optionChain]);

  const calculateNearestStrikePrice = useMemo(() => {
    if (!optionChain.data || !underlyingValue) return null;
    if (optionChain.data.length === 0) return null;
    // Provide an initial value for reduce, using a large number or the first element of strikePrices
    return strikePrices.reduce(
      (prev, curr) => (Math.abs(curr - underlyingValue) < Math.abs(prev - underlyingValue) ? curr : prev),
      strikePrices[0] // Initial value set to the first strike price
    );
  }, [strikePrices, underlyingValue]);

  const ocPassDown = {
    option,
    optionChain,
    optionExDates,
    underlying,
    strikePrices,
    underlyingValue,
    calculateNearestStrikePrice,
    apiChecker,
    optionsDate,
    setOption,
    setOptionChain,
    setOptionsDate,
    setApiChecker,
    setIsOneTimeAPICalled,
  };

  const commonPassDown = {
    setLoaderShow,
    setErrorShow,
    loaderShow,
    errorShow,
  };

  const pagesPassdown = {
    option,
    underlying,
    optionChain: fulldata,
    optionsDate,
    optionExDates,
  };
  return (
    <div
      className="general-card-1 px-2 py-3"
      style={{
        height: "79vh",
        overflowY: "scroll",
        position: "relative",
      }}
    >
      <VisBetaTag />
      <div className="row p-0 m-0 h-fill-available">
        <>
          <div className="col-md-6 h-fill-available">
            {optionExDates.length > 0 ? <DerivativesOC {...ocPassDown} {...commonPassDown} /> : <></>}
          </div>
          <div className="col-md-6">
            <DerivativesPages {...pagesPassdown} />
          </div>
        </>
      </div>
    </div>
  );
};

export default Derivatives;
