import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";

import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { notificationTopup } from "../../utils/NotificationTopup";
import { getRandomStockMessage } from "../../utils/configs";
import QFLoader from "../vision/QFLoader";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import { groupINRCurrencyNSE } from "../../utils/functions";
import LinkToComp from "../minis/LinkToComp";
import VisDropDown from "../vision/VisDropDown";

const OIChange = ({ by }) => {
  const [search, setSearch] = useState("");

  const [bus, setbus] = useState([]);
  const [dropdownbusOpen, setDropdownbusOpen] = useState(false);
  const [dropdownExpOpen, setDropdownExpOpen] = useState(false);
  const builupOptions = {
    ...(by === "price"
      ? {
          PercPriceGainers: "% Price Gainers",
          PercPriceLosers: "% Price Losers",
        }
      : {
          PercOILosers: "% OI Losers",
          PercOIGainers: "% OI Gainers",
        }),
  };
  const expOptions = ["NEAR", "NEXT", "FAR"];
  const [selectedbusOption, setSelectedbusOption] = useState(Object.keys(builupOptions)[0]);
  const [selectedExpOption, setSelectedExpOption] = useState(expOptions[0]);
  const bustoggle = () => setDropdownbusOpen((prevState) => !prevState);
  const exptoggle = () => setDropdownExpOpen((prevState) => !prevState);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/fno/OIChange?datatype=${selectedbusOption}&expirytype=${selectedExpOption}`)
        .then((res) => {
          setbus(Array.isArray(res.data.data) ? res.data.data : []);
          // setbus(res.data.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [selectedbusOption, selectedExpOption]);

  return (
    <CommonCardView
      id={`commonCard_${by.toUpperCase()}Change`}
      header={`${by.toUpperCase()} Change`}
      headerAlign="left"
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: `Analyze changes in Open Interest (OI) alongside price movements to identify trends, buildups, and potential breakouts or reversals.`,
        id: `${by.toLowerCase()}change-in-stocks`,
      }}
    >
      <div className="absolute-multiple-tabGrp">
        <VisDropDown
          selected={builupOptions[selectedbusOption]}
          mapping={builupOptions}
          setter={(val) => {
            setLoaderShow(true);
            setbus([]);
            setSelectedbusOption(val);
          }}
          isMultiple={true}
        />

        <VisDropDown
          selected={selectedExpOption}
          list={expOptions}
          setter={(val) => {
            setLoaderShow(true);
            setbus([]);
            setSelectedExpOption(val);
          }}
          isMultiple={true}
          className="ms-2"
        />
      </div>

      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          <div
            // style={{
            //   ...(key !== 0
            //     ? {
            //         borderTop: `1px solid ${
            //           theme === "dark" ? themedTextColor.dark : themedTextColor.light
            //         }`,
            //       }
            //     : {}),
            // }}
            className="py-1"
          >
            <table className="w-100 table-tr-bb">
              <thead
                className="make-me-sticky non-trans-bg"
                style={{
                  top: 40,
                }}
              >
                <tr>
                  {/* "percentChange": 5.51,
                      "symbolToken": 40117,
                      "ltp": 198.2,
                      "netChange": 10.35 */}
                  <th>Symbol</th>
                  <th>{selectedbusOption.includes("Price") ? "LTP" : "OI"}</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                {bus
                  .filter((val) => val.tradingSymbol.toLowerCase().includes(search.toLowerCase()))
                  .map((val, key) => {
                    return (
                      <tr key={`selectedbusOption_${selectedbusOption}_${selectedExpOption}_${key}`} className="">
                        {/* <td>{val.tradingSymbol.match(/\d{2}[A-Z]{3}\d{2}/)}</td> */}
                        <td>
                          <LinkToComp symbol={val.tradingSymbol.match(/^[A-Z-&]+(?=\d)/)} />
                        </td>
                        <td>{selectedbusOption.includes("Price") ? "₹" + val.ltp : groupINRCurrencyNSE(val.opnInterest)}</td>
                        <td>
                          {selectedbusOption.includes("Price")
                            ? "₹" + val.netChange
                            : groupINRCurrencyNSE(val.netChangeOpnInterest)}
                          {` (${val.percentChange}%)`}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {bus.filter((val) => val.tradingSymbol.toLowerCase().includes(search.toLowerCase())).length === 0
            ? getRandomStockMessage()
            : ""}
        </>
      )}
    </CommonCardView>
  );
};

export default OIChange;
