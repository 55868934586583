import React from "react";
import ReactECharts from "echarts-for-react";
import { themedTextColor } from "../../../utils/configs";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/functions";

const PieChart = ({ data }) => {
  const theme = useSelector((state) => state.theme.theme);
  const option = {
    title: {
      text: "Donut Chart Example",
      subtext: "ECharts in React",
      left: "center",
      show: false,
    },
    tooltip: {
      trigger: "item",
      className: "customEchartohlcTooltip glassmorfy-it general-card-1",
      borderWidth: 1,
      borderColor: "#ccc",
      padding: 10,
      textStyle: {
        color: themedTextColor[theme],
        fontSize: "10",
      },
      formatter: (params) => {
        const { name, value, percent, data } = params;
        // Access additional values here, e.g., data.OtherValue
        return `
          <strong>${name}</strong><br />
          # Stocks: ${data.stocks || value}<br />
          Marcket Cap: ${formatCurrency(data.mcap || value, "INR")}Cr.
        `;
      },
    },
    legend: {
      orient: "vertical",
      left: "left",
      show: false,
    },
    series: [
      {
        name: "Categories",
        type: "pie",
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: true,
        },
        data: data,
      },
    ],
  };

  return (
      <ReactECharts option={option} style={{ height: 150 }} />
  );
};

export default PieChart;
