import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { commonApiPost, getCookie } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import QFLoader from "../../../components/vision/QFLoader";
import { urlsFE } from "../../pageroutes";

export const loginUrl = "https://api.quibblefrost.in/auth/login";

const Ctas = ({ lgSm = "lg" }) => {
  const n = useNavigate();

  return (
    <div
      className=""
      style={{
        color: "var(--text-color) !important",
      }}
    >
      {getCookie("access_token") === "" ? (
        <>
          <button
            type="button"
            className={`btn-${lgSm} px-4 general-btn m-1`}
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
            onClick={() => {
              // getLogin();
              n(urlsFE.signin);
            }}
          >
            Signin
            {/* <i className="fa-solid fa-right-to-bracket"></i> */}
          </button>
          <button
            type="button"
            className={`btn-${lgSm} px-4 general-btn m-1`}
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
            onClick={() => {
              // getReg();
              n(urlsFE.signup);
            }}
          >
            Signup
            {/* <i className="fa-solid fa-file-signature"></i> */}
          </button>
        </>
      ) : (
        <Link to={urlsFE.dashboard}>
          <button
            type="button"
            className={`btn-${lgSm} px-4 general-btn`}
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
          >
            Explore
          </button>
        </Link>
      )}
    </div>
  );
};

export default Ctas;

export const getGoogleLogin = async (loaderShow) => {
  loaderShow(true);
  await commonApiPost("/auth/google", {})
    .then((res) => {
      // openAuthPopup(res.data.url)
      location.href = res.data.url;
    })
    .catch((err) => {
      notificationTopup(err.message);
      loaderShow(false);
    });
};

export const GoogBtn = ({ text = "Signin" }) => {
  const [loaderShow, setLoaderShow] = useState(false);
  return (
    <div className="social-signup">
      <span
        className="p-1 c-pointer rounded bx-nmprc-out"
        style={{ backgroundColor: "var(--button-bg)" }}
        onClick={() => {
          if (!loaderShow) getGoogleLogin(setLoaderShow);
        }}
      >
        {loaderShow ? (
          <QFLoader />
        ) : (
          <>
            <i
              className=" p-1 fab fa-google rounded"
              style={{ backgroundColor: "var(--body-bg)", fontSize: "var(--fnt-smaller)" }}
            />
            <span
              style={{
                fontSize: "var(--fnt-comm)",
                fontWeight: 700,
                color: "var(--body-bg)",
              }}
            >
              {" "}
              | {text} with Google
            </span>
          </>
        )}
      </span>
    </div>
  );
};

export const LoginBtn = () => {
  return (
    <>
      <Link
        to={urlsFE.signin}
        style={{
          fontSize: "var(--fnt-comm)",
        }}
      >
        Signin
      </Link>
    </>
  );
};
