import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { urlsFE } from "../../../views/pageroutes";

const MarketScreener = () => {
  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    // Load the TradingView widget script
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "450",
      defaultColumn: "overview",
      defaultScreen: "unusual_volume",
      market: "india",
      showToolbar: true,
      colorTheme: theme,
      locale: "en",
      isTransparent: true,
      largeChartUrl: `${location.origin}${urlsFE.tvStkPgRedirector}`,
    });
    document.getElementsByClassName("tradingview-widget-container__widget_screener")[0].appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const widgetContainer = document.getElementsByClassName("tradingview-widget-container__widget_screener")[0];
      if (widgetContainer) {
        widgetContainer.innerHTML = ""; // Clear the widget on unmount
      }
    };
  }, [theme]);

  return (
    <div className="tradingview-widget-container general-card-1  vert-center h-min-full">
      <div
        className="tradingview-widget-container__widget_screener"
        style={{
          // height: "-webkit-fill-available",
        }}
      ></div>
    </div>
  );
};

export default MarketScreener;
