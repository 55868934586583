import React from "react";
import { hawkReport, hawkReportTypeIdentifiers } from "./hawkHelper";
import { removeUserSession } from "../api";

class HawkBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      errorStack: "",
      showStack: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.toString(),
      errorStack: error.stack.toString(),
    };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to your error reporting service
    hawkReport(error, {}, hawkReportTypeIdentifiers.feUnexError);
  }

  toggleStack = () => {
    this.setState((prevState) => ({ showStack: !prevState.showStack }));
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-page w-100">
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <i
              className="fas fa-question"
              style={{
                fontSize: "50px",
              }}
            ></i>
            <h1>Sorry, Something Went Wrong!</h1>
            <p>An unexpected error occurred.</p>
            {this.state.showStack && (
              <div>
                <p>Error: {this.state.errorMessage}</p>
                <p>Stack Trace:</p>
                <pre>{this.state.errorStack}</pre>
              </div>
            )}
            <div>
              <button
                onClick={() => {
                  removeUserSession();
                }}
              >
                Logout & Jump Home Page
              </button>
              <button
                className="ms-1"
                onClick={() => {
                  location.reload();
                }}
              >
                Reload
              </button>
              <br />
              <br />
              {location.href.includes(":3000") ? (
                <button onClick={this.toggleStack}>{this.state.showStack ? "Hide Details" : "View Error Details"}</button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default HawkBoundary;
