import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { chartColor, maxViewContent } from "../../utils/configs";
import { Link } from "react-router-dom";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import QFLoader from "../vision/QFLoader";
import VisOops from "../vision/VisOops";

const ResearchStocks = () => {
  const [researchStocksData, setResearchStocksData] = useState([]);
  const [selectedRes, setSelectedRes] = useState(0);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  useEffect(() => {
    (async () => {
      await commonApiGet(`/analysis/research_stocks`)
        .then((res) => {
          setResearchStocksData(res.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  return (
    <CommonCardView
      id="commonCard_researchAnalysis"
      header="Recommendations"
      style={{
        position: "relative",
      }}
      headerAlign="center"
      headerInfo={{
        show: true,
        desc: "Recommendation by top researchers",
        id: "research-stocks",
      }}
    >
      <div className="tab-weight absolute-tabGrp-left">
        <div className=" mt-1">
          <i
            className="div-pill general-card-1 our-border fas fa-caret-left"
            style={{
              lineHeight: "20px",
            }}
            onClick={() => {
              setSelectedRes((prev) => {
                if (0 === prev) {
                  return researchStocksData.length - 1;
                }
                return prev - 1;
              });
            }}
          ></i>
        </div>
      </div>
      <div className="tab-weight absolute-tabGrp">
        <div className=" mt-1">
          <i
            className="div-pill general-card-1 our-border fas fa-caret-right"
            style={{
              lineHeight: "20px",
            }}
            onClick={() => {
              setSelectedRes((prev) => {
                if (researchStocksData.length - 1 === prev) {
                  return 0;
                }
                return prev + 1;
              });
            }}
          ></i>
        </div>
      </div>
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <div>
          <div className="row px-3 mt-3">
            <div className="col-6">
              <span
                className="div-pill general-card-1 our-border"
                style={{
                  backgroundColor:
                    researchStocksData[selectedRes].Research.recommend_flag ===
                    "BUY"
                      ? chartColor.green
                      : researchStocksData[selectedRes].Research
                          .recommend_flag === "SELL"
                      ? chartColor.red
                      : chartColor.yellow,
                  color: "#fff",
                }}
              >
                {researchStocksData[selectedRes].Research.recommend_flag}
              </span>
            </div>
            <div
              className="col-6"
              style={{
                textAlign: "right",
              }}
            >
              <Link
                to={researchStocksData[selectedRes].Research.attachment}
                target="_blank"
                style={{
                  color: "var(--text-color)",
                  textDecoration: "none",
                }}
              >
                Attachment <i className="fas fa-file"></i>
              </Link>
            </div>
          </div>
          <div className=" mt-2 mb-2">
            <div className="px-1">
              <table className="table-td-r w-100 table-th-py table-tr-bb">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <marquee
                        dangerouslySetInnerHTML={{
                          __html: researchStocksData[
                            selectedRes
                          ].Research.heading
                            .replaceAll(
                              "Buy",
                              `<font color="${chartColor.green}"><b>BUY</b></font>`
                            )
                            .replaceAll(
                              "Sell",
                              `<font color="${chartColor.red}"><b>SELL</b></font>`
                            )
                            .replaceAll(
                              "Hold",
                              `<font color="${chartColor.yellow}"><b>HOLD</b></font>`
                            ),
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Stock:</th>
                    <td>{researchStocksData[selectedRes].Research.stkname}</td>
                  </tr>
                  <tr>
                    <th>Analyst:</th>
                    <td>
                      {maxViewContent(
                        researchStocksData[selectedRes].Research.organization
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Rec Price:</th>
                    <td>
                      ₹
                      {
                        researchStocksData[selectedRes].Research
                          .recommended_price
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Target Price:</th>
                    <td>
                      ₹{researchStocksData[selectedRes].Research.target_price}
                    </td>
                  </tr>
                  <tr>
                    <th>Current Price:</th>
                    <td>₹{researchStocksData[selectedRes].Research.cmp}</td>
                  </tr>
                  <tr>
                    <th>Curr. Ret:</th>
                    <td>
                      {researchStocksData[selectedRes].Research.current_returns}
                      %
                    </td>
                  </tr>
                  <tr>
                    <th>Pot. Ret:</th>
                    <td>
                      {
                        researchStocksData[selectedRes].Research
                          .potential_returns_per
                      }
                      %
                    </td>
                  </tr>
                  <tr>
                    <th>Entry Date:</th>
                    <td>
                      {researchStocksData[selectedRes].Research.entry_date}
                    </td>
                  </tr>
                  <tr>
                    <th>Rec Date:</th>
                    <td>
                      {
                        researchStocksData[selectedRes].Research
                          .target_price_date
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </CommonCardView>
  );
};

export default ResearchStocks;
