import React from "react";
import Scanners from "../components/NSEComponents/Scanners";
import SharkFolio from "../components/NSEComponents/SharkFolio";
import Deals from "../components/NSEComponents/Deals";
import IpoData from "../components/NSEComponents/IpoData";
import { Holiday } from "../components/NSEComponents/Holiday";
import IntradayStocks from "../components/NSEComponents/IntradayStocks";
import MyWatchlists from "../components/features/myWatchlists/MyWatchlists";
import SharedWatchlist from "../components/features/myWatchlists/SharedWatchlist";
import Derivatives from "../components/features/derivatives/Derivatives";
import HandleConnect from "../components/integrations/HandleConnect";
import EodBandChanges from "../components/NSEComponents/EodBandChanges";
import ShortSelling from "../components/NSEComponents/ShortSelling";
import PrivacyPolicy from "./preLogin/components/PrivacyPolicy";
import { NSE } from "./NSE";
import Tnc from "./preLogin/components/Tnc";
import EquityPage from "./equity/EquityPage";
import Signin from "./authentications/Signin";
import Signup from "./authentications/Signup";
import { Home } from "./preLogin/Home";
import Trending from "../components/NSEComponents/Trending";
import AdvancerDecliner from "../components/NSEComponents/AdvancerDecliner";
import PriceBandHitters from "../components/NSEComponents/PriceBandHitters";
import OIChange from "../components/NSEComponents/OIChange";
import Buildup from "../components/NSEComponents/Buildup";
import PreMarketData from "../components/NSEComponents/PreMarketData";
import NotFound from "../components/NotFound";
import { EquityOverviewHelmet, IpoDataHelmet, MarketWatchHelmet, PreMarketHelmet } from "./seo-helmet/helmets";
import FiiDiiPage from "../components/NSEComponents/FiiDiiPage";
import BhavCopy from "../components/NSEComponents/BhavCopy";
import TVStockPageRedirector from "../components/vision/TradingViewWidgets/TVStockPageRedirector";
import ForexMovement from "../components/vision/TradingViewWidgets/ForexMovement";
import GlobalIndicesNews from "../components/vision/TradingViewWidgets/GlobalIndicesNews";
import HomeSearch from "./preLogin/components/HomeSearch";
import SectoralPage from "../components/NSEComponents/SectoralPage";

const MarketWatch = () => {
  return (
    <>
      <div className="col-xxxl-6 col-md-6">
        <Scanners />
      </div>
      <div className="col-xxxl-6 col-md-6">
        <SharkFolio />
      </div>
    </>
  );
};

const PreMarket = () => {
  return (
    <>
      <div className="col-md-8">
        <PreMarketData selectedIndex={"ALL"} />
      </div>
      <div className="col-md-4">
        <EodBandChanges />
      </div>
      <div className="col-md-6">
        <Deals />
      </div>
      <div className="col-md-3">
        <IntradayStocks />
      </div>
      <div className="col-md-3">
        <ShortSelling />
      </div>
    </>
  );
};

const EquityOverview = () => {
  return (
    <>
      <div className="col-xl-3 col-md-6">
        <BhavCopy />
      </div>
      <div className="col-xl-3 col-md-6">
        <Trending />
      </div>
      <div className="col-xl-3 col-md-6">
        <AdvancerDecliner />
      </div>
      <div className="col-xl-3 col-md-6">
        <PriceBandHitters />
      </div>
    </>
  );
};

const DerivativesOverview = () => {
  return (
    <>
      <div className="col-md-4">
        <OIChange by="price" />
      </div>
      <div className="col-md-4">
        <OIChange by="oi" />
      </div>
      <div className="col-md-4">
        <Buildup />
      </div>
    </>
  );
};

export const urlsFE = {
  dashboard: "/dashboard",
  marketWatch: "/market-watch",
  preMarket: "/pre-market",
  equityOverview: "/equity-overview",
  derivativesOverview: "/derivatives-overview",
  scanner: "/fundamental-scanners",
  watchlist: "/my-watchlists",
  derivatives: "/index/derivatives/options",
  handleConnect: "/broker/handle-connect",
  watchlistId: "/watchlist/:watchlistId",
  ipoDetails: "/ipo/:ipoType",
  sectors: "/sectors",
  insideSector: "/sectors/:sectorName",
  marketHoliday: "/market-holiday",
  crypto: "/crypto",
  cryptoRedirector: "/crypto-redirector",
  privacyPolicy: "/privacy-policy",
  tnc: "/terms-and-conditions",
  equityPage: "/info/:exchange/:symbol",
  indexPage: "/index/:exchange/:symbol",
  tvStkPgRedirector: "/tv-stockpage-redirector",
  fiiDii: "/fii-dii/:fiiDiiType",
  signup: "/signup",
  signin: "/signin",
};

/*
  options: 
  {
    path: string
    component: JSX
    isPage: boolean
    shortComponent: boolean
    isProtected: boolean
    emptyRoute: boolean
    helmet: JSX Helmet Component
    doNotChangeHelmet: boolean
  }
*/
export const pageRoutes = [
  {
    path: "/",
    component: <Home />,
    isPage: true,
  },
  {
    path: urlsFE.tvStkPgRedirector,
    component: <TVStockPageRedirector />,
    isPage: true,
  },
  {
    path: urlsFE.ipoDetails,
    component: <IpoData />,
    shortComponent: false,
    isPage: true,
    helmet: <IpoDataHelmet />,
  },
  {
    path: urlsFE.fiiDii,
    component: <FiiDiiPage />,
    shortComponent: false,
    isPage: true,
  },
  {
    path: urlsFE.sectors,
    component: <SectoralPage />,
    isPage: true,
    shortComponent: false,
  },
  {
    path: urlsFE.insideSector,
    component: <SectoralPage />,
    isPage: true,
    shortComponent: false,
  },
  {
    path: urlsFE.marketHoliday,
    component: <Holiday />,
    shortComponent: true,
    isPage: true,
  },
  // {
  //   path: urlsFE.scanner,
  //   component: <Scanners />,
  //   // component: <div className="mt-3"><Scanners /></div>,
  //   shortComponent: true,
  //   isPage: true,
  //   isProtected: true,
  // },
  {
    path: urlsFE.watchlist,
    component: <MyWatchlists />,
    shortComponent: false,
    isProtected: true,
    isPage: true,
  },
  {
    path: urlsFE.watchlistId,
    component: <SharedWatchlist />,
    shortComponent: false,
    isPage: true,
  },
  {
    path: urlsFE.derivatives,
    component: <Derivatives />,
    shortComponent: false,
    isPage: true,
    isProtected: true,
  },
  {
    path: urlsFE.handleConnect,
    component: <HandleConnect />,
    emptyRoute: true,
  },
  {
    path: urlsFE.marketWatch,
    component: <MarketWatch />,
    isPage: true,
    helmet: <MarketWatchHelmet />,
  },
  {
    path: urlsFE.preMarket,
    component: <PreMarket />,
    isPage: true,
    helmet: <PreMarketHelmet />,
  },
  {
    path: urlsFE.equityOverview,
    component: <EquityOverview />,
    isPage: true,
    helmet: <EquityOverviewHelmet />,
  },
  {
    path: urlsFE.derivativesOverview,
    component: <DerivativesOverview />,
    isProtected: true,
    isPage: true,
  },
  {
    path: urlsFE.dashboard,
    component: <NSE />,
    isProtected: true,
    isPage: true,
  },
  {
    path: urlsFE.privacyPolicy,
    component: <PrivacyPolicy />,
    isPage: true,
  },
  {
    path: urlsFE.tnc,
    component: <Tnc />,
    isPage: true,
  },
  {
    path: urlsFE.equityPage,
    component: <EquityPage />,
    isPage: true,
    doNotChangeHelmet: true,
  },
  {
    path: urlsFE.indexPage,
    component: <EquityPage />,
    isPage: true,
    doNotChangeHelmet: true,
  },
  {
    path: urlsFE.signin,
    component: <Signin />,
    isPage: true,
  },
  {
    path: urlsFE.signup,
    component: <Signup />,
    isPage: true,
  },
  {
    path: "/qf-edition",
    component: (
      <>
        <NotFound />
        <HomeSearch />
        <div className="col-xxxl-6 col-md-6">
          <ForexMovement />
        </div>
        <div className="col-xxxl-6 col-md-6">
          <GlobalIndicesNews />
        </div>
      </>
    ),
    isPage: true,
  },
  {
    path: "*",
    isPage: true,
    component: <NotFound />,
  },
];
