import React, { useEffect, useState } from "react";
import { formatDate, generateDateFromA1 } from "../../../utils/configs";
import OIVisualizerChart from "./chart/OIVisualizerChart";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const OIVisualizer = ({
  optionChain,
  underlying,
  optionExDates,
  optionsDate,
}) => {
  const oiData = optionChain.data;
  const [checkedItems, setCheckedItems] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight
  const threeMonthsLater = new Date(today);
  threeMonthsLater.setMonth(today.getMonth() + 3); // Add 3 months
  threeMonthsLater.setHours(0, 0, 0, 0); // Set time to midnight

  useEffect(() => {
    setCheckedItems([optionsDate]);
  }, [optionsDate]);

  const handleCheckboxChange = (item) => {
    setCheckedItems((prev) => {
      if (prev.includes(item)) {
        return prev.filter((checkedItem) => checkedItem !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    const filteredItems = (optionExDates || []).filter((dateStr) => {
      const date = new Date(generateDateFromA1(dateStr));
      date.setHours(0, 0, 0, 0); // Set time to midnight for comparison
      return date >= today && date <= threeMonthsLater;
    });

    if (isChecked) {
      setCheckedItems(filteredItems); // Select all
    } else {
      setCheckedItems([]); // Deselect all
    }
  };

  return (
    <div>
      <div className="container p-0">
        <ButtonDropdown
          isOpen={dropdownOpen}
          className="pb-2"
          toggle={toggleDropdown}
        >
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-btn our-border text-color-themed mt-1 mx-2"
            style={{ fontSize: "12px" }}
          >
            {checkedItems.length > 0
              ? `Selected Expiries: ${checkedItems.length}`
              : "Select Expiries"}
          </DropdownToggle>
          <DropdownMenu
            className="general-btn our-border non-trans-bg"
            style={{ maxHeight: "220px", overflowY: "scroll", zIndex: 1 }}
          >
            <DropdownItem className="bg-set-on-active text-color-themed">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={
                    (optionExDates || []).filter((dateStr) => {
                      const date = new Date(generateDateFromA1(dateStr));
                      date.setHours(0, 0, 0, 0); // Set time to midnight for comparison
                      return date >= today && date <= threeMonthsLater;
                    }).length === checkedItems.length
                  }
                  onChange={handleSelectAllChange}
                />
                <label className="form-check-label">Select All</label>
              </div>
            </DropdownItem>
            {(optionExDates || [])
              .filter((dateStr) => {
                const date = new Date(generateDateFromA1(dateStr));
                date.setHours(0, 0, 0, 0); // Set time to midnight for comparison
                return date >= today && date <= threeMonthsLater;
              })
              .map((item, index) => (
                <DropdownItem
                  key={index}
                  className="bg-set-on-active text-color-themed"
                >
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={checkedItems.includes(item)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    <label className="form-check-label">{item}</label>
                  </div>
                </DropdownItem>
              ))}
          </DropdownMenu>
        </ButtonDropdown>

        {checkedItems.length > 0 ? (
          <OIVisualizerChart
            data={oiData.filter((val) => checkedItems.includes(val.expiryDate))}
            underlyingValue={underlying.underlyingValue}
            lengthOfExps={checkedItems.length}
          />
        ) : (
          <div className="text-center pt-2">
            Select atleast one Expiry to analyse
          </div>
        )}
      </div>
    </div>
  );
};

export default OIVisualizer;
