import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../vision/QFLoader";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import VIsAdvancerDeclinerUnchanged from "../vision/VIsAdvancerDeclinerUnchanged";
import LinkToComp from "../minis/LinkToComp";
import VisDropDown from "../vision/VisDropDown";

const AdvancerDecliner = () => {
  const options = ["LOSER", "GAINER"];
  const [whichAdvancer, setWhichAdvancer] = useState("LOSER");
  const [gainerLoser, setGainerLoser] = useState({ data: [] });
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet("/market/advances_declines")
        .then((res) => {
          setGainerLoser(res.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  return (
    <CommonCardView
      id="commonCard_advancerDecliner"
      header="Gainer/Loser"
      headerInfo={{
        show: true,
        desc: "Advancers Decliners stocks from NIFTY 200",
        id: "gainer-loser",
      }}
      style={{
        position: "relative",
      }}
    >
      <VisDropDown
        selected={whichAdvancer}
        list={options}
        setter={(val) => {
          setWhichAdvancer(val);
        }}
      />
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <table
         className="table-tr-bb"
          style={{
            width: "100%",
          }}
        >
          <thead className="make-me-sticky non-trans-bg">
            <tr>
              <td colSpan={2}>
                <VIsAdvancerDeclinerUnchanged gainerLoser={gainerLoser} />
              </td>
            </tr>
          </thead>
          <tbody>
            {(whichAdvancer == "GAINER"
              ? gainerLoser.data.filter((val) => val.pChange > 0)
              : gainerLoser.data.filter((val) => val.pChange < 0) || []
            )
              .sort((a, b) => (whichAdvancer == "GAINER" ? b.pChange - a.pChange : a.pChange - b.pChange))
              // .slice(0, 15)
              .map((data, index) => {
                return (
                  <tr
                    key={index}
                  >
                    <td className="py-1">
                      <LinkToComp symbol={data["symbol"]} />
                    </td>
                    <td className="py-1" style={{ textAlign: "right" }}>
                      {data["pChange"]}%
                    </td>
                    {/* <td>{data["totalTradedVolume"]}</td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </CommonCardView>
  );
};

export default AdvancerDecliner;
