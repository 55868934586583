import React, { useEffect, useState } from "react";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../vision/QFLoader";
import { commonApiGet } from "../../utils/api";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import VisTable from "../vision/VisTable";
import LinkToComp from "../minis/LinkToComp";
import { chartColor, convertToTimestamp, isCurrentTimeBefore1530, isToday, lastDays } from "../../utils/configs";
import { groupINRCurrencyNSE } from "../../utils/functions";
import VisDropDown from "../vision/VisDropDown";
const BhavCopy = () => {
  const [data, setdata] = useState([]);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [dataTable, setdataTable] = useState({ columns: [], rows: [] });
  const [dates, setdates] = useState([]);

  const [selectedDate, setselectedDate] = useState("");

  useEffect(() => {
    (async () => {
      let date30Days = await lastDays(30);
      let compiled = date30Days.map((val) => convertToTimestamp(val, false).split("-").reverse().join("-"));
      if (isToday(new Date(date30Days[0])) && isCurrentTimeBefore1530()) compiled.shift();
      setdates(compiled);
      setselectedDate(compiled[0]);
    })();
  }, []);

  async function getBhavCopyForGivenDate(latestDate) {
    await commonApiGet(`/market/bhavcopy?date=${latestDate}`)
      .then((res) => {
        setdata(res.data.filter((val) => val[" SERIES"].trim() == "BE" || val[" SERIES"].trim() == "EQ"));
        setLoaderShow(false);
        setErrorShow(false);
      })
      .catch((err) => {
        notificationTopup(err.message);
        setErrorShow(true);
        setLoaderShow(false);
      });
  }

  useEffect(() => {
    if (dates.length > 0) {
      setLoaderShow(true);
      getBhavCopyForGivenDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (data.length > 0) {
      setdataTable({
        columns: [
          { field: "symbol", label: "Symbol" },
          { field: "hiddenSymbol", label: undefined },
          { field: "avgPrice", label: "Price Book" },
          { field: "tradedInfo", label: "Tradeformation" },
        ],
        rows: data.map((val) => ({
          symbol: (
            <>
              <LinkToComp symbol={`${val.SYMBOL}`} />
              <br />
              <span
                style={{
                  color: val[" PREV_CLOSE"] < val[" LAST_PRICE"] ? chartColor.green : chartColor.red,
                }}
              >
                ₹{val[" LAST_PRICE"]}
                <br />
                <span style={{ fontSize: "var(--fnt-small-cp)", textWrap: "nowrap" }}>
                  {(val[" LAST_PRICE"] - val[" PREV_CLOSE"]).toFixed(2)} (
                  {(((val[" LAST_PRICE"] - val[" PREV_CLOSE"]) / val[" PREV_CLOSE"]) * 100).toFixed(2)})%
                </span>
              </span>
            </>
          ),
          hiddenSymbol: val.SYMBOL,
          avgPrice: (
            <table
              className="w-100 table-no-pad table-2nd-td-r no-brdr-tr"
              style={{ fontSize: "var(--fnt-smaller)", textWrap: "nowrap" }}
            >
              <tr>
                <th>Open</th>
                <td>₹{val[" OPEN_PRICE"]}</td>
              </tr>
              <tr>
                <th>High</th>
                <td>₹{val[" HIGH_PRICE"]}</td>
              </tr>
              <tr>
                <th>Low</th>
                <td>₹{val[" LOW_PRICE"]}</td>
              </tr>
              <tr>
                <th>Avg</th>
                <td>₹{val[" AVG_PRICE"]}</td>
              </tr>
            </table>
          ),
          tradedInfo: (
            <table
              className="w-100 table-no-pad table-2nd-td-r no-brdr-tr"
              style={{ fontSize: "var(--fnt-smaller)", textWrap: "nowrap" }}
            >
              <tr>
                <th>Traded Qty</th>
                <td>{groupINRCurrencyNSE(val[" TTL_TRD_QNTY"])}</td>
              </tr>
              <tr>
                <th>Deliv. Qty</th>
                <td>{groupINRCurrencyNSE(val[" DELIV_QTY"])}</td>
              </tr>
              <tr>
                <th>Deliv. %</th>
                <td>{val[" DELIV_PER"]}%</td>
              </tr>
              <tr>
                <th>Trades</th>
                <td>{groupINRCurrencyNSE(val[" NO_OF_TRADES"])}</td>
              </tr>
              <tr>
                <th>TurnOver</th>
                <td>{groupINRCurrencyNSE(val[" TURNOVER_LACS"] * 100000, true)}</td>
              </tr>
            </table>
          ),
        })),
      });
    }
  }, [data]);
  return (
    <CommonCardView
      id="commonCard_BhavCopy"
      style={{
        position: "relative",
      }}
      header="Bhavcopy"
      headerAlign="left"
      headerInfo={{
        show: true,
        desc: "A comprehensive report of daily stock market data, including prices, volumes, and other details for all listed securities. Use it to analyze market trends and track performance.",
        id: "bhav-copy",
      }}
    >
      <VisDropDown
        selected={selectedDate}
        list={dates}
        setter={(val) => {
          setselectedDate(val);
        }}
      />
      {errorShow ? <VisOops /> : loaderShow ? <QFLoader /> : <VisTable data={dataTable} recsPerPage={50} />}
    </CommonCardView>
  );
};

export default BhavCopy;
