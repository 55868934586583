import {
  commonApiDelete,
  commonApiGet,
  commonApiPostRB,
} from "../../../../utils/api";
import { notificationTopup } from "../../../../utils/NotificationTopup";

export async function handleSaveStrategy(
  optionLegs,
  selectedIndex,
  name,
  desc
) {
  await commonApiPostRB(
    "/user/strategy/create_strategy",
    {
      name,
      desc,
      legs: optionLegs.map((val) => {
        return {
          symbol: String(selectedIndex),
          changeinOpenInterest: String(val.changeinOpenInterest),
          pchangeinOpenInterest: String(val.pchangeinOpenInterest),
          strikePrice: val.strikePrice,
          expiryDate: String(val.expiryDate),
          openInterest: String(val.openInterest),
          impliedVolatility: String(val.impliedVolatility),
          totalTradedVolume: String(val.totalTradedVolume),
          totalBuyQuantity: String(val.totalBuyQuantity),
          totalSellQuantity: String(val.totalSellQuantity),
          optionType: String(val.optionType),
          delta: val.delta,
          gamma: val.gamma,
          theta: val.theta,
          vega: val.vega,
          token: String(val.token),
          change: String(val.change),
          pChange: String(val.pChange),
          lastPrice: String(val.tradedPrice),
          bidprice: String(val.bidprice),
          askPrice: String(val.askPrice),
          side: String(val.action),
        };
      }),
      total_delta: 0,
      total_gamma: 0,
      total_theta: 0,
      total_vega: 0,
      total_iv: 0,
    },
    false
  )
    .then((res) => {
      notificationTopup("Your stategy is saved", "green");
    })
    .catch((err) => {
      notificationTopup(err.message);
    });
}

export async function handleUpdateStrategy(
  optionLegs,
  selectedIndex,
  name,
  desc,
  strategyId
) {
  await commonApiPostRB(
    `/user/strategy/update/${strategyId}`,
    {
      name,
      desc,
      legs: optionLegs.map((val) => {
        return {
          symbol: String(selectedIndex),
          changeinOpenInterest: String(val.changeinOpenInterest),
          pchangeinOpenInterest: String(val.pchangeinOpenInterest),
          strikePrice: val.strikePrice,
          expiryDate: String(val.expiryDate),
          openInterest: String(val.openInterest),
          impliedVolatility: String(val.impliedVolatility),
          totalTradedVolume: String(val.totalTradedVolume),
          totalBuyQuantity: String(val.totalBuyQuantity),
          totalSellQuantity: String(val.totalSellQuantity),
          optionType: String(val.optionType),
          delta: val.delta,
          gamma: val.gamma,
          theta: val.theta,
          vega: val.vega,
          token: String(val.token),
          change: String(val.change),
          pChange: String(val.pChange),
          lastPrice: String(val.tradedPrice),
          bidprice: String(val.bidprice),
          askPrice: String(val.askPrice),
          side: String(val.action),
        };
      }),
      total_delta: 0,
      total_gamma: 0,
      total_theta: 0,
      total_vega: 0,
      total_iv: 0,
    },
    false
  )
    .then((res) => {
      notificationTopup("Your stategy is updated", "green");
    })
    .catch((err) => {
      notificationTopup(err.message);
    });
}

export async function getSavedStrategy(setter) {
  await commonApiGet("/user/strategy/list", "STRATEGY-BUILDER", false)
    .then((res) => {
      setter(res.data);
    })
    .catch((err) => {
      notificationTopup(err.message);
    });
}

export async function deleteStrategyLeg(strategyId, legId) {
  await commonApiDelete(`/user/strategy/${strategyId}/leg/${legId}`)
    .then((res) => {
      notificationTopup("Strategy leg deleted successfully", "green");
    })
    .catch((err) => {
      notificationTopup(err.message);
    });
}

export async function deleteStrategy(strategyId) {
  await commonApiDelete(`/user/strategy/${strategyId}`)
    .then((res) => {
      notificationTopup("Strategy deleted successfully", "green");
    })
    .catch((err) => {
      notificationTopup(err.message);
    });
}
