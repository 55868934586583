import React from "react";
// import HomeBanner from "./components/HomeBanner";
import Divider from "./components/Divider";
// import VisionMissionValues from "./components/VisionMissionValues";
import Scanners from "../../components/NSEComponents/Scanners";
import SharkFolio from "../../components/NSEComponents/SharkFolio";
import EodBandChanges from "../../components/NSEComponents/EodBandChanges";
import Ctas from "./components/Ctas";
import ResearchStocks from "../../components/NSEComponents/ResearchStocks";
import VisFeaturesPages from "../../components/vision/VisFeaturesPages";
import HomeSearch from "./components/HomeSearch";

export const Home = () => {
  return (
    <>
      <div className="col-12">
        <HomeSearch />
      </div>
      <Divider />
      <div className="col-12">
        {/* <HomeBanner /> */}
        <VisFeaturesPages />
        <Divider />
      </div>
      {/* <div className="col-12">
        <VisionMissionValues />
        <Divider />
      </div> */}
      <div className="col-12">
        <div className="row g-3">
          <div className="col-xxxl-3 col-md-6">
            <Scanners />
          </div>
          <div className="col-xxxl-3 col-md-6">
            <EodBandChanges />
          </div>
          <div className="col-xxxl-3 col-md-6">
            <ResearchStocks />
          </div>
          <div className="col-xxxl-3 col-md-6">
            <SharkFolio />
          </div>
          <center className="mt-5">
            <Ctas lgSm="sm" />
          </center>
        </div>
      </div>
    </>
  );
};
