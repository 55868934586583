import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { commonApiPost, setCookie } from "../../utils/api";
import { Col, Row } from "reactstrap";
import { notificationTopup } from "../../utils/NotificationTopup";
import { GoogBtn } from "../preLogin/components/Ctas";
import Divider from "../preLogin/components/Divider";
import QFLoader from "../../components/vision/QFLoader";
import { urlsFE } from "../pageroutes";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Signin() {
  const query = useQuery();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [viewPass, setViewPass] = useState(false);

  const [loaderShow, setLoaderShow] = useState(false);

  const n = useNavigate();
  const handleSignUp = async () => {
    setLoaderShow(true);
    await commonApiPost(`/auth/signin?user_name=${username}&password=${encodeURIComponent(password)}`)
      .then(async (res) => {
        if (res.status === 200) {
          await setCookie("access_token", res.data.access_token, 1);
          await setCookie("refresh_token", res.data.refresh_token, 1);
          notificationTopup("Logged in successfully", "green", true);
          n(query.get("callback") || urlsFE.dashboard);
        }
      })
      .catch((err) => {
        if (400 <= err.status < 500) {
          notificationTopup(err.response.data.error);
          if (err.status === 409) {
            n(`${urlsFE.signup}?usernametoconfirm=${encodeURIComponent(username)}`);
            notificationTopup("Please confirm your email to login", "info", true);
          }
        } else {
          notificationTopup(err.message);
          notificationTopup("Please try again later", "red", true);
        }
      });
    setLoaderShow(false);
  };

  return (
    <div
      className="centered-inside col-12"
      style={{
        minHeight: "80%",
      }}
    >
      <div className="signup-container">
        <h3>Login To Trade</h3>
        <Divider role="common_card_view" py={1} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSignUp();
          }}
          className="pt-3"
        >
          <Row>
            <Col sm={12}>
              <input
                type="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                required
              />
            </Col>
            <Col
              sm={12}
              style={{
                position: "relative",
              }}
            >
              <input
                type={viewPass ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="password-toggle-icon">
                <i onClick={() => setViewPass(!viewPass)} className={viewPass ? "fas fa-lock-open" : "fas fa-lock"}></i>
              </span>
            </Col>
          </Row>

          {loaderShow ? (
            <div className="py-2">
              <QFLoader />
            </div>
          ) : (
            <button type="submit">Sign in</button>
          )}
        </form>
        <Divider role="text" py="3" />
        <GoogBtn />
        <p>
          Not signed up with us? <Link to={urlsFE.signup}>Signup</Link>
        </p>
      </div>
    </div>
  );
}
