import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { commonApiGet } from "../../utils/api";
import { getRandomStockMessage, isLoggedin, isMobile, isObject } from "../../utils/configs";
import QFLoader from "./QFLoader";
import { useNavigate } from "react-router-dom";
import GridIcon from "../../views/svgs/GridIcon";
import VisWatchlist from "./toolbox/VisWatchlist";
import { IconForAddtowl } from "./toolbox/VisAddToWatchlist";
import { urlsFE } from "../../views/pageroutes";

import svgDashboard from "../../views/svgs/growth.svg";
import watchlistSVG from "../../views/svgs/watchlist.svg";
import ipoSVG from "../../views/svgs/ipo.svg";
import derivativesSVG from "../../views/svgs/derivatives-fx.svg";
import fiiDiiSVG from "../../views/svgs/fii-dii.svg";

const VisEquitySearch = () => {
  let navigate = useNavigate();

  const iconConfig = [
    {
      svg: watchlistSVG,
      name: "Watchlist",
      onClick: () => {},
      view: <VisWatchlist />,
    },
    {
      svg: svgDashboard,
      name: "Home",
      onClick: () => {
        navigate(urlsFE.dashboard);
      },
    },
    {
      svg: derivativesSVG,
      name: "F&O",
      onClick: () => {
        navigate(urlsFE.derivatives);
      },
    },
    {
      svg: ipoSVG,
      name: "IPO",
      onClick: () => {
        navigate("/ipo/ongoing");
      },
    },
    {
      svg: fiiDiiSVG,
      name: "FII/DII",
      onClick: () => {
        navigate("/fii-dii/day-wise");
      },
    },
  ];

  const visSearch = useSelector((state) => state.visSearch);
  const visExpandedToolBox = useSelector((state) => state.visExpandedToolBox);
  const [isVisible, setIsVisible] = useState(visSearch); // Initialize isVisible with visSearch
  const [searchResults, setSearchResults] = useState([]); // Store search results
  const [search, setSearch] = useState("");
  const [x, setX] = useState(-21); // initial x position
  const [y, setY] = useState(100); // initial y position;
  const [searchError, setSearchError] = useState(null); // Store search error
  const [isSearching, setIsSearching] = useState(false); // Flag to indicate search in progress

  const [focusedSearch, setFocusedSearch] = useState(0);

  const [selectedIcon, setSelectedIcon] = useState(null);

  const [componentHeight, setComponentHeight] = useState(0);

  const navigateHandle = (result) => {
    let prefix = result.instrumenttype === "AMXIDX" ? "index" : "info";
    let symbol = result.instrumenttype === "AMXIDX" ? result.symbol : result.name;
    let exchange = result.exchange;
    navigate(`/${prefix}/${exchange}/${symbol}`);
  };

  useEffect(() => {
    setComponentHeight(document.getElementById("floating_search")?.offsetHeight || 0);
  }, [isVisible, searchResults, visExpandedToolBox, x, y]);

  const handleClose = () => {
    setIsVisible(false);
    dispatch({ type: "HIDE_VISSEARCH" });
    setSearchResults([]);
    setSearch("");
    setIsSearching(false);
    setSearchError(false);
  };

  const instrumentTypePriority = {
    AMXIDX: 1,
    EQ: 2,
    FUTBAS: 3,
    FUTBLN: 4,
    FUTCOM: 5,
    FUTCUR: 6,
    FUTENR: 7,
    FUTIDX: 8,
    FUTIRC: 9,
    FUTIRT: 10,
    FUTSTK: 11,
  };
  const [noserchfoundmsg, setnoserchfoundmsg] = useState(getRandomStockMessage());
  const dispatch = useDispatch();
  const inputRef = useRef(null); // Create a reference to the input element
  const cache = {}; // Cache search results for 1 minute
  let throttleTimeout = 200;

  const throttledSearch = async (searchTerm) => {
    if (searchTerm.length < 3) return;
    setTimeout(async () => {
      setIsSearching(true);
      setSearchError(null);
      if (searchTerm === document.getElementById("quibblefrostsearchbar").value) {
        const cachedResults = cache[searchTerm];
        if (cachedResults) {
          setSearchResults(cachedResults);
          setIsSearching(false);
        } else {
          try {
            const res = await commonApiGet(`/stocks/scripsearch?search=${searchTerm}&type=1`, "EQUITY_SEARCH", false);

            let data = res.data.data
              .filter((val) => {
                if (!isObject(val.ohlc)) {
                  return 0;
                }
                if (Object.keys(val.ohlc).length === 0) {
                  return 0;
                }
                if (val.ohlc.ltp && parseInt(val.ohlc.ltp) == 0) {
                  return 0;
                }
                return 1;
              })
              .sort((a, b) => {
                const priorityA = instrumentTypePriority[a.instrumenttype] || Infinity; // Default to Infinity if not found
                const priorityB = instrumentTypePriority[b.instrumenttype] || Infinity; // Default to Infinity if not found
                return priorityA - priorityB; // Sort based on priority
              });
            setSearchResults(data);
            setFocusedSearch(0);
            cache[searchTerm] = data; // Cache the results
          } catch (error) {
            setSearchResults([]);
            setSearchError(error.message);
          } finally {
            setIsSearching(false);
          }
        }
      }
    }, throttleTimeout);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    throttledSearch(searchTerm);
  };

  useEffect(() => {
    setIsVisible(visSearch);
  }, [visSearch]);

  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === "s") {
      e.preventDefault(); // Prevent the default save action
      setIsVisible(true);
      dispatch({ type: "TOOLBOX_SEARCH" });
    }
    if (e.key === "Escape") {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleDrag = (e, ui) => {
    setX(ui.x);
    setY(ui.y);
  };

  useEffect(() => {
    if (isVisible && inputRef.current) {
      inputRef.current.focus(); // Focus on the input element when the component is visible
    }
  }, [isVisible]);

  return (
    isVisible && ( // Render the component only if isVisible is true
      <div
        style={{
          position: "relative",
        }}
      >
        <Draggable
          axis="both"
          handle=".handle"
          bounds={{
            top: 0,
            left: -21,
            right: window.innerWidth / 1.5,
            bottom: window.innerHeight - componentHeight - 50,
          }}
          defaultPosition={{ x, y }}
          onDrag={handleDrag}
        >
          <div
            id="floating_search"
            style={{
              position: "absolute",
              width: "500px",
              maxWidth: "85vw",
              zIndex: "10000000",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)", // Soft shadow for depth
              border: "1px solid rgba(255, 255, 255, 0.3)", // Light border to enhance glass feel
            }}
            className="p-2 our-border glassmorfy-it"
          >
            <div className="row">
              <div
                className="col-12"
                style={{
                  display: "flex",
                }}
              >
                <div className="vert-center pe-2">
                  <GridIcon />
                </div>
                <div className="vert-center pe-2">
                  <i
                    className={visExpandedToolBox ? "fas fa-magnifying-glass" : "fas fa-angles-left"}
                    style={{
                      cursor: "pointer",
                      fontSize: isMobile() ? "var(--fnt-tlbr-icon-m)" : "var(--fnt-tlbr-icon)",
                      transform: "scaleX(-1)",
                    }}
                    onClick={() => {
                      dispatch({ type: visExpandedToolBox ? "TOOLBOX_SEARCH" : "TOOLBOX_TOOLBOX" });
                    }}
                  ></i>
                </div>
                {visExpandedToolBox ? (
                  <div
                    className="vert-center p-0 pe-2 w-100 expanded-tool-box"
                    style={{
                      minHeight: "40px",
                    }}
                  >
                    <div
                      className="px-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {iconConfig.map((icon, index) => (
                        <div
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          key={index}
                          onClick={() => {
                            setSelectedIcon(icon.name);
                            icon.onClick();
                          }}
                          title={icon.name}
                          className="toolbarsvg"
                        >
                          <img src={icon.svg} width={26} />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <input
                    ref={inputRef} // Assign the reference to the input element
                    type="text"
                    autoCapitalize="true"
                    autoComplete="off"
                    className="qfInputType py-2 px-2"
                    placeholder="Search..."
                    onChange={handleSearch}
                    value={search}
                    style={{
                      fontSize: isMobile() ? "var(--fnt-tlbr-icon-m)" : "var(--fnt-tlbr-icon-ininp)",
                      fontWeight: 700,
                      borderColor: "var(--text-color)",
                    }}
                    id="quibblefrostsearchbar"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" && searchResults.length > 0 && focusedSearch > 0) {
                        setFocusedSearch(focusedSearch - 1);
                      }
                      if (e.key === "ArrowDown" && searchResults.length > 0 && focusedSearch < searchResults.length - 1) {
                        setFocusedSearch(focusedSearch + 1);
                      }
                      if (e.key === "Enter") {
                        if (searchResults.length > 0) {
                          navigateHandle(searchResults[focusedSearch]);
                        }
                      }
                    }}
                  />
                )}

                <div
                  className="vert-center ps-2"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <i
                    className="fa fa-close"
                    style={{
                      cursor: "pointer",
                      fontSize: isMobile() ? "var(--fnt-tlbr-icon-m)" : "var(--fnt-tlbr-icon)",
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  ></i>
                </div>
              </div>
              {visExpandedToolBox === true && selectedIcon !== null ? (
                <>{iconConfig.filter((val) => selectedIcon === val.name)[0].view || <></>}</>
              ) : (
                <></>
              )}
              {search.length < 3 ? (
                <></>
              ) : (
                <div
                  className="col-12 vert-center"
                  style={{
                    overflowY: "scroll",
                    maxHeight: "300px",
                    minHeight: "100px",
                  }}
                >
                  <div className="p-3">
                    {isSearching ? (
                      <QFLoader />
                    ) : searchResults.length > 0 ? (
                      <table className="table-bb w-100">
                        <thead className="make-me-sticky">
                          <tr>
                            <th>Name</th>
                            <th>LTP</th>
                            <th>Change</th>
                            {isLoggedin() ? <th style={{ textAlign: "center" }}>Watchlist</th> : <></>}
                          </tr>
                        </thead>
                        <tbody>
                          {searchResults.map((result, index) => {
                            let calchange = (100 * (result.ohlc.ltp - result.ohlc.close)) / result.ohlc.close || 0;
                            calchange = calchange === Infinity ? 0 : calchange;
                            return (
                              <tr
                                key={index}
                                className={`c-pointer tr-hover-focus-selection ${
                                  focusedSearch === index ? " tr-hover-focus-selectionDefault" : ""
                                }`}
                              >
                                <td onClick={() => navigateHandle(result)}>
                                  {result.tradingsymbol}{" "}
                                  <span
                                    className="div-pill  px-1 our-border"
                                    style={{
                                      fontWeight: 400,
                                      fontSize: "var(--fnt-xsmall)",
                                    }}
                                  >
                                    {result.instrumenttype === "AMXIDX" ? "INDEX" : result.exchange}
                                  </span>
                                </td>
                                <td onClick={() => navigateHandle(result)}>₹{result.ohlc.ltp}</td>
                                <td onClick={() => navigateHandle(result)}>{parseFloat(calchange).toFixed(2)}%</td>

                                {isLoggedin() ? (
                                  <td>
                                    <center>
                                      <IconForAddtowl symbol={result.name} vers={"search"} />{" "}
                                    </center>
                                  </td>
                                ) : (
                                  <></>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : searchError ? (
                      <div>{noserchfoundmsg}</div>
                    ) : (
                      <div>{noserchfoundmsg}</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Draggable>
      </div>
    )
  );
};

export default VisEquitySearch;
