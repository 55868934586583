import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";

const fetchOGImage = async (url) => {
  return await commonApiGet(`/utils/getImg?url=${url}`)
    .then((res) => {
      return res.data["og:image"];
    })
    .catch((error) => {
      return null;
    });
};

const VisLinkPreviewImg = ({ link, title }) => {
  const [image, setImage] = useState(null);
  useEffect(() => {
    (async () => {
      const img = await fetchOGImage(link);
      setImage(img);
    })();
  }, []);
  return <div className="blogImgpreview" style={{
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }}></div>;
};

export default VisLinkPreviewImg;
