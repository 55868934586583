import React from "react";
import IndexView from "../components/NSEComponents/IndexView";
import MarketScreener from "../components/vision/TradingViewWidgets/MarketScreeners";
import EconomicCalendar from "../components/vision/TradingViewWidgets/EconomicCalendar";
import PreMarketData from "../components/NSEComponents/PreMarketData";
import TechnicalAnalysis from "../components/vision/TradingViewWidgets/IndexTechnicalAnalysis";
import { useSelector } from "react-redux";
import VisFeaturesPages from "../components/vision/VisFeaturesPages";
import NewsCorp from "../components/NSEComponents/NewsCorp";
import BlogBridge from "../components/BlogBridge";
import ChartPatterns from "../components/NSEComponents/ChartPatterns";

export const NSE = () => {
  const selectedIndex = useSelector((state) => state.homePageSelectedIndex);
  return (
    <>
      <div className="col-12">
        <IndexView />
      </div>
      {/* <div className="col-md-4">
        <TechnicalAnalysis symbol={selectedIndex} />
      </div> */}
      <div className="col-md-12 p-0">
        <VisFeaturesPages />
      </div>
      <div className="col-md-6">
        <PreMarketData selectedIndex={selectedIndex} />
      </div>
      <div className="col-md-6">
        <MarketScreener />
      </div>
      <div className="col-xxxl-6 col-md-4">
        <ChartPatterns />
      </div>
      <div className="col-xxxl-3 col-md-4">
        <BlogBridge />
      </div>
      <div className="col-xxxl-3 col-md-4">
        <EconomicCalendar />
      </div>
      <div className="col-md-12">
        <NewsCorp />
      </div>
    </>
  );
};
