import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QFLoader from "../../vision/QFLoader";
import VisOops from "../../vision/VisOops";
import { commonApiPost } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import hawkQuiver, { updateVisWL } from "../../../utils/hawkeye/hawkQuiver";
import WatchlistOverviewer from "./WatchlistOverviewer";
import WatchlistTab from "./WatchlistTab";
import Ctas from "../../../views/preLogin/components/Ctas";
import Divider from "../../../views/preLogin/components/Divider";

const SharedWatchlist = () => {
  const { watchlistId } = useParams();

  const [watchlistDetails, setWatchlistDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [apiCaller, setApiCaller] = useState(0);

  const visWLUp = useSelector((state) => state.visWLUp);

  useEffect(() => {
    (async () => {
      await commonApiPost(`/user/watchlist/share/${watchlistId}`, "SHARED_WATCHLIST", false)
        .then((res) => {
          setWatchlistDetails(res.data);
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setLoading(false);
          setError(true);
        });
    })();
  }, []);

  const handleUpdater = () => {
    setApiCaller(apiCaller + 1);
  };

  useEffect(() => {
    setWatchlistDetails(hawkQuiver.getState().visWatchlist);
  }, [visWLUp]);
  return (
    <div
      className="w-100 h-100-responsive-lg"
      style={{
        height: "80vh",
        textAlign: "unset !important",
        overflowY: "scroll",
      }}
    >
      {loading ? (
        <div
          className="vert-center"
          style={{
            height: "80vh",
          }}
        >
          <QFLoader />
        </div>
      ) : error ? (
        <VisOops />
      ) : (
        <>
          <div className="row p-2 m-0 h-100 general-card-1">
            <div
              className="col-12 h-100"
              style={{
                overflowY: "scroll",
              }}
            >
              <div className="row h-100">
                <div
                  className="col-lg-8 pb-2 h-100"
                  style={{
                    overflowY: "hidden",
                    // position: "relative",
                  }}
                >
                  <Divider text={watchlistDetails.name} py={2} role="text" />
                  <WatchlistTab
                    watchlistDetails={[watchlistDetails]}
                    handleUpdater={handleUpdater}
                    shortBig="big"
                    selectedTab={watchlistId}
                    isSharedWL={true}
                  />
                  <center className="py-5">
                    <Ctas lgSm="sm" />
                  </center>
                </div>
                <div
                  className="col-lg-4 h-100"
                  style={{
                    overflowY: "scroll",
                    textAlign: "left",
                  }}
                >
                  <WatchlistOverviewer watchlistToAnalyse={watchlistDetails} isSharedWL={true} />
                  {/* Mobile Btns */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SharedWatchlist;
