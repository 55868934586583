import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";
import { notificationTopup } from "../../utils/NotificationTopup";
import Divider from "../../views/preLogin/components/Divider";
import captureAndShare from "./captureAndShare";
import Watermark from "./watermark/Watermark";
import { useSelector } from "react-redux";

const CommonCardView = ({
  header = "Quibblefrost",
  headerAlign = "left",
  children = <></>,
  className = "",
  style = {},
  headerInfo = {
    show: false,
    desc: "Description",
    id: "",
  },
  id = "NA",
  toHoverEffect = true,
  toWatermark = true,
  sharable = true,
  responsiveHeight = false,
}) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [showWatermark, setShowWatermark] = React.useState(false);

  // Effect to capture the screenshot after watermark is set
  useEffect(() => {
    if (showWatermark) {
      // Delay to ensure the DOM updates and shows the watermark
      setTimeout(() => {
        if (id !== "NA") {
          try {
            captureAndShare(id, header, headerInfo);
          } catch (err) {
            notificationTopup(err.message);
          } finally {
            setShowWatermark(false);
          }
        }
      }, 100); // A slight delay to ensure the watermark is rendered
    }
  }, [showWatermark, id]);

  const handleScreenshot = () => {
    // Show watermark and let the useEffect handle the screenshot
    setShowWatermark(true);
  };

  const highlightedComponent = useSelector((state) => state.highlightedComponent);
  return (
    <div
      className={`${className} common-card-view highlightable ${highlightedComponent === id && "highlight"}`}
      style={style}
      id={id !== "NA" ? id : ""}
    >
      <div className={`general-card-1 p-2 ${toHoverEffect ? " hover-effect" : ""}`} style={{ height: "100%" }}>
        <center>
          <h2 style={{ textAlign: headerAlign, paddingLeft: "10px" }} className="position-relative">
            {headerInfo.show && (
              <>
                <i id={`homepage_header_info_tooltip_${headerInfo.id}_desc`} className="fas fa-bars-staggered"></i>
                <Tooltip
                  popperClassName="trans-bg"
                  isOpen={tooltipOpen}
                  placement="right"
                  target={`homepage_header_info_tooltip_${headerInfo.id}_desc`}
                  toggle={() => {
                    setTooltipOpen(!tooltipOpen);
                  }}
                  innerClassName="text-color-themed glassmorfy-it"
                  autohide={false}
                  fade={true} // Use fade instead of transition
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)", // Soft shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.3)", // Light border to enhance glass feel
                  }}
                >
                  <div>{headerInfo.desc}</div>
                  {sharable ? (
                    <>
                      <hr className="m-1" />
                      <div className="align-left">
                        <div className=" c-pointer" onClick={handleScreenshot}>
                          <span>Share</span>{" "}
                          <i
                            className="fas fa-share-nodes bg-primary p-1 rounded"
                            style={{
                              color: "#fff",
                            }}
                          ></i>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Tooltip>
              </>
            )}
            <span className="mx-2">{header}</span>
          </h2>
          {/* <hr  /> */}
          <Divider role="common_card_view" py={2} />
        </center>

        <div
          style={{
            height: responsiveHeight === null ? "50vh" : responsiveHeight ? "auto" : "400px",
            overflowX: "hidden",
            transition: "max-height 3s ease-in-out",
          }}
          className="px-2"
          // scrbar-vr-available
        >
          {children}
        </div>

        {/* Watermark */}
        {showWatermark && toWatermark && <Watermark />}
      </div>
    </div>
  );
};

CommonCardView.propTypes = {
  header: PropTypes.any,
  headerAlign: PropTypes.oneOf(["center", "left", "right"]),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  headerInfo: PropTypes.shape({
    show: PropTypes.bool,
    desc: PropTypes.string,
    id: PropTypes.string,
  }),
  id: PropTypes.string,
  toHoverEffect: PropTypes.bool,
  toWatermark: PropTypes.bool,
  sharable: PropTypes.bool,
  responsiveHeight: PropTypes.bool,
};

export default CommonCardView;
