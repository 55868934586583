import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { themedTextColor } from "../../../utils/configs";
import OIVisualizer from "./OIVisualizer";
import PayoffChart from "./chart/PayoffChart";
import QFLoader from "../../vision/QFLoader";
import { useSelector } from "react-redux";

const DerivativesPages = ({
  option,
  underlying,
  optionChain,
  optionsDate,
  optionExDates,
}) => {
  const theme = useSelector((state) => state.theme);
  const tabsConfig = {
    strategyBuilder: {
      name: "Strategy Builder",
      component: (
        <div
          style={{
            maxHeight: "69vh",
            overflowY: "scroll",
            overflowX: 'hidden'
          }}
        >
          {underlying.underlyingValue ? (
            <PayoffChart
              optionChain={optionChain.data.filter(
                (val) =>
                  val.expiryDate
                    ?.toString()
                    ?.replaceAll("-", "")
                    ?.toLowerCase() === optionsDate?.toLowerCase()
              )}
              underlying={underlying}
              optionsDate={optionsDate}
              selectedIndex={option}
            />
          ) : (
            <QFLoader />
          )}
        </div>
      ),
    },
    analysis: {
      name: "Analysis",
      component: (
        <div>
          {optionsDate ? (
            <OIVisualizer
              optionChain={optionChain}
              underlying={underlying}
              optionExDates={optionExDates}
              optionsDate={optionsDate}
            />
          ) : (
            <QFLoader />
          )}
        </div>
      ),
    },
  };

  const [selectedTab, setSelectedTab] = useState("strategyBuilder");

  return (
    <div
      className="row m-0 p-0"
      style={{
        position: "relative ",
      }}
    >
      <div>
        <Nav
          tabs
          style={{
            color: "var(--text-color) !important",
          }}
          className="mb-3 "
        >
          {Object.keys(tabsConfig).map((key, index) => (
            <NavItem
              key={index}
              onClick={() => {
                setSelectedTab(key);
              }}
              className="p-2"
              style={{
                cursor: "pointer",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                ...(selectedTab === key
                  ? {
                      backgroundColor: "var(--text-color)",
                    }
                  : {}),
              }}
            >
              <NavLink
                active={selectedTab === key ? true : false}
                style={{
                  color:
                    selectedTab === key
                      ? themedTextColor[theme === "light" ? "dark" : "light"]
                      : "var(--text-color)",
                  textDecoration: "none",
                }}
              >
                {tabsConfig[key].name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <div className="p-0 m-0 text-start">
        {tabsConfig[selectedTab].component}
      </div>
    </div>
  );
};

export default DerivativesPages;
