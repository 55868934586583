import React, { useEffect, useRef, useState } from "react";
import { groupINRCurrencyNSE } from "../../utils/functions";
import { Nav, NavItem, NavLink } from "reactstrap";
import { isMobile, themedTextColor } from "../../utils/configs";
import EquityNews from "./EquityNews";
import VisOHLCUCLC from "../../components/vision/charts/VisOHLCUCLC";

const FundamentalPreview = ({ fullView, exchange }) => {
  const scrollRef = useRef(null);
  const [scrollPercent, setScrollPercent] = useState(0);

  const handleScroll = () => {
    const scrollWidth = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    const scrollPosition = scrollRef.current.scrollLeft;
    const percent = (scrollPosition / scrollWidth) * 100;
    setScrollPercent(percent);
  };

  useEffect(() => {
    if (scrollRef.current !== null) {
      const scrollContainer = scrollRef.current;
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollRef]);
  const [expandedIndex, setExpandedIndex] = useState(false);
  const isSecConfig = {
    isFNOSec: "FNO Sector",
    isCASec: "CA Sector",
    isSLBSec: "SLB Sector",
    isDebtSec: "Debt Sector",
    isSuspended: "Suspended",
    isETFSec: "ETF Sector",
    isDelisted: "Delisted",
    tradingStatus: "Trading",
    derivatives: "Derivatives",
  };

  const tabsConfig = {
    ...(exchange === "NSE"
      ? {
          fundamentalData: {
            name: "Fundamentals",
            component: (
              <>
                {fullView.surveillance && fullView.surveillance.surv !== null && fullView.surveillance.surv !== "-" ? (
                  <div className="col-12 pt-3">
                    <b>Surveillance: </b>
                    <div
                      className="text-danger div-pill our-border general-card-1 py-0 px-2 m-2"
                      style={{ display: "inline-block" }}
                    >
                      <span style={{ fontSize: "var(--fnt-smaller)" }}>
                        {fullView.surveillance.surv}: {fullView.surveillance.desc}
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="col-12"
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    ref={scrollRef}
                    className="row"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      overflowX: "scroll",
                    }}
                  >
                    <div className="col-md-6 col-10">
                      <table className="w-100">
                        <tbody>
                          {fullView.promisedData.strike > 0 && (
                            <tr>
                              <td>
                                <b>Strike Price</b>
                              </td>
                              <td>{fullView.promisedData.strike}</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <b>Name:</b>
                            </td>
                            <td>{fullView.companyName}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Industry:</b>
                            </td>
                            <td>{fullView.industry}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Trading Symbol:</b>
                            </td>
                            <td>{fullView.tradingSymbol}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Traded Volume:</b>
                            </td>
                            <td>{groupINRCurrencyNSE(fullView.tradeVolume)}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Traded Value:</b>
                            </td>
                            <td>₹{fullView.totalTradedValue}Cr.</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Last Traded Quantity:</b>
                            </td>
                            <td>{fullView.lastTradeQty}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Delivery Quantity:</b>
                            </td>
                            <td>
                              {fullView.deliveryQuantity} | {groupINRCurrencyNSE(fullView.deliveryQuantity)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Delivery to trade:</b>
                            </td>
                            <td>{fullView.deliveryToTradedQuantity}%</td>
                          </tr>
                          {Object.keys(isSecConfig).map((val, key) => (
                            <tr key={key}>
                              <td>
                                <b>{isSecConfig[val]}:</b>
                              </td>
                              <td>
                                {typeof fullView[val] === "boolean" ? (fullView[val] ? " Yes" : " No") : fullView[val]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 col-10">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td>
                              <b>Issue Size:</b>
                            </td>
                            <td>{`${fullView.issuedSize} | ${groupINRCurrencyNSE(fullView.issuedSize)}`}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Market Cap:</b>
                            </td>
                            <td>₹{fullView.totalMarketCap}Cr.</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Free Float:</b>
                            </td>
                            <td>{fullView.ffmc ? `₹${fullView.ffmc?.toFixed(2)}Cr.` : "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Face Value:</b>
                            </td>
                            <td>{fullView.faceValue}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Impact Cost:</b>
                            </td>
                            <td>{fullView.impactCost}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Symbol PE:</b>
                            </td>
                            <td>{fullView.pdSymbolPe}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Adjusted PE:</b>
                            </td>
                            <td>{fullView.pdSectorPe}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Tick Size:</b>
                            </td>
                            <td>{fullView.promisedData.tick_size}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Daily Volatility:</b>
                            </td>
                            <td>{fullView.cmDailyVolatility}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Annual Volatility:</b>
                            </td>
                            <td>{fullView.cmAnnualVolatility}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Security VAR:</b>
                            </td>
                            <td>{fullView.securityVar}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Index VAR:</b>
                            </td>
                            <td>{fullView.indexVar}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>VAR Margin:</b>
                            </td>
                            <td>{fullView.varMargin}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Extreme Loss Margin:</b>
                            </td>
                            <td>{fullView.extremeLossMargin}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Adhoc Margin:</b>
                            </td>
                            <td>{fullView.adhocMargin}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Applicable Margin:</b>
                            </td>
                            <td>{fullView.applicableMargin}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>{fullView.opnInterest == 0 ? "Open Interest" : "OI"}:</b>
                            </td>
                            <td>
                              {fullView.opnInterest} | {groupINRCurrencyNSE(fullView.opnInterest)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {isMobile() ? (
                    <div
                      style={{
                        borderRadius: "100px",
                        overflow: "hidden",
                        width: "100px",
                        backgroundColor: "var(--text-color)",
                        position: "absolute",
                        bottom: "-10px",
                        right: "50%",
                        transform: "translateX(50%)",
                      }}
                    >
                      <div className="progress-bar" style={{ width: `${Math.max(scrollPercent, 50)}%` }} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {fullView.pdSectorIndAll && fullView.pdSectorIndAll !== "NA" ? (
                  <div className="col-md-12 pt-2">
                    <b>Sector Indices:</b>
                    <br />
                    <div className="">
                      {fullView.pdSectorIndAll
                        ?.filter((val, ind) => {
                          return ind < 5 || expandedIndex;
                        })
                        .map((val, key) => {
                          return (
                            <div
                              className={
                                "our-border div-pill me-1 mt-1 mb-1 " + (fullView.pdSectorInd === val ? "btn btn-info" : "")
                              }
                              style={{
                                textWrap: "nowrap",
                                display: "inline-block",
                                fontWeight: "normal",
                                fontSize: "var(--fnt-smaller)",
                              }}
                              key={key}
                            >
                              {val}
                            </div>
                          );
                        })}
                      {expandedIndex ? (
                        <div
                          className="our-border div-pill me-1 mt-1 mb-1"
                          style={{
                            textWrap: "nowrap",
                            display: "inline-block",
                            fontWeight: "normal",
                            fontSize: "var(--fnt-smaller)",
                          }}
                          onClick={() => setExpandedIndex(false)}
                        >
                          ...less
                        </div>
                      ) : (
                        <div
                          className="our-border div-pill me-1 mt-1 mb-1"
                          style={{
                            textWrap: "nowrap",
                            display: "inline-block",
                            fontWeight: "normal",
                            fontSize: "var(--fnt-smaller)",
                          }}
                          onClick={() => setExpandedIndex(true)}
                        >
                          more...
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ),
          },
          news: {
            name: "Updates",
            component: <EquityNews symbol={fullView.promisedData.name} />,
          },
        }
      : {}),
    info: {
      name: "Pricebook",
      component: (
        <VisOHLCUCLC
          open={fullView.open}
          high={fullView.high}
          low={fullView.low}
          close={fullView.close}
          uc={fullView.upperCircuit}
          lc={fullView.lowerCircuit}
          ltp={fullView.ltp}
          extraData={{
            "52WeekHigh": fullView["52WeekHigh"],
            "52WeekLow": fullView["52WeekLow"],
            vwap: fullView.vwap,
            avgPrice: fullView.avgPrice,
          }}
          showCandle={false}
        />
      ),
    },
  };

  const [selectedTab, setSelectedTab] = useState(Object.keys(tabsConfig)[0]);

  return (
    <div
      className="row"
      style={{
        position: "relative",
      }}
    >
      <Nav
        tabs
        style={{
          color: "var(--text-color) !important",
        }}
        className="mb-3"
      >
        {Object.keys(tabsConfig).map((key, index) => (
          <NavItem
            key={index}
            onClick={() => {
              setSelectedTab(key);
            }}
          >
            <NavLink
              active={selectedTab === key ? true : false}
              style={{
                color: selectedTab === key ? themedTextColor.light : "var(--text-color)",
              }}
            >
              <h4 className="qf-normal-text my-0 py-0">{tabsConfig[key].name}</h4>
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <div
        style={{
          ...(isMobile()
            ? {}
            : {
                maxHeight: "350px",
              }),
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <p>{tabsConfig[selectedTab] && tabsConfig[selectedTab].component}</p>
      </div>
    </div>
  );
};

export default FundamentalPreview;
