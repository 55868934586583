import React, { useEffect, useRef, useState } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { notificationTopup } from "../../utils/NotificationTopup";
import { commonApiGet } from "../../utils/api";
import VisPreviewChart from "../vision/charts/VisPreviewChart";
import { useDispatch, useSelector } from "react-redux";
import angeloneTokenMappings from "../../utils/mappings/indices-symbolName.json";
import VisDropDown from "../vision/VisDropDown";

const IndexView = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const NSE = Object.keys(angeloneTokenMappings).map((val) => val.toUpperCase()) || [];
  const [selectedIndex, setSelectedIndex] = useState(useSelector((state) => state.homePageSelectedIndex) || "NIFTY 50");

  const d = useDispatch();

  const updateIndexInRedux = (index) => {
    d({
      type: "HOMEPAGE_SELECTED_INDEX",
      index: index,
    });
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div className="general-card-1 ">
        <VisPreviewChart symbol={selectedIndex} exchange={"NSE"} name={selectedIndex} isEq={false} />
      </div>
      <div
        style={{
          position: "absolute",
          top: "-5px",
          right: "0px",
        }}
      >
        <VisDropDown
          selected={selectedIndex}
          list={NSE}
          setter={(val) => {
            setSelectedIndex(val);
            updateIndexInRedux(val);
          }}
        />
      </div>
    </div>
  );
};

export default IndexView;
