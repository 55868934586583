import React from "react";
import { Link } from "react-router-dom";

const LinkToComp = ({ symbol, to = null, exchange = "NSE", type = "eq" }) => (
  <Link
    className="link-to-comp" // Add the class name here
    to={`/${type === "eq" ? "info" : "index"}/${exchange}/${to || symbol}`}
  >
    {symbol}
  </Link>
);

export default LinkToComp;