import React from "react";
import tickBytick from "../../views/svgs/tick-by-tick.svg";
import derive from "../../views/svgs/derivatives-fx.svg";
import watchlist from "../../views/svgs/watchlist.svg";
import paperTrade from "../../views/svgs/paper-trade.svg";
import strategyBuilder from "../../views/svgs/strategy-builder.svg";
import preMarket from "../../views/svgs/premarket.svg";
import "./VisFeaturesPages.css"; // Import the CSS file for styles
import { useNavigate } from "react-router-dom";
import { urlsFE } from "../../views/pageroutes";

const VisFeaturesPages = () => {
  const n = useNavigate();
  const features = [
    {
      title: "Tick-by-Tick",
      description: "Connect your broker for tick-by-tick data",
      svg: tickBytick,
      onclick: () => {
        n(urlsFE.handleConnect);
      },
    },
    {
      title: "Derivatives",
      description: "Get OI/Vol support-resistance indications",
      svg: derive,
      onclick: () => {
        n(urlsFE.derivatives);
      },
    },
    {
      title: "Watchlist",
      description: "Create and analyse your watchlishts",
      svg: watchlist,
      onclick: () => {
        n(urlsFE.watchlist);
      },
    },
    {
      title: "Paper Trade",
      description: "Learn on live market with paper trading",
      svg: paperTrade,
      onclick: () => {
        n(urlsFE.derivatives);
      },
    },
    {
      title: "Strategy Builder",
      description: "Learn, create and analyse",
      svg: strategyBuilder,
      onclick: () => {
        n(urlsFE.derivatives);
      },
    },
    {
      title: "Premarket",
      description: "Prepare yourself with up-to-date stats",
      svg: preMarket,
      onclick: () => {
        n(urlsFE.preMarket);
      },
    },
  ];

  return (
    <div className="features-container">
      {features.map((feature, index) => (
        <div className="feature-box p-2 text-center our-border c-pointer" key={index} onClick={feature.onclick}>
          <img src={feature.svg} alt="Indian Flag" className="feature-icon" />
          <h2>{feature.title}</h2>
          <p>{feature.description}</p>
        </div>
      ))}
    </div>
  );
};

export default VisFeaturesPages;
