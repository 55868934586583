import React, { useEffect, useState, useRef } from "react";
import { isMarketOpen } from "../../../utils/configs";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { commonApiGet } from "../../../utils/api";

const LivePrice = () => {
  const [prices, setPrices] = useState([]);
  const [tickerItems, setTickerItems] = useState([]);

  const [isOneTimeAPICalled, setIsOneTimeAPICalled] = useState(false);

  const isOneTimeAPICalledRef = useRef(isOneTimeAPICalled); // Create a ref

  // Update the ref whenever isOneTimeAPICalled changes
  useEffect(() => {
    isOneTimeAPICalledRef.current = isOneTimeAPICalled;
  }, [isOneTimeAPICalled]);

  const fetchLivePrices = async () => {
    const marketOpen = await isMarketOpen();

    if (!marketOpen && isOneTimeAPICalledRef.current) return;

    const url = "/websock/ltp/HOME";

    await commonApiGet(url, "LIVE_PRICE_API", false)
      .then((res) => {
        setPrices(res.data || []);
      })
      .catch((error) => {
        notificationTopup(error.message);
      });

    setIsOneTimeAPICalled(true);
  };

  useEffect(() => {
    fetchLivePrices(); // Initial fetch
    const interval = setInterval(fetchLivePrices, 40 * 1000); // Refresh every 15 seconds

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, []); // Add dependencies as needed

  useEffect(() => {
    const interval = setInterval(() => {
      setTickerItems(prices);
    }, 1000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, [prices]);

  const renderTickerItems = () => {
    return (
      Array.isArray(tickerItems) &&
      tickerItems.map((value, index) => (
        <span
          key={index}
          className={` px-2 mx-1 my-2 general-btn`}
          style={{
            display: "inline-block",
          }}
        >
          <strong>
            <i
              className={
                value.percentChange < 0
                  ? "fas fa-arrow-down text-danger"
                  : value.percentChange > 0
                  ? "fas fa-arrow-up text-success"
                  : ""
              }
            ></i>{" "}
            {value.tradingSymbol}
          </strong>{" "}
          | {`${value.ltp} (${value.percentChange}%)`}
        </span>
      ))
    );
  };

  return (
    <marquee behavior="scroll" direction="left" scrollamount="5">
      {renderTickerItems()}
      {renderTickerItems()}
    </marquee>
  );
};

export default LivePrice;
