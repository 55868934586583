import React from "react";
import VisOHLCChart from "./helpers/VisOHLCChart";

const VisOHLCUCLC = ({
  open = 0,
  high = 0,
  low = 0,
  close = 0,
  uc = 0,
  lc = 0,
  ltp = 0,
  extraData = {},
  forOptionChain = false,
  showCandle = true,
}) => {
  const calculatePercentage = (value) => (((ltp - value) / ltp) * 100).toFixed(2);

  const fromToLTP = {
    open: calculatePercentage(open),
    high: calculatePercentage(high),
    low: calculatePercentage(low),
    close: calculatePercentage(close),
    uc: calculatePercentage(uc),
    lc: calculatePercentage(lc),
    ...(extraData["52WeekHigh"] !== undefined && {
      "52WeekHigh": calculatePercentage(extraData["52WeekHigh"]),
    }),
    ...(extraData["52WeekLow"] !== undefined && {
      "52WeekLow": calculatePercentage(extraData["52WeekLow"]),
    }),
    ...(extraData.vwap !== undefined && {
      vwap: calculatePercentage(extraData.vwap),
    }),
    ...(extraData.avgPrice !== undefined && {
      avgPrice: calculatePercentage(extraData.avgPrice),
    }),
  };

  return (
    <div className="row">
      <div className="col vert-center">
        <table className={`${forOptionChain ? "no-brdr" : "table-tr-bb"} fromToLtp-t3-right qf-text-responsive`}>
          <tbody>
            <tr>
              <th>LTP: </th>
              <td>₹{ltp}</td>
              <td>
                <b>From LTP</b>
              </td>
            </tr>
            {extraData.vwap === undefined ? (
              <></>
            ) : (
              <tr>
                <th>VWAP: </th>
                <td>₹{parseFloat(extraData.vwap).toFixed(2)}</td>
                <td>{fromToLTP.vwap}%</td>
              </tr>
            )}
            <tr>
              <th>Open: </th>
              <td>₹{open}</td>
              <td>{fromToLTP.open}%</td>
            </tr>
            <tr>
              <th>High: </th>
              <td>₹{high}</td>
              <td>{fromToLTP.high}%</td>
            </tr>
            <tr>
              <th>Low: </th>
              <td>₹{low}</td>
              <td>{fromToLTP.low}%</td>
            </tr>
            <tr>
              <th>Close: </th>
              <td>₹{close}</td>
              <td>{fromToLTP.close}%</td>
            </tr>
            {extraData.avgPrice === undefined ? (
              <></>
            ) : (
              <tr>
                <th>Avg Price: </th>
                <td>₹{parseFloat(extraData.avgPrice).toFixed(2)}</td>
                <td>{fromToLTP.avgPrice}%</td>
              </tr>
            )}
            <tr>
              <th>UC: </th>
              <td>₹{parseFloat(uc).toFixed(2)}</td>
              <td>{fromToLTP.uc}%</td>
            </tr>
            <tr>
              <th>LC: </th>
              <td>₹{parseFloat(lc).toFixed(2)}</td>
              <td>{fromToLTP.lc}%</td>
            </tr>
            {extraData["52WeekHigh"] === undefined ? (
              <></>
            ) : (
              <tr>
                <th>1Y High: </th>
                <td>₹{parseFloat(extraData["52WeekHigh"]).toFixed(2)}</td>
                <td>{fromToLTP["52WeekHigh"]}%</td>
              </tr>
            )}
            {extraData["52WeekLow"] === undefined ? (
              <></>
            ) : (
              <tr>
                <th>1Y Low: </th>
                <td>₹{parseFloat(extraData["52WeekLow"]).toFixed(2)}</td>
                <td>{fromToLTP["52WeekLow"]}%</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showCandle ? (
        <div className="col-4">
          <center
            style={{
              height: "100%",
            }}
          >
            <VisOHLCChart ohlc={[[open, forOptionChain ? ltp : close, low, high]]} />
          </center>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default VisOHLCUCLC;
