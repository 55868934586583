import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import VisTable from "../vision/VisTable";
import { isMobile } from "../../utils/configs";

export const Holiday = () => {
  const [tradingHoliday, setTradingHoliday] = useState([]);
  const [clearingHoliday, setClearingHoliday] = useState([]);

  const [dataTableT, setDataTableT] = useState({ columns: [], rows: [] });
  const [dataTableC, setDataTableC] = useState({ columns: [], rows: [] });

  function prepareDataTablesT() {
    const holidays = tradingHoliday.map((item) => {
      return {
        tradingDate: item["tradingDate"],
        weekDay: item["weekDay"],
        description: item["description"],
      };
    });
    setDataTableT({
      columns: [
        { field: "tradingDate", label: "Date" },
        { field: "weekDay", label: "Day" },
        { field: "description", label: "Description" },
      ],
      rows: holidays,
    });
  }

  function prepareDataTablesC() {
    const holidays = clearingHoliday.map((item) => {
      return {
        tradingDate: item["tradingDate"],
        weekDay: item["weekDay"],
        description: item["description"],
      };
    });
    setDataTableC({
      columns: [
        { field: "tradingDate", label: "Date" },
        { field: "weekDay", label: "Day" },
        { field: "description", label: "Description" },
      ],
      rows: holidays,
    });
  }

  useEffect(() => {
    prepareDataTablesT();
    prepareDataTablesC();
  }, [tradingHoliday, clearingHoliday]);

  useEffect(() => {
    (async () => {
      await commonApiGet("/utils/holidays?type=trading")
        .then((res) => {
          setTradingHoliday(res.data.CM);
        })
        .catch((err) => {
          notificationTopup(err.message);
        });
    })();
    (async () => {
      await commonApiGet("/utils/holidays?type=clearing")
        .then((res) => {
          setClearingHoliday(res.data.CM);
        })
        .catch((err) => {
          notificationTopup(err.message);
        });
    })();
  }, []);
  return (
    <>
      <div className="col-md-6">
        <CommonCardView
          id="commonCard_holidayT"
          header="CM - Trading Holidays"
          headerAlign="left"
          headerInfo={{
            show: true,
            desc: "View the list of market holidays when stock exchanges remain closed. Plan your trades in advance with the holiday calendar.",
            id: "Trading-holiday",
          }}
          responsiveHeight={true}
        >
          
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "50vh",
            }}
          >
            <VisTable data={dataTableT} recsPerPage={"All"} />
          </div>
        </CommonCardView>
      </div>
      <div className="col-md-6">
        
      <CommonCardView
          id="commonCard_holidayC"
          header="CM - Clearing Holidays"
          headerAlign="left"
          headerInfo={{
            show: true,
            desc: "Days when stock exchanges are open for trading, but clearing and settlement of trades do not occur. Ensure timely fund and securities management around these dates.",
            id: "clearing-holiday",
          }}
          responsiveHeight={true}
        >
          
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "50vh",
            }}
          >
            <VisTable data={dataTableC} recsPerPage={"All"} />
          </div>
        </CommonCardView>
      </div>
    </>
  );
};
