import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../../components/vision/QFLoader";
import VisOops from "../../components/vision/VisOops";
import CommonCardView from "../../components/vision/CommonCardView";
import VisOrderBook from "../../components/vision/charts/VisOrderBook";
import VisPreviewChart from "../../components/vision/charts/VisPreviewChart";
import FundamentalPreview from "./FundamentalPreview";
import { chartColor, isLoggedin, isMobile } from "../../utils/configs";
import { Helmet } from "react-helmet-async";
import IndicesFunda from "./IndicesFunda";
import { IconForAddtowl } from "../../components/vision/toolbox/VisAddToWatchlist";
import { GetAngelOneLoginBtn } from "../../components/integrations/angel-one/angelOneHelper";
import VisOHLCEChart from "../../components/vision/charts/helpers/VisOHLCChart";
import { urlsFE } from "../pageroutes";

import indexSymbolName from "../../utils/mappings/indices-symbolName.json";

const EquityPage = () => {
  let { exchange, symbol } = useParams();
  const [fullView, setFullView] = useState({});
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const isIndex = location.href.indexOf("/index/") !== -1;
  const symbolForGetcandleStick = symbol;
  if (isIndex) symbol = indexSymbolName[symbol.toLowerCase()].name;

  const livePriceReflector = (updatedData) => {
    setFullView({
      ...fullView,
      ltp: updatedData.ltp,
      netChange: (updatedData.ltp - updatedData.close).toFixed(2),
      percentChange: (((updatedData.ltp - updatedData.close) / updatedData.close) * 100).toFixed(2),
    });
  };

  const [orderBook, setOrderBook] = useState({ buy: [], sell: [] });
  const n = useNavigate();
  useEffect(() => {
    setLoaderShow(true);
    (async () => {
      await commonApiGet(
        `/stocks/getfullquote?symbol=${encodeURIComponent(symbol)}&exchange=${exchange.toUpperCase()}`,
        `EQUITY_PAGE_${exchange}_${symbol}`,
        false,
        false
      )
        .then((res) => {
          if (res.data === null) {
            notificationTopup("No data found for this symbol");
            n("/404");
          } else {
            setFullView(res.data);
            setOrderBook(res.data.depth);
            setLoaderShow(false);
            setErrorShow(false);
          }
        })
        .catch((err) => {
          notificationTopup(err.message);
          setLoaderShow(false);
          setErrorShow(true);
        });
    })();
  }, [exchange, symbol]);

  const settingLiveOrderBook = (newOrderBook) => {
    setOrderBook({
      buy: newOrderBook
        .filter((val) => val.buy_sell_flag === 1)
        .map((val) => {
          return {
            price: val.price / 100,
            quantity: val.quantity,
            orders: val.number_of_orders,
          };
        }),
      sell: newOrderBook
        .filter((val) => val.buy_sell_flag === 0)
        .map((val) => {
          return {
            price: val.price / 100,
            quantity: val.quantity,
            orders: val.number_of_orders,
          };
        }),
    });
  };

  return (
    <div className="col-12">
      <Helmet data-rh="true">
        <title>{`${symbol} Share Price Today - About ${symbol}`}</title>
        <meta
          name="description"
          content={`Stay updated with the latest stock data and news for ${symbol} on the Quibblefrost. Discover real-time stock prices, market insights, and expert analysis.`}
        />
        <meta
          name="keywords"
          content={`${symbol}, ${exchange}, Quibblefrost, stock market updates, stock news, real-time stock prices, investment insights`}
        />
        <meta
          property="og:title"
          content={`${symbol} Stock Price - Live Updates from the ${exchange} Exchange - Quibblefrost`}
        />
        <meta
          property="og:description"
          content={`Get real-time updates on ${symbol} stock prices and the latest news from the ${exchange} exchange on Quibblefrost. Stay informed with market trends and investment opportunities.`}
        />
        <meta name="twitter:title" content={`Latest ${symbol} Stock Price - ${exchange} Exchange Updates - Quibblefrost`} />
        <meta
          name="twitter:description"
          content={`Follow ${symbol} for the latest stock price and news from the ${exchange} exchange on Quibblefrost. Keep your investments informed with timely updates and analysis.`}
        />
      </Helmet>
      <CommonCardView
        id="EQUITY_PAGE_COMP"
        headerInfo={{
          show: true,
          id: `EQUITY_PAGE_COMP_tooltip`,
          desc: "The News",
        }}
        header={
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {exchange} / {fullView.symbol} <br className="d-block d-md-none" />
            <span
              style={{
                textWrap: "nowrap",
              }}
            >
              <span
                style={{
                  fontSize: "var(--fnt-large)",
                  textWrap: "nowrap",
                }}
              >
                <i
                  className={fullView.netChange < 0 ? "fas fa-arrow-down text-danger" : "fas fa-arrow-up text-success"}
                  style={{
                    marginInlineStart: isMobile() ? "24px" : "10px",
                  }}
                ></i>{" "}
                ₹{fullView.ltp}{" "}
              </span>
              <span
                style={{
                  color: fullView.netChange < 0 ? chartColor.red : chartColor.green,
                }}
              >
                <span
                  style={{
                    fontSize: "var(--fnt-small)",
                  }}
                >
                  ₹{fullView.netChange} ({fullView.percentChange}%)
                </span>
              </span>{" "}
              {["NFO", "BFO"].includes(fullView.exchange) ? (
                <span
                  className="div-pill our-border general-card-1"
                  style={{ fontSize: "var(--fnt-smaller)", textWrap: "nowrap" }}
                >{`Lot size: X${fullView.promisedData.lotsize}`}</span>
              ) : (
                <></>
              )}
            </span>
          </span>
        }
        children={
          <div className="main-content pb-2">
            <div className="row g-3 ">
              <div
                className="col-12"
                style={{
                  minHeight: "500px",
                }}
              >
                {loaderShow ? (
                  <QFLoader />
                ) : errorShow ? (
                  <VisOops />
                ) : (
                  <div
                    className="row"
                    style={{
                      fontSize: "var(--fnt-small)",
                    }}
                  >
                    <div className="col-md-8 vert-spacebet" style={{ position: "relative" }}>
                      <VisPreviewChart
                        name={fullView?.companyName || fullView?.promisedData?.name}
                        symbol={isIndex ? symbolForGetcandleStick : fullView.tradingSymbol}
                        exchange={fullView.exchange}
                        settingLiveOrderBook={settingLiveOrderBook}
                        livePriceReflector={livePriceReflector}
                        isEq={!isIndex}
                      />

                      <hr />

                      <hr className="d-block d-md-none" />
                    </div>
                    <div className="col-md-4 ">
                      {isIndex ? (
                        <>
                          <IndicesFunda fullView={fullView} />
                        </>
                      ) : (
                        <>
                          <>
                            <div className="row">
                              <div className="col-md-10">
                                <VisOrderBook data={orderBook} />
                              </div>
                              <div className="col-md-2 vert-center d-none d-md-block">
                                <center>
                                  <VisOHLCEChart ohlc={[[fullView.open, fullView.ltp, fullView.low, fullView.high]]} />
                                </center>
                              </div>
                            </div>
                            <hr />
                          </>
                          <FundamentalPreview fullView={fullView} exchange={exchange.toUpperCase()} />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {exchange.toUpperCase() === "NSE" && (
              <>
                {isLoggedin() ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "80px",
                      fontSize: "var(--fnt-tlbr-icon)",
                      cursor: "pointer",
                    }}
                  >
                    <IconForAddtowl symbol={fullView.symbol} vers="custom" />
                  </div>
                ) : (
                  <i
                    className="fas fa-heart-circle-plus"
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "80px",
                      fontSize: "var(--fnt-tlbr-icon)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      n(urlsFE.signin);
                    }}
                  />
                )}
              </>
            )}

            <div
              style={{
                position: "absolute",
                right: "25px",
                top: "22px",
              }}
            >
              <GetAngelOneLoginBtn />
            </div>
          </div>
        }
        responsiveHeight={true}
        className="mt-2"
        style={{
          position: "relative",
        }}
      />
      <br />
      <br />
    </div>
  );
};

export default EquityPage;
